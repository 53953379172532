import React, { useContext, useEffect, useState } from 'react'
import {
  ListChats,
  Wrapper,
  StyledTitle,
  More,
  StyledSearch,
  InputSearch,
  ListItemChats,
  ItemChats,
  StyledName,
  ImgSearch,
  StyledItemchats,
  StyledItemChats,
  BoxChats,
  BoxSearch,
  TitleLine,
  StyledWrapper,
} from './styled'
import images from 'configs/images'
import Avatar from 'components/Avatar'
import UserName from 'components/UserName'
import { and, collection, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore'
import { db } from '../../../firebase'
import Conversation from './components/Conversation'
import Skeleton from 'react-loading-skeleton'
import { useLocation } from 'react-router-dom'
import userMessages from 'api/userMessages'
import _debounce from 'lodash/debounce'
import { UserContext } from 'astore/UserContext'
import SideBar from 'components/SideBar'
import { PageMeta } from 'components/Layout/Page'
import { SEOPage } from 'components/SEOPage'

const Messages = () => {
  const { userData } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [listMesseages, setListMesseages] = useState([])
  const [listSearchFriend, setListSearchFriend] = useState([])
  const [listSearchGroup, setListSearchGroup] = useState([])
  const [idFriend, setIdFriend] = useState<any>([])
  const [inFoFr, setInFoFr] = useState()
  const [active, setActive] = useState()
  const [inputSearchChat, setInputSearchChat] = useState('')
  const [total, setTotal] = useState(10)
  const userId = userData?.userId
  const location = useLocation()

  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'message')
  // let typingTimer

  useEffect(() => {
    setIdFriend(location.state)
    setActive(null)
  }, [])

  useEffect(() => {
    setLoading(true)
    if (userId) {
      const query2 = and(where('memberIds', 'array-contains', userId), where('lastSent', '!=', null))
      const unsub = onSnapshot(
        query(collection(db, 'conversations'), query2, orderBy('lastSent', 'desc'), limit(total)),
        (doc) => {
          const data = doc.docs.map((doc) => {
            return doc.data()
          })
          // console.log(data)
          setListMesseages(data)
          // console.log(data)
          setLoading(false)
        },
      )
      return () => unsub()
    }
    return undefined
  }, [total, userData])

  const handledSetItems = (_id, _idFr, _name, _avatar, _isGroup, _newChat, _item, ix) => {
    setIdFriend({
      _id: _id,
      _idFr: _idFr,
      name: _name,
      avatar: _avatar,
      isGroup: _isGroup,
      newchat: _newChat,
    })
    setInFoFr(_item)
    setActive(ix)
  }

  const searchChat = async (_keywork) => {
    setLoading(true)
    if (_keywork) {
      try {
        const params = {
          keyword: _keywork,
          pageSize: 6,
          pageIndex: 1,
        }
        const response = await userMessages.search(params)
        setListSearchFriend(response.data.friends)
        setListSearchGroup(response.data.groups)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('Failed to fetch userInfo: ', error)
      }
    }
  }

  const handleInputChange = (_keywork) => {
    if (_keywork.length > 0) {
      setInputSearchChat(_keywork)
      searchChat(_keywork)
    } else {
      setInputSearchChat('')
    }
  }

  const SkeletonItem = () => (
    <ItemChats>
      <Skeleton width={44} height={44} borderRadius={50} />
      <StyledName>
        <p className="seen">
          <Skeleton style={{ width: '60%' }} />
        </p>
        <p className="seen">
          <Skeleton />
        </p>
      </StyledName>
    </ItemChats>
  )

  const SkeletonList = () => {
    return (
      <StyledItemChats>
        <ListItemChats>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </ListItemChats>
      </StyledItemChats>
    )
  }

  return (
    <StyledWrapper>
      <PageMeta meta={findMeta} />
      <SideBar />
      <Wrapper>
        <ListChats style={{ display: `${window?.screen.width <= 625 && active !== null ? 'none' : 'flex'}` }}>
          <BoxChats>
            <StyledTitle>
              <p>Chat</p>
              <More className="display-more-chat">
                <img src={images.morefill} alt="more" />
              </More>
            </StyledTitle>
          </BoxChats>
          <BoxSearch>
            <StyledSearch>
              <ImgSearch src={images.icon_search} alt="/search" />
              <InputSearch
                value={inputSearchChat}
                onChange={(e) => handleInputChange(e.target.value)}
                type="text"
                placeholder="Search on messenger"
              />
            </StyledSearch>
          </BoxSearch>
          {inputSearchChat.length > 0 ? (
            <>
              {loading ? (
                <SkeletonList />
              ) : (
                <StyledItemChats>
                  <ListItemChats>
                    {listSearchFriend.length > 0 && (
                      <>
                        <TitleLine>Friends</TitleLine>
                        {listSearchFriend.map((iFr, ixFr) => (
                          <ItemChats
                            key={ixFr}
                            onClick={() =>
                              handledSetItems(iFr._id, iFr?._id, iFr?.name, iFr?.avatar, false, false, iFr, ixFr)
                            }
                          >
                            <Avatar data={iFr.avatar} width={44} height={44} />
                            <StyledName>
                              <UserName data={iFr.name.length > '15' ? `${iFr.name.substring(0, 16)}...` : iFr.name} />
                            </StyledName>
                          </ItemChats>
                        ))}
                      </>
                    )}
                    {listSearchGroup.length > 0 && (
                      <>
                        <TitleLine>Groups</TitleLine>
                        {listSearchGroup.map((iGr, ixGr) => {
                          const names =
                            iGr.groupInfos.name === '' && iGr.members.map((member) => member.name).join(', ')
                          return (
                            <ItemChats
                              key={ixGr}
                              onClick={() =>
                                handledSetItems(
                                  iGr._id,
                                  iGr._id,
                                  names,
                                  iGr.groupInfos.avatar,
                                  iGr.isGroup,
                                  false,
                                  iGr,
                                  ixGr,
                                )
                              }
                            >
                              <Avatar data={iGr.groupInfos.avatar} width={44} height={44} />
                              <StyledName>
                                <UserName data={names} />
                              </StyledName>
                            </ItemChats>
                          )
                        })}
                      </>
                    )}
                  </ListItemChats>
                </StyledItemChats>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <SkeletonList />
              ) : (
                <StyledItemChats>
                  <ListItemChats>
                    {listMesseages.slice().map((i, ix) => {
                      const idFr = i.members.find((item) => item._id !== userData.userId)
                      const names =
                        i.isGroup && i.groupInfos.name === '' && i.members.map((member) => member.name).join(', ')
                      const isSeen = i?.seenBy ? i.seenBy.find((item) => item.userId === userData.userId) : ''

                      return (
                        <React.Fragment key={ix}>
                          {i.isGroup ? (
                            <ItemChats
                              className={ix === active && 'activeItem'}
                              onClick={() =>
                                handledSetItems(i.id, i._id, names, i.groupInfos.avatar, i.isGroup, false, i, ix)
                              }
                            >
                              <Avatar data={i.groupInfos.avatar} width={44} height={44} />
                              <StyledName>
                                <UserName data={names.length > '15' && `${names.substring(0, 18)}...`} />
                                <p className={isSeen ? 'seen' : 'noSeen'}>
                                  {i.lastContent.length > '15' ? (
                                    <>{`${i.lastContent.substring(0, 18)}...`}</>
                                  ) : (
                                    <>{i.lastContent ? i.lastContent : ''}</>
                                  )}
                                </p>
                              </StyledName>
                            </ItemChats>
                          ) : (
                            <ItemChats
                              className={ix === active && 'activeItem'}
                              onClick={() =>
                                handledSetItems(i.id, idFr?._id, idFr?.name, idFr?.avatar, i.isGroup, false, i, ix)
                              }
                            >
                              <Avatar data={idFr?.avatar} width={44} height={44} />
                              <StyledName>
                                <UserName data={idFr?.name} />
                                <p className={isSeen ? 'seen' : 'noSeen'}>
                                  {i.lastContent.length > '15' ? (
                                    <>{`${i.lastContent.substring(0, 18)}...`}</>
                                  ) : (
                                    <>{i.lastContent ? i.lastContent : ''}</>
                                  )}
                                </p>
                              </StyledName>
                            </ItemChats>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </ListItemChats>
                </StyledItemChats>
              )}
            </>
          )}
        </ListChats>
        <StyledItemchats>
          {idFriend && idFriend?._id ? (
            <Conversation
              key={idFriend._id}
              userData={userData}
              idFriend={idFriend}
              inFoFr={inFoFr}
              db={db}
              setActive={setActive}
            />
          ) : null}
        </StyledItemchats>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Messages

import { useTranslation } from '@pancakeswap/localization'
import {
  Box,
  Button,
  Text,
  UserMenu as UIKitUserMenu,
  UserMenuItem,
  UserMenuVariant,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { UserContext } from 'astore/UserContext'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import images from 'configs/images'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { usePendingTransactions } from 'state/transactions/hooks'
import styled from 'styled-components'
import { useAccount } from 'wagmi'

const Wrapper = styled.div`
  display: none;
  @media (max-width: 968px) {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    * {
      font-family: Inter, sans-serif;
    }
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
  }
`

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: rgba(26, 26, 26, 0.7);
`
const StyledItem = styled.div`
  cursor: pointer;
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 10px 8px 10px 16px;
  &.active {
    background: #fef1f1;
    border-right: 2px solid #dc2828;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
  &:hover {
    opacity: 0.8;
    color: rgba(26, 26, 26, 0.7);
  }
`
const ItemV1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 10px 8px 10px 16px;
  color: var(--neutral-600-gray, #68778d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  &.active {
    border-radius: var(--Radius-radi-0, 0px);
    border-right: 2px solid var(--primary-600-default, #ff0420);
    background: var(--Colors-red-50, #fef1f1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
  &:hover {
    border-radius: var(--Radius-radi-0, 0px);
    border-right: 2px solid var(--primary-600-default, #ff0420);
    background: var(--Colors-red-50, #fef1f1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
`
const ButtonShowMenuAccount = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 4px 24px;
  color: var(--mode-base-400, #a1a1aa);

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ButtonConnect = styled(Button)`
  width: 100%;
  display: flex;
  min-height: 44px;
  padding: 0 16px;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
  background: #f4f4f5;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
  cursor: pointer;
`
const ButtonSignIn = styled(ButtonConnect)`
  background: red;
  color: white;
  border: 1px solid #dc2828;
`
const Flex = styled.div`
  display: flex;
  height: 268px;
  padding: 0 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  @media screen and (max-width: 575px) {
    height: auto;
  }
`
const User = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .userImage {
    display: flex;
    width: 44px;
    height: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    border: 1px solid #e4e4e7;
    background: #fff;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .name {
      color: #1a1a1a;
      font-size: 16px;
      font-style: normal;
      /* font-weight: 500; */
      font-weight: bold;
      line-height: 24px;
      color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
    }
    .status {
      a {
        color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
      }
    }
  }
`

const UserMenuV2 = () => {
  const { userData } = useContext(UserContext)
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chainId, isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { isMobile, isTablet } = useMatchBreakpoints()
  const history = useHistory()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const avatarSrc = null
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const UserMenuItems = () => {
    const linkV1 = [
      // { title: 'Home', href: '/', img: `${images.home}` },
      { title: 'Pool', href: '/pools', img: `${images.iconPool}` },
      { title: 'Referral', href: '/referral', img: `${images.iconPool}` },
      { title: 'OPS', href: '/ops', img: `${images.iconPool}` },
      { title: 'Commissions', href: '/commissions', img: `${images.iconPool}` },
      { title: 'Tokenomic', href: '/tokenomic', img: `${images.iconTokenomic}` },
      // { title: 'Your Wallet', href: `/profile?id=${userData?.userId}`, img: `${images.iconyourwallet}` },
      // { title: 'Message', href: '/messages', img: `${images.Message}` },
    ]
    const link = [
      { title: 'Home', href: '/', img: `${images.home}` },
      { title: 'Signal Hub', href: '#', img: `${images.Signal}` },
      { title: 'News', href: '#', img: `${images.News}` },
      { title: 'Ranking', href: '#', img: `${images.Ranking}` },
      { title: 'Pool', href: '/pools', img: `${images.iconPool}` },
      { title: 'Tokenomic', href: '/tokenomic', img: `${images.iconTokenomic}` },
    ]
    const arr = [
      { title: 'Your Wallet', href: `/profile?id=${userData?.userId}`, img: `${images.Wallet}` },
      { title: 'Friends', href: '/', img: `${images.Friends}` },
      { title: 'Message', href: '/messages', img: `${images.Message}` },
    ]

    const handledNextPage = (_to) => {
      if (!account) {
        onPresentConnectModal()
        return
      }
      if (!userData.userId) {
        connetSocial()
        return
      }
      if (_to.length > 0) {
        history.push(_to)
      }
    }

    return (
      <>
        {/* {isMobile && ( */}
        <Wrapper style={{ gap: '30px' }}>
          <Menu>
            {linkV1.map((i, ix) => (
              <Link key={ix} to={i.href} className="active">
                <ItemV1>
                  <p>{i.title}</p>
                </ItemV1>
              </Link>
            ))}
          </Menu>

          {/* <Menu>
              {link.map((i, ix) => (
                <Link key={ix} to={i.href} className="active">
                  <Item>
                    <img src={i.img} alt="icon" />
                    <p>{i.title}</p>
                  </Item>
                </Link>
              ))}
            </Menu>
            
            {/* <Menu>
              {link.map((i, ix) => (
                <Link key={ix} to={i.href} className="active">
                  <Item>
                    <img src={i.img} alt="icon" />
                    <p>{i.title}</p>
                  </Item>
                </Link>
              ))}
            </Menu>
            <ButtonShowMenuAccount className="showmenuaccount">
              Account Access
              <a>
                <img src={images.dot} alt="" />
              </a>
            </ButtonShowMenuAccount>
            <Menu>
              {arr.map((i, ix) => (
                <StyledItem key={ix} onClick={() => handledNextPage(i.href)} className="active">
                  <Item>
                    <img src={i.img} alt="icon" />
                    <p>{i.title}</p>
                  </Item>
                </StyledItem>
              ))}
            </Menu> */}
          <Flex style={{ paddingBottom: '60px' }}>
            {/* <User>
                <div className="userImage">
                  <img src={images.user} alt="" />
                </div>
                <div className="userInfo">
                  <div className="name">Brooklyn Simmons</div>
                  <div className="status">
                    <Link to={``}>View Profile</Link>
                  </div>
                </div>
              </User> */}
            <ButtonConnect>
              <img src={images.wallet_icon} alt="wallet" />
              {truncateHash(account)}
            </ButtonConnect>
            <ButtonSignIn onClick={logout}>Sign out</ButtonSignIn>
          </Flex>
        </Wrapper>
        {/* // )} */}
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton scale="sm">
      <Box display={['none', , , 'block']}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
          <img width={22} height={22} src={images.walletiwhite} alt="wallet" />
          <Trans>Connect Wallet</Trans>
        </div>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenuV2

import { Box, InjectedModalProps, ModalPostStatus } from '@pancakeswap/uikit'
import { useTranslation, ContextApi } from '@pancakeswap/localization'
import useEditProfile, { Views } from './reducer'
import {
  BtnIntroduction,
  Container,
  ErrorInput,
  StyledAvatar,
  StyledError,
  StyledFrom,
  StyledTextarea,
  Title,
  Wrapper,
} from './styled'
import { useEffect, useState } from 'react'
import usersApi from 'api/usersApi'
import images from 'configs/images'
import axios from 'axios'
import EditAvatar from '../EditAvatar'
import EditBanner from '../EditBanner'

interface EditProfileModalProps extends InjectedModalProps {
  onSuccess?: () => void
  userInfo: any
  setUserInfo: any
  userCt: any
  setUserCt: React.Dispatch<React.SetStateAction<any>>
}

interface Data {
  address: string
  avatar: string
  banner: string
  bio: string
  birthday: string
  createdAt: string
  email: string
  followers: number
  following: number
  genderId: number
  name: string
  phoneNumber: string
  relationship: string[]
  role: string
  topFriends: string[]
  totalFriends: number
  __v: number
  _id: string
}

interface FormData {
  name: string
  avatar: string
  banner: string
  bio: string
  birthday: string
  phone: string
  email: string
  gender: number
}

const viewTitle = (t: ContextApi['t'], currentView: Views) => {
  switch (currentView) {
    case Views.START:
      return t('Edit Profile')
    case Views.CHANGE:
      return t('Change Profile')
    case Views.REMOVE:
      return t('Remove Profile')
    case Views.APPROVE:
      return t('Enable')
    default:
      return ''
  }
}

const EditProfileModal: React.FC<React.PropsWithChildren<EditProfileModalProps>> = ({
  userInfo,
  setUserInfo,
  onDismiss,
  onSuccess,
  userCt,
  setUserCt,
}) => {
  const { currentView } = useEditProfile()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [field, setField] = useState('')
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    avatar: '',
    banner: '',
    bio: '',
    birthday: '',
    phone: '',
    email: '',
    gender: 0,
  })

  useEffect(() => {
    const fethchUserMe = async () => {
      try {
        const response = await usersApi.get()
        const isoString = response.data.birthday
        const formattedDate = isoString.split('T')[0]
        setFormData({
          name: response.data.name,
          avatar: response.data.avatar,
          banner: response.data.banner,
          bio: response.data.bio,
          birthday: formattedDate,
          phone: response.data.phoneNumber,
          email: response.data.email,
          gender: response.data.gender,
        })
      } catch (error) {
        console.log('Failed to fetch list posts: ', error)
      }
    }
    fethchUserMe()
  }, [])

  const handleFieldChange = (field: string, value: string) => {
    if (field === 'name' && value.length > 40) {
      return
    }
    setFormData({
      ...formData,
      [field]: value,
    })

    if (field === 'name') {
      if (value.trim() === '') {
        setMessage('Name cannot be empty')
      } else {
        const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/
        if (specialCharactersRegex.test(value)) {
          setMessage('Name cannot contain special characters')
        } else {
          setMessage('')
        }
      }
    }
    if (field === 'birthday') {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/
      if (!dateRegex.test(value)) {
        setMessage('Invalid date')
      } else {
        setMessage('')
      }
    }
    if (field === 'phone') {
      const phoneRegex = /^[0-9]{10,12}$/
      if (!phoneRegex.test(value)) {
        setMessage('Phone number must be between 10 and 12 digits')
      } else {
        setMessage('')
      }
    }
    if (field === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValidEmail = emailRegex.test(value)
      if (!isValidEmail) {
        setMessage('Invalid email format')
      } else {
        setMessage('')
      }
    }
  }

  const sendFileToIPFS = async (e, _filed) => {
    e.preventDefault()
    try {
      setLoading(true)
      setField(_filed)
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      const resFile = await axios({
        method: 'post',
        url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
        data: formData,
        headers: {
          pinata_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_KEY}`,
          pinata_secret_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_SECRET}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`
      if (_filed === 'avatar') {
        handleFieldChange('avatar', ImgHash)
      } else {
        handleFieldChange('banner', ImgHash)
      }
      // console.log('avatar', ImgHash)
      setField('')
      setLoading(false)
    } catch (error) {
      setField('')
      setLoading(false)
      console.log('Error sending File to IPFS: ', error)
    }
  }

  const mergeApiDataWithUserInfo = (userInfo, apiData) => {
    const updatedData = { ...userInfo }

    Object.keys(apiData).forEach((field) => {
      updatedData[field] = apiData[field]
    })
    return updatedData
  }

  const fethchEditProfile = async () => {
    try {
      const params = {
        email: formData.email,
        name: formData.name,
        avatar: formData.avatar,
        banner: formData.banner,
        phoneNumber: formData.phone,
        genderId: formData.gender,
        birthday: formData.birthday,
        bio: formData.bio,
      }
      const response = await usersApi.put(params)
      window?.localStorage?.setItem('user_social', JSON.stringify({ userInfo: response.data }))
      setUserCt(true)
      const updatedData = mergeApiDataWithUserInfo(userInfo || {}, response.data)
      setUserInfo(updatedData)
      onDismiss()
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const formattedDate = formData.birthday ? new Date(formData.birthday).toLocaleDateString('en-GB') : ''

  return (
    <ModalPostStatus title={viewTitle(t, currentView)} onDismiss={onDismiss}>
      <Wrapper>
        <Container>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Title>Avatar</Title>
            <StyledAvatar>
              <label htmlFor="UpAvatarEdit" className="custom-file-2">
                <EditAvatar
                  src={formData?.avatar || '/images/homepage/avatar_none1.png'}
                  alt={t('User profile picture')}
                  onSuccess={() => {
                    onSuccess?.()
                  }}
                  loading={field === 'avatar' && loading}
                />
              </label>
              <input
                type="file"
                id="UpAvatarEdit"
                name="files[]"
                accept="image/*"
                onChange={(e) => sendFileToIPFS(e, 'avatar')}
              />
            </StyledAvatar>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Title>Cover image</Title>
            <StyledAvatar style={{ border: '1px solid #e4e4e7', borderRadius: '8px' }}>
              <label htmlFor="UpBannerEdit" className="custom-file-2">
                <EditBanner
                  src={formData?.banner || ''}
                  alt={t('banner')}
                  onSuccess={() => {
                    onSuccess?.()
                  }}
                  loading={field === 'banner' && loading}
                />
              </label>
              <input
                type="file"
                id="UpBannerEdit"
                name="files[]"
                accept="image/*"
                onChange={(e) => sendFileToIPFS(e, 'banner')}
              />
            </StyledAvatar>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Title>Bio</Title>
              {/* <Title style={{ color: '#dc2828', fontWeight: '500', fontSize: '16px', cursor: 'pointer' }}>Edit</Title> */}
            </div>
            <StyledAvatar>
              <StyledTextarea
                value={formData.bio}
                placeholder="Enter bio"
                onChange={(e) => handleFieldChange('bio', e.target.value)}
              />
            </StyledAvatar>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Title>Edit introduction</Title>
              {/* <Title style={{ color: '#dc2828', fontWeight: '500', fontSize: '16px', cursor: 'pointer' }}>Edit</Title> */}
            </div>
            <div>
              <StyledFrom>
                <img src={images.iconusername} alt="name" />
                <input
                  id="name"
                  value={
                    formData.name.length > 40
                      ? `${formData.name.substring(0, 5)}...${formData.name.substring(formData.name.length - 5)}`
                      : formData.name
                  }
                  maxLength={40}
                  type="text"
                  placeholder="..."
                  onChange={(e) => handleFieldChange('name', e.target.value)}
                />
              </StyledFrom>
              <StyledFrom>
                <img src={images.birthday} alt="birthday" />
                <input
                  style={{ cursor: 'pointer' }}
                  type="date"
                  name="trip-start"
                  value={formData.birthday}
                  onChange={(e) => handleFieldChange('birthday', e.target.value)}
                  min="1975-01-01"
                />
                <span className="open-button">
                  <button type="button">
                    <img style={{ cursor: 'pointer' }} src={images.dayccc} alt="" />
                  </button>
                </span>
              </StyledFrom>
              <StyledFrom>
                <img src={images.phoneicon} alt="phone" />
                <input
                  type="text"
                  value={formData.phone}
                  placeholder="..."
                  onChange={(e) => handleFieldChange('phone', e.target.value)}
                />
              </StyledFrom>
              <StyledFrom>
                <img src={images.email} alt="email" />
                <input
                  type="text"
                  value={formData.email}
                  placeholder="..."
                  onChange={(e) => handleFieldChange('email', e.target.value)}
                />
              </StyledFrom>
              {/* <StyledFrom>
              <img style={{width: "22px", height: "18px"}} src={images.venus} alt="gender" />
              <input type="text" value={gender === 0 ? 'Boy' : 'Girl'} placeholder="..." onChange={(e) => handleFieldChange('gender', Number(e.target.value))} />
            </StyledFrom> */}
            </div>
          </Box>
          {message.length > 0 && (
            <Box>
              <StyledError>
                <ErrorInput>{message}</ErrorInput>
              </StyledError>
            </Box>
          )}
        </Container>
      </Wrapper>
      <Box>
        <div style={{ padding: '0 12px' }}>
          <BtnIntroduction disabled={message.length > 0} onClick={fethchEditProfile} type="button">
            Edit information
          </BtnIntroduction>
        </div>
      </Box>
    </ModalPostStatus>
  )
}

export default EditProfileModal

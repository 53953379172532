import { PropsWithChildren, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
// import { trendyColors } from 'style/trendyTheme'
import { Table, Th, Td, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { getPoolsV3Contract, getPoolsV4Contract } from 'utils/contractHelpers'
import CountUp from 'react-countup'
// import { Pool, PoolV4, timeDisplay } from 'views/PoolV2/util'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import moment from 'moment'
import { formatEther } from '@ethersproject/units'
import images from 'configs/images'
import { ChainId } from '../../../../../../packages/swap-sdk/src/constants'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
// import ProgressBar from '../../progressBar'
// import useOnScreen from 'hooks/useOnScreen'
import numeral from 'numeral'
import { isMobile } from 'react-device-detect'
// STYLE
const TableScroll = styled.div`
  // max-width: 100%;
  width: auto;
  &.scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &.scroll::-webkit-scrollbar-track {
    background-color: white;
  }
  &.scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(0, 240, 225);
  }
  @media screen and (max-width: 1070px) {
    overflow-x: auto;
    width: 100%;
  }
  Th {
    padding: 20px 10px;
    @media screen and (max-width: 896px) {
      padding: 20px 25px;
    }
    @media screen and (max-width: 530px) {
      padding: 20px 16px;
    }
  }
  Td {
    border-bottom: 0;
    padding: 10px 22px;
    @media screen and (max-width: 730px) {
      padding: 10px 10px;
    }
  }
  .imagesvector {
    padding: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }
`
const TableHeader = styled.span`
  width: 100%;
  font-size: 26px;
  padding: 40px 0;
  display: flex;
  justify-content: start;
  @media (max-width: 575px) {
    padding: 30px 0;
  }
`
const TablePool = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  table > thead {
    tr {
      font-weight: 300;
      border-bottom: 1px solid black;
    }
  }

  @media screen and (max-width: 360px) {
    width: 100%;
  }
`
const AmountData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`
const responsiveTextTH = ['11px', '12px', '16px', '16px', '16px']
const responsiveTextSizeBNB = ['9px', '10px', '12px', '14px', '16px']
const responsiveTextSizeHeader = ['16px', '18px', '22px', '26px', '30px']

const TableDataPool = ({ pool, usersClaimed, userClaimedLength, ...props }) => {
  const { account, chainId } = useActiveWeb3React()
  const total = pool.totalRewardUSD + pool.remainRewardUSD
  const amountClaim = total - pool.remainRewardUSD
  const amount = useRef('')
  const ref = useRef(null)
  const getRemain = () => {
    var array = []
    const totalRemain = pool.totalRewardUSD + pool.remainRewardUSD
    array.push(totalRemain)
    amount.current = `$ ${numeral(totalRemain - pool.remainRewardUSD).format('0,0')} / $ ${numeral(totalRemain).format(
      '0,0',
    )}`
    for (let i = 0; i < usersClaimed.length; i++) {
      const arrRemain = Number(array[i]) - Number(usersClaimed[i].amountUSD)
      array.push(arrRemain)
    }

    return array
  }
  const getNewArray = () => {
    const remainArr = getRemain().slice(1)
    const arr = usersClaimed.map((i, r) => ({
      ...i,
      remain: remainArr[r],
    }))
    return arr
  }
  useEffect(() => {
    getRemain()
    getNewArray()
  }, [account])

  const renderClaimHistory = () => {
    return (
      <>
        {pool.userTotalLock > 0 && (
          <tr>
            <Td textAlign={'center'}>
              <Text style={{ color: 'white', lineHeight: '18px' }} fontSize={responsiveTextSizeBNB}>
                <p style={{ fontSize: isMobile ? '12px' : '16px' }}>{moment().format('DD/MM/YYYY')}</p>
                <p style={{ fontSize: isMobile ? '10px' : '12px' }}>{moment.unix(pool.timeStake).format('HH:mm:ss')}</p>
              </Text>
            </Td>
            <Td textAlign={'center'}>
              <Text style={{ color: 'white', textAlign: 'end' }} fontSize={responsiveTextSizeBNB}>
                {pool.currentInterestWithMine ? (
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={Number((30 / 365) * pool.currentInterestWithMine)}
                    decimals={2}
                    duration={0.5}
                  ></CountUp>
                ) : (
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={Number((30 / 365) * pool.currentInterest)}
                    decimals={2}
                    duration={0.5}
                  />
                )}
                %
              </Text>
            </Td>
            <Td textAlign={'right'}>
              {pool.userTotalLock === 0 ? (
                <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                  0
                </Text>
              ) : (
                <AmountData>
                  <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}
                  >
                    $
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.totalLockUSD}
                      decimals={pool.userTotalLock > 0 ? 1 : 0}
                      duration={0.5}
                      separator=","
                    />
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'right'}>
              {pool.totalRewardUSD === 0 ? (
                <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                  $0
                </Text>
              ) : (
                <AmountData>
                  <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}
                  >
                    $
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.totalRewardUSD}
                      decimals={pool.totalRewardUSD > 0 ? 1 : 0}
                      duration={0.5}
                      separator=","
                    />
                  </Text>
                </AmountData>
              )}
            </Td>
            {/* maxout */}
            <Td textAlign={'center'}>
              <AmountData>
                <Text
                  fontSize={responsiveTextSizeBNB}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}
                >
                  $
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={total}
                    decimals={total > 0 ? 1 : 0}
                    duration={0.5}
                    separator=","
                  />
                </Text>
              </AmountData>
            </Td>
            <Td textAlign={'center'}>
              <AmountData>
                <Text
                  fontSize={responsiveTextSizeBNB}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}
                >
                  $
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={pool.useOut}
                    decimals={pool.useOut > 0 ? 1 : 0}
                    duration={0.5}
                    separator=","
                  />
                </Text>
              </AmountData>
            </Td>
            <Td textAlign={'center'}>
              <AmountData>
                <Text
                  fontSize={responsiveTextSizeBNB}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}
                >
                  $
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={pool.remainRewardUSD}
                    decimals={pool.totalRewardUSD + pool.remainRewardUSD > 0 ? 1 : 0}
                    duration={0.5}
                    separator=","
                  />
                </Text>
              </AmountData>
            </Td>
            <Td textAlign={'right'}>
              {pool.currentReward === 0 ? (
                <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                  0
                </Text>
              ) : (
                <AmountData>
                  <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                    $
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.currentReward * pool.rateBNB2USD}
                      decimals={pool.currentReward > 0 ? 1 : 0}
                      duration={0.5}
                      separator=","
                      style={{ marginRight: 6 }}
                    />
                  </Text>
                  {/* <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}
                  >
                    ~
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.currentReward}
                      separator=","
                      decimals={pool.currentReward > 0 ? 1 : 0}
                      duration={0.5}
                    />
                    <h4 style={{ color: '#ff0420' }}> OP</h4>
                  </Text> */}
                </AmountData>
              )}
            </Td>
            <Td textAlign={'center'}>
              <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                <span>{pool.currentReward <= 0 ? 'Waiting' : 'Claimable'}</span>
              </Text>
            </Td>
          </tr>
        )}
        {renderHistory()}
      </>
    )
  }
  const renderHistory = () => {
    return (
      <>
        {userClaimedLength > 0 &&
          getNewArray().map((claimHistory, index) => {
            return (
              <tr key={index}>
                <Td textAlign={'center'}>
                  <Text style={{ color: 'white', lineHeight: '18px' }} fontSize={responsiveTextSizeBNB}>
                    {chainId === 97 ? (
                      <>
                        <p style={{ fontSize: isMobile ? '12px' : '16px' }}>
                          {moment.unix(claimHistory.date * 300).format('DD/MM/YYYY')}
                        </p>
                        <p style={{ fontSize: isMobile ? '10px' : '12px' }}>
                          {moment.unix(claimHistory.date * 300).format('HH:mm:ss')}
                        </p>
                      </>
                    ) : (
                      <>
                        <p style={{ fontSize: isMobile ? '12px' : '16px' }}>
                          {moment.unix(claimHistory.date * 86400).format('DD/MM/YYYY')}
                        </p>
                        <p style={{ fontSize: isMobile ? '10px' : '12px' }}>
                          {moment.unix(claimHistory.date * 86400).format('HH:mm:ss')}
                        </p>
                      </>
                    )}
                  </Text>
                </Td>
                <Td textAlign={'right'}>
                  <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={(30 / 365) * claimHistory.interest}
                      decimals={2}
                      duration={0.5}
                      separator=","
                    />
                    %{/* {(30 / 365) * claimHistory.interest} */}
                  </Text>
                </Td>
                <Td textAlign={'right'}>
                  {claimHistory.totalLock === 0 ? (
                    <Text fontSize={responsiveTextSizeBNB}>0</Text>
                  ) : (
                    <AmountData>
                      <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={pool.totalLockUSD}
                          decimals={claimHistory.totalLock > 0 ? 1 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Text>
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'right'}>
                  {pool.totalRewardUSD + pool.remainRewardUSD === 0 ? (
                    <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                      0
                    </Text>
                  ) : (
                    <AmountData>
                      <Text
                        fontSize={responsiveTextSizeBNB}
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}
                      >
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={pool.totalRewardUSD + pool.remainRewardUSD}
                          decimals={pool.totalRewardUSD + pool.remainRewardUSD > 0 ? 1 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Text>
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'right'}>
                  {claimHistory.totalLock === 0 ? (
                    <Text fontSize={responsiveTextSizeBNB}>0</Text>
                  ) : (
                    <AmountData>
                      <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                        --/--
                      </Text>
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'right'}>
                  {claimHistory.totalLock === 0 ? (
                    <Text fontSize={responsiveTextSizeBNB}>0</Text>
                  ) : (
                    <AmountData>
                      <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                        --/--
                      </Text>
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'right'}>
                  {pool.totalRewardUSD + pool.remainRewardUSD === 0 ? (
                    <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                      0
                    </Text>
                  ) : (
                    <>
                      <AmountData>
                        <Text
                          fontSize={responsiveTextSizeBNB}
                          style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}
                        >
                          $
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={claimHistory.remain}
                            decimals={claimHistory.remain > 0 ? 1 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Text>
                      </AmountData>
                    </>
                  )}
                </Td>
                <Td textAlign={'right'}>
                  {claimHistory.amount === 0 ? (
                    <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                      0
                    </Text>
                  ) : (
                    <AmountData>
                      <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={claimHistory.amountUSD}
                          decimals={claimHistory.amount > 0 ? 1 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Text>
                      {/* <Text style={{ color: 'white', display: 'flex' }} fontSize={responsiveTextSizeBNB}>
                        ~
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={claimHistory.amount}
                          decimals={claimHistory.amount > 0 ? 1 : 0}
                          duration={0.5}
                          separator=","
                        />
                        <h4 style={{ color: '#ff0420' }}> OP</h4>
                      </Text> */}
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'center'}>
                  <Text style={{ color: 'white' }} fontSize={responsiveTextSizeBNB}>
                    Claimed
                  </Text>
                </Td>
              </tr>
            )
          })}
      </>
    )
  }

  const progress = Math.round((amountClaim / total) * 100)
  return (
    <div ref={ref}>
      <TableHeader>
        <Text style={{ color: 'white' }} fontSize={responsiveTextSizeHeader}>
          Your Income{' '}
        </Text>
      </TableHeader>
      {/* {userClaimedLength > 0 && <ProgressBar title={amount.current} isVisible={isVisible} progress={progress} />} */}
      {/* <ProgressBar title={amount.current} isVisible={isVisible} progress={progress} /> */}
      <TablePool>
        <TableScroll className="scroll">
          <table>
            <thead>
              <tr>
                <Th textAlign="left">
                  <Text
                    className="th"
                    style={{ color: '#777E90', fontWeight: '600' }}
                    // color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                    textAlign="center"
                  >
                    Date Time
                  </Text>
                </Th>
                <Th textAlign="center">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Interest (%)
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Your Lock
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Total Reward
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    // color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    MaxOut
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    // color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Out
                  </Text>
                </Th>
                <Th>
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    // color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Remain
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    // color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Your Income
                  </Text>
                </Th>
                <Th textAlign="center">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    // color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Action
                  </Text>
                </Th>
              </tr>
            </thead>
            <tbody>
              <>{renderClaimHistory()}</>
            </tbody>
          </table>
        </TableScroll>
      </TablePool>
    </div>
  )
}
export default TableDataPool

import { createContext, useState, Dispatch, SetStateAction, ReactNode, useContext, useEffect } from 'react'

export type User = {
  userId: string
  address: string
  balance: number
  avatar: string
  banner: string
  email: string
  bio: string
  name: string
  phoneNumber: number
  gender: number
  birthday: number
}

export interface UserContextInterface {
  userData: User
  setUser: Dispatch<SetStateAction<User>>
  clearData: () => void
}

const defaultState = {
  userData: {
    userId: '',
    address: '',
    balance: 0,
    avatar: '',
    banner: '',
    email: '',
    bio: '',
    name: '',
    phoneNumber: 0,
    gender: 0,
    birthday: 0,
  },
  // setUser: () => {},
} as UserContextInterface

export const UserContext = createContext(defaultState)
type UserProviderProps = {
  children: ReactNode
  initialUserData?: any
}

export default function UserProvider({ children, initialUserData }: UserProviderProps) {
  const [userData, setUser] = useState<User>({
    userId: '',
    address: '',
    balance: 0,
    avatar: '',
    banner: '',
    email: '',
    bio: '',
    name: '',
    phoneNumber: 0,
    gender: 0,
    birthday: 0,
  })
  
  useEffect(() => {
    if (initialUserData && initialUserData.userInfo) {
      setUser(initialUserData.userInfo)
    }
  }, [initialUserData])

  const clearData = () => {
    setUser({
      userId: '',
      address: '',
      balance: 0,
      avatar: '',
      banner: '',
      email: '',
      bio: '',
      name: '',
      phoneNumber: 0,
      gender: 0,
      birthday: 0,
    });
  };

  return <UserContext.Provider value={{ userData, setUser, clearData }}>{children}</UserContext.Provider>
}
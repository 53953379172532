import { Button, Flex, Heading, LinkExternalDepo, useModal, Text, LinkExternal, useToast } from '@pancakeswap/uikit'
import PageHeader from 'components/PageHeader'
import styled from 'styled-components'
import images from 'configs/images'
import { FC, useEffect, useState } from 'react'
import contracts from 'config/constants/contracts'
import { isMobile } from 'react-device-detect'
import { formatEther } from '@ethersproject/units'
import moment from 'moment'
import { getContract, getPoolsContract, getPoolsV4Contract } from 'utils/contractHelpers'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import ClaimPoolModal from './ClaimModal'
import TableDataPool from './TableData'
import DetailInfoPool from './DetailInfo'
import DepositPoolModal, { ThreeDots } from './DepositModal'
import { ChainId, NATIVE } from '../../../../../packages/swap-sdk/src/constants'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import OPAbi from 'config/abi/OP.json'
import { useSigner } from 'wagmi'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useTranslation } from '@pancakeswap/localization'

const Wrapper = styled.div`
  * {
    font-family: Inter, sans-serif;
  }

  background: var(--greyscale-background-dark, #0a090d);
  width: 100%;
  margin-top: 80px;

  .flexx {
    margin-top: 40px;
    @media (max-width: 768px) {
      margin-top: 50px;
    }
    @media (max-width: 575px) {
      margin-top: 20px;
    }
  }

  .root {
    display: flex;
    flex-direction: row;
    gap: 12px;
    @media (max-width: 398px) {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  .headingg {
    @media (max-width: 575px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .bg {
    background: url(${images.bgV3}) no-repeat;
    background-size: 100% 100%;
  }
`
const Body = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: none;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  @media (max-width: 575px) {
    padding: 0 20px;
  }
`
const PoolName = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .title {
    color: rgb(255, 4, 32);
  }
`
const PoolLogo = styled.img`
  width: 80px;
  height: auto;
  @media screen and (max-width: 1028px) {
    width: 80px;
  }
  @media screen and (max-width: 851px) {
    width: 70px;
  }
  @media screen and (max-width: 575px) {
    width: 60px;
  }
  @media screen and (max-width: 450px) {
    width: 50px;
  }
`
const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const BtnBack = styled(Link)`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`

const PoolDetail = () => {
  type Employee = {
    id?: number
    chainIds?: string
  }
  const poolsV2 = useParams()
  const abc: Employee = poolsV2
  const poolId = Number(abc.id)
  const { account, chainId } = useActiveWeb3React()
  // account = '0x11205d2c06C2F392C1C20aCFa2b2f2de1882608e'
  // account = '0x15d075D54B0dB34eE43B98a32D62ddcF2c5a83a3'
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [isLoading, setIsLoading] = useState(true)
  const [loadingModalClaim, setLoadingModalClaim] = useState(false)
  const [now, setNow] = useState(0)
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const getPoolContract = getPoolsContract(CHAIN_ID)
  const [usersClaimed, setUserClaimed] = useState([])
  const unit = NATIVE[chainId].symbol
  const { data: signer } = useSigner()
  const [amountApprove, setAmountApprove] = useState(0)
  const [isApprove, setIsApprove] = useState(false)
  const OPContract = getContract({
    address: contracts.OP[CHAIN_ID],
    abi: OPAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [poolInfo, setPoolInfo] = useState({
    currentInterest: 0,
    enable: true,
    maxLock: 0,
    minLock: 0,
    timeLock: 0,
    timeStake: 0,
    totalLock: 0,
    totalLockUser: 0,
    totalLockUSD: 0,
    pid: 0,
    currentReward: 0,
    currentRewardV1: 0,
    totalReward: 0,
    totalRewardUSD: 0,
    remainRewardUSD: 0,
    startTime: 0,
    userClaimedLength: 0,
    userTotalLock: 0,
    rateBNB2USD: 1,
    unit: '',
    minUSD2BNB: 0,
    maxUSD2BNB: 0,
    maxOut: 0,
    useOut: 0,
    // currentInterestWithMine: 0,
  })

  const getPool = async () => {
    // try {
    if (!account) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
      const pool = await getPoolContract.pools(poolId)
      const currentReward = await getPoolContract.currentReward(poolId, account)
      const currentRewardV1 = await getPoolContract.currentRewardV1(poolId, account)
      const maxOut = await getPoolContract.usermaxOutUSD(account)
      const useOut = await getPoolContract.userOutUSD(account)
      const rateBnbUsd = await getPoolContract.OP2USDT()
      const users = await getPoolContract.users(account, poolId)
      const minMaxUSD2BNB = await getPoolContract.minMaxUSD2OP(poolId)
      const getUsersClaimedLength = await getPoolContract
        .getUsersClaimedLength(poolId, account)
        .then((rs) => Number(rs))
      setPoolInfo({
        currentInterest: (Number(pool.currentInterest.toString()) / 10000) * 365,
        enable: pool.enable,
        maxLock: Number(formatEther(pool.maxLock)),
        minLock: Number(formatEther(pool.minLock)),
        timeLock: 63072000,
        timeStake: Number(users.startTime),
        totalLock: Number(formatEther(pool.totalLock)),
        totalLockUser: Number(formatEther(users.totalLock)),
        totalLockUSD: Number(formatEther(users.totalLockUSD)),
        pid: poolId,
        currentReward: Number(formatEther(currentReward)),
        currentRewardV1: Number(formatEther(currentRewardV1)),
        totalReward: Number(formatEther(users.totalReward)),
        totalRewardUSD: Number(formatEther(users.totalRewardUSD)),
        remainRewardUSD: Number(formatEther(users.remainRewardUSD)),
        startTime: Number(users.startTime),
        userTotalLock: Number(formatEther(users.totalLock)),
        userClaimedLength: getUsersClaimedLength,
        rateBNB2USD: Number(formatEther(rateBnbUsd)),
        unit,
        minUSD2BNB: Number(formatEther(minMaxUSD2BNB._min)),
        maxUSD2BNB: Number(formatEther(minMaxUSD2BNB._max)),
        maxOut: Number(formatEther(maxOut)),
        useOut: Number(formatEther(useOut)),
      })

      if (getUsersClaimedLength > 0) {
        let roundLoop = Math.floor(getUsersClaimedLength / 400)
        const endRound = getUsersClaimedLength % 400
        if (endRound !== 0) roundLoop += 1
        let claimArr = []
        let skip = 0
        for (let i = 0; i < roundLoop; i++) {
          const limit = endRound > 0 && i === roundLoop - 1 ? endRound : 100
          const { list } = await getPoolContract.getUsersClaimed(poolId, account, limit, skip)
          claimArr = claimArr.concat(list)
          skip += limit
          if (i === roundLoop - 1) {
            claimArr.sort((a, b) => b.date - a.date)
            setUserClaimed(
              claimArr.map((claimed: any, i: number) => {
                return {
                  amount: Number(formatEther(claimed.amount)),
                  amountUSD: Number(formatEther(claimed.amountUSD)),
                  date: Number(claimed.date.toString()),
                  interest: (Number(claimed.interrest.toString()) / 10000) * 365,
                  totalLock: Number(formatEther(claimed.totalLock)),
                  totalLockUSD: Number(formatEther(claimed.totalLockUSD)),
                }
              }),
            )
          }
        }
      }
      setIsLoading(false)
    }
    // } catch (e) {
    //   console.log(e)
    // }
  }
  const handleSuccess = () => {
    getPool()
  }

  const [openDepositModal, onDismiss] = useModal(
    <DepositPoolModal
      allowance={amountApprove}
      onDismiss={() => onDismiss()}
      pool={poolInfo}
      onSuccess={handleSuccess}
      account={account}
      chainId={chainId}
    />,
    true,
  )
  const checkApprove = async () => {
    const allowance = await OPContract.allowance(account, getPoolContract.address)
    if (Number(formatEther(allowance)) > 0) {
      setIsApprove(true)
    } else {
      setIsApprove(false)
    }
  }

  const max = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      return callWithMarketGasPrice(OPContract, 'approve', [getPoolContract.address], [max])
    },
    onSuccess: async ({ receipt }) => {
      // setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Approve successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      checkApprove()
    },
  })
  const handleOpenDepositModal = () => {
    openDepositModal()
  }

  const [openClaimModal, onDismissModal] = useModal(
    <ClaimPoolModal
      loadingModalClaim={loadingModalClaim}
      setLoadingModalClaim={setLoadingModalClaim}
      pool={poolInfo}
      account={account}
      onSuccess={handleSuccess}
      onDismiss={() => onDismissModal()}
    />,
    true,
  )
  // const [openUnlockModal] = useModal(<WithDrawModal pool={poolInfo} onSuccess={handleSuccess} account={account} />)
  useEffect(() => {
    getPool()
    checkApprove()
  }, [account])
  useEffect(() => {
    setInterval(() => {
      setNow(moment().unix())
    }, 1000)
  }, [])
  const shortenURL = (s: string, max: number) => {
    return s.length > max ? s.substring(0, max / 2 - 1) + '...' + s.substring(s.length - max / 2 + 2, s.length) : s
  }
  return (
    <Wrapper>
      {isLoading === true ? (
        <TrendyPageLoader />
      ) : (
        <div className="bg">
          <img
            style={{ position: 'absolute', top: '200px', width: '100%', zIndex: '-1' }}
            src={images.bgV3}
            alt="iconback"
          />
          <PageHeader background="none">
            <BtnBack to="/pools">
              <img style={{ width: '20px', height: '20px' }} src={images.iconBack} alt="iconback" />
              Back
            </BtnBack>
            <Flex className="flexx" flex="1" flexDirection="column" mr={['80px', 0, 0]} alignItems="start">
              <PoolName>
                {/* <PoolLogo src={images.bscoin} alt="pool name" /> */}
                {/* <Text className="title" fontSize={['28px', '40px', '42px', '50px', '60px']} fontWeight="700">
                  OP
                </Text> */}
              </PoolName>
            </Flex>
          </PageHeader>
          <PageHeader background="none">
            <DetailInfoPool poolInfo={poolInfo} poolId={poolId} />
          </PageHeader>
          <Body>
            <ButtonArea>
              <Button
                style={{ color: '#000', backgroundColor: '#D9D9D9' }}
                variant={poolInfo.currentReward > 0 ? 'danger' : 'light'}
                disabled={
                  (poolInfo.currentRewardV1 > 0 &&
                    poolInfo.totalLockUSD > 0 &&
                    (poolInfo.currentReward === 0 ||
                      poolInfo.remainRewardUSD === 0 ||
                      poolInfo.totalReward >= poolInfo.maxOut ||
                      loadingModalClaim)) ||
                  (poolInfo.currentRewardV1 === 0 && poolInfo.totalLockUSD === 0) ||
                  (poolInfo.currentRewardV1 === 0 &&
                    poolInfo.totalLockUSD > 0 &&
                    (poolInfo.currentReward === 0 ||
                      poolInfo.remainRewardUSD === 0 ||
                      poolInfo.totalReward >= poolInfo.maxOut ||
                      loadingModalClaim))
                }
                width={['120px', '150px', '180px', '200px']}
                height={48}
                onClick={openClaimModal}
                scale={isMobile ? 'sm' : 'md'}
              >
                {loadingModalClaim ? (
                  <ThreeDots className="loading">
                    Claiming<span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </ThreeDots>
                ) : (
                  'Claim'
                )}
              </Button>
              {isApprove === false ? (
                <Button
                  style={{ background: '#ff0420', color: '#ffffff' }}
                  width={['120px', '150px', '180px', '200px']}
                  height={48}
                  onClick={handleConfirm}
                  disabled={isConfirming}
                  scale={isMobile ? 'sm' : 'md'}
                >
                  {isConfirming ? (
                    <ThreeDots className="loading">
                      Approving<span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </ThreeDots>
                  ) : (
                    'Approve'
                  )}
                </Button>
              ) : (
                <Button
                  style={{ background: '#ff0420', color: '#ffffff' }}
                  width={['120px', '150px', '180px', '200px']}
                  height={48}
                  onClick={() => handleOpenDepositModal()}
                  scale={isMobile ? 'sm' : 'md'}
                  // disabled={
                  //   chainId === 97
                  //     ? poolInfo.startTime > 0 && now - poolInfo.startTime > 604800
                  //     : poolInfo.startTime > 0 && now - poolInfo.startTime > 604800
                  // }
                >
                  Deposit
                </Button>
              )}
              {/* <Button
                style={{ background: '#ff0420', color: '#ffffff' }}
                width={['120px', '150px', '180px', '200px']}
                height={48}
                onClick={() => handleOpenDepositModal()}
                scale={isMobile ? 'sm' : 'md'}
                disabled={
                  chainId === 97
                    ? poolInfo.startTime > 0 && now - poolInfo.startTime > 3600
                    : poolInfo.startTime > 0 && now - poolInfo.startTime > 604800
                }
              >
                Deposit
              </Button> */}
            </ButtonArea>
          </Body>
          <PageHeader background="none">
            <TableDataPool pool={poolInfo} usersClaimed={usersClaimed} userClaimedLength={poolInfo.userClaimedLength} />
          </PageHeader>
        </div>
      )}
    </Wrapper>
  )
}
export const PoolDetailLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}
export default PoolDetail

import { BrowserRouter as Router, Route, Switch, Link, useRouteMatch, useParams } from 'react-router-dom'
import HomePage from 'views/HomePage1'
import NotFound from 'views/NotFound'
import Profile from 'views/Profile'
import Messages from 'views/Messages'
import Referral from 'views/Referral'
import Tokenomic from 'views/Tokenomic'
import Pool from 'views/Pool'
import PoolDetail from 'views/Pool/components/PoolDetails'
import Mining from 'views/Mining'
import PostDetailPage from 'components/Post/PostDetailPage'
import Commissions from 'views/Commissions'

export const NavV1 = () => {
  return (
    <>
      <Switch>
        <Route exact path="/social">
          <HomePage />
        </Route>
        <Route exact path="/profile?id=:id">
          <Profile />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/posts">
          <PostDetailPage />
        </Route>
        <Route exact path="/posts?id=:id">
          <PostDetailPage />
        </Route>
        <Route exact path="/referral">
          <Referral />
        </Route>
        <Route exact path="/">
          <Pool />
        </Route>
        <Route exact path="/pools">
          <Pool />
        </Route>
        <Route exact path={'/poolsDetails/:id/chainId=:chainId'}>
          <PoolDetail />
        </Route>
        <Route exact path="/ops">
          <Mining />
        </Route>
        <Route exact path="/commissions">
          <Commissions />
        </Route>
        <Route exact path="/tokenomic">
          <Tokenomic />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  )
}

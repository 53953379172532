import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  margin: 79px auto 0;
`
export const TotalLock = styled.div`
  width: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 767px) {
    padding: 20px;
    flex-direction: column;
  }
`
export const Title = styled.p`
  color: #1a1a1a;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
`
export const Text = styled.p`
  max-width: 552px;
  padding-top: 12px;
  color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`

export const StyledTotalLock = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  p {
    color: var(--neutral-600-gray, #68778d);
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  h4 {
    color: var(--neutral-900-text, #202327);
    text-align: right;
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
    @media (max-width: 575px) {
      font-size: 32px;
    }
  }
  span {
    color: var(--neutral-500, #8496ae);
    text-align: right;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 20px;
    align-items: flex-start;
  }
`
export const PoolRewards = styled.div`
  padding: 60px 20px;
  @media (max-width: 767px) {
    padding: 20px;
  }
`
// export const Rewards = styled.div`
//   position: relative;
//   display: flex;
//   padding: 40px;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 12px;
//   border-radius: 16px;
//   background: var(--neutral-100, #1a1e23);
//   box-shadow: 0px 8px 32px -6px rgba(20, 23, 26, 0.08), 0px 6px 20px -6px rgba(20, 23, 26, 0.12);
//   h3 {
//     color: var(--White-White, #fff);
//     font-family: Inter;
//     font-size: 28px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 36px;
//   }
//   h4 {
//     color: var(--White-White, #fff);
//     font-family: Inter;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 20px;
//     cursor: pointer;
//     &:hover {
//       text-decoration: underline;
//     }
//   }
//   p {
//     color: var(--White-White, #fff);
//     font-family: Inter;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     @media (max-width: 840px) {
//       max-width: 500px;
//     }
//   }
//   a {
//     color: var(--primary-700, #f71c35);
//     text-align: center;
//     font-family: Inter;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 20px;
//     cursor: pointer;
//   }
//   span {
//     color: var(--neutral-400, #a9b9cc);
//     font-family: Inter;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 20px;
//   }
//   @media (max-width: 767px) {
//     padding: 20px;
//     gap: 20px;
//   }
// `
export const StyledRewardsRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ListRank = styled.div`
  padding: 24px 0 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media (max-width: 767px) {
    gap: 20px;
  }
  @media (max-width: 575px) {
    padding: 20px 0;
  }
`
export const RankItem = styled.div`
  max-width: 346px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--primary-200, #ffdbdf);
  background: var(--neutral-0-white, #fff);
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.02), 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.16);
`
export const RankItemHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  p {
    color: var(--neutral-900-text, #202327);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
`
export const RankItemBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const RankItemBodyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  p {
    color: var(--neutral-600-gray, #68778d);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  h4 {
    color: var(--neutral-900-text, #202327);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
`
export const ListPools = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`
export const ListPoolsItem = styled.div`
  display: flex;
  max-width: 532px;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--primary-200, #ffdbdf);
  background: var(--neutral-0-white, #fff);
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.02), 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.16);
  @media (max-width: 575px) {
    padding: 20px;
  }
`
export const PoolsItemHeader = styled.div`
  height: 64px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  p {
    color: var(--neutral-900-text, #202327);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
`
export const ImgMine = styled.img`
  position: absolute;
  bottom: -16px;
  right: 0;
  @media (max-width: 575px) {
    width: 56px;
    height: 68px;
    bottom: -22px;
  }
`
export const PoolsItemMinmax = styled.div`
  display: flex;
  padding: 8px 24px;
  flex-direction: row;
  align-items: flex-end;
  align-self: stretch;
  border-radius: var(--Radius-radi-6, 12px);
  color: #000;
  background: #f1f4f9;
  @media (max-width: 575px) {
    padding: 12px 16px;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  }
  h4 {
    color: #202327;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  p {
    color: #68778d;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`
export const StyledMinMax = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`
export const PoolsItemBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  h4 {
    color: #202327;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  p {
    color: #68778d;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  span {
    color: #68778d;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`
export const StyledBodyItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const StyledBodyItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  h4 {
    color: #202327;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
export const PoolsItemFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
export const BtnDetail = styled.button`
  width: 80px;
  height: 40px;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #cbd5e0;
  background: #fff;
  cursor: pointer;

  color: var(--primary-600-default, #ff0420);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  &:hover {
    background: #cbd5e0;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`
export const BtnClose = styled.div`
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #000;
  z-index: 999;
  top: -6px;
  left: -55px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
export const BoxPrice = styled.div`
  max-width: 260px;
  width: 100%;
  z-index: 99;
  position: fixed;
  display: flex;
  padding: 18px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 2px solid transparent;
  background-image: linear-gradient(#fff, #fff), linear-gradient(180deg, #c45656 27%, #ef4343 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  gap: 12px;
  right: 1%;
  top: 50%;
  @media screen and (max-width: 800px) {
    padding: 12px;
    top: 60%;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`

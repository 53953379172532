import styled from 'styled-components'
import images from 'configs/images'
import Avatar from 'components/Avatar'

const EditOverlay = styled.div`
  background: rgba(0, 0, 0, 0.6) url('/images/icons/camera.svg') no-repeat center center;
  border-radius: 50%;
  left: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 300ms;
  width: 100%;
  z-index: 1;
`

const StyledEditProfileAvatar = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;

    ${EditOverlay} {
      opacity: 1;
    }
  }
`

const EditAvatar: React.FC<React.PropsWithChildren<{ src: string; alt?: string; loading: boolean; onSuccess: () => void }>> = ({
  src,
  alt,
  loading,
  onSuccess,
}) => {

  return (
    <StyledEditProfileAvatar>
      {loading ? (
        <>
          <Avatar data={images.loadinggif} width={150} height={150} border={4} />
        </>
      ) : (
        <>
          <Avatar data={src} width={150} height={150} border={4} />
          <EditOverlay />
        </>
      )}
    </StyledEditProfileAvatar>
  )
}

export default EditAvatar

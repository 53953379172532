import styled from 'styled-components'
import { Heading } from '@pancakeswap/uikit'
import images from 'configs/images'

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  * {
    font-family: Inter, sans-serif;
  }
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
  border-radius: 8px;
`

const Title = styled(Heading)`
  color: var(--mode-alpha-900, #1a1a1a);

  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 158.333% */
`
const TrendItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .head {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--mode-alpha-900, #1a1a1a);
    }
    .hashtag {
      font-size: 24px;
      font-weight: 700;
      line-height: 38px;
      color: var(--mode-alpha-900, #1a1a1a);
    }
    .number {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--mode-alpha-900, #1a1a1a);
    }
  }
  .dots:hover {
    cursor: pointer;
  }
`

const Trend = () => {
  return (
    <Wrapper>
      <Title>Trend for you</Title>
      <TrendItem>
        <div className="content">
          <div className="head">Trending in Blockchain </div>
          <div className="hashtag">#BULL</div>
          <div className="number">18.9k Post</div>
        </div>
        <div className="dots">
          <img src={images.dot} />
        </div>
      </TrendItem>
      <TrendItem>
        <div className="content">
          <div className="head">Trending in Blockchain </div>
          <div className="hashtag">#bitcoin</div>
          <div className="number">18.9k Post</div>
        </div>
        <div className="dots">
          <img src={images.dot} />
        </div>
      </TrendItem>
      <TrendItem>
        <div className="content">
          <div className="head">Trending in Blockchain </div>
          <div className="hashtag">#bitcoin</div>
          <div className="number">18.9k Post</div>
        </div>
        <div className="dots">
          <img src={images.dot} />
        </div>
      </TrendItem>
      <TrendItem>
        <div className="content">
          <div className="head">Trending in Blockchain </div>
          <div className="hashtag">#bitcoin</div>
          <div className="number">18.9k Post</div>
        </div>
        <div className="dots">
          <img src={images.dot} />
        </div>
      </TrendItem>
      <TrendItem>
        <div className="content">
          <div className="head">Trending in Blockchain </div>
          <div className="hashtag">#bitcoin</div>
          <div className="number">18.9k Post</div>
        </div>
        <div className="dots">
          <img src={images.dot} />
        </div>
      </TrendItem>
      <TrendItem>
        <div className="content">
          <div className="head">Trending in Blockchain </div>
          <div className="hashtag">#bitcoin</div>
          <div className="number">18.9k Post</div>
        </div>
        <div className="dots">
          <img src={images.dot} />
        </div>
      </TrendItem>
    </Wrapper>
  )
}

export default Trend

import images from 'configs/images'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-height: 666px;
  width: 100%;
  height: 100%;
  @media (max-width: 680px) {
    max-height: 560px;
  }
  @media (max-width: 575px) {
    max-height: 450px;
  }
`
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  .img-container {
    width: 100%;
    max-height: 666px;
    height: 100%;
    @media (max-width: 680px) {
      max-height: 560px;
    }
    @media (max-width: 575px) {
      max-height: 450px;
    }
  }
  .img-container-auto {
    max-width 100%;
    width: auto;
    max-height: 666px;
    height: 100%;
    @media (max-width: 680px) {
      max-height: 560px;
    }
    @media (max-width: 575px) {
      max-height: 450px;
    }
  }
`
const GridTwoW = styled.div`
  max-height: 560px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  align-content: center;
  gap: 2px;
`
const GridTwoH = styled.div`
  max-height: 560px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  gap: 2px;
`
const GridBase = styled.div`
  max-height: 560px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  gap: 2px;
`
const GridThreeW = styled(GridBase)`
  .grid-threeW-item-0 {
    grid-area: 1 / 1 / 3 / 2;
  }
  .grid-threeW-item-1 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .grid-threeW-item-2 {
    grid-area: 2 / 2 / 3 / 3;
  }
`
const GridThree = styled(GridBase)`
  .grid-three-item-0 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .grid-three-item-1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .grid-three-item-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
`

const GridFour = styled(GridBase)`
  .grid-four-item-0 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .grid-four-item-1 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .grid-four-item-2 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-four-item-3 {
    grid-area: 2 / 1 / 3 / 2;
  }
`
const GridFive = styled.div`
  max-height: 560px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);
  gap: 2px;
  .grid-five-item-0 {
    grid-area: 1 / 1 / 2 / 4;
  }
  .grid-five-item-1 {
    grid-area: 1 / 4 / 2 / 7;
  }
  .grid-five-item-2 {
    grid-area: 2 / 1 / 3 / 3;
  }
  .grid-five-item-3 {
    grid-area: 2 / 3 / 3 / 5;
  }
  .grid-five-item-4 {
    grid-area: 2 / 5 / 3 / 7;
  }
`

const StyledImg = styled.img`
  width: 100%;
  max-height: 560px;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`
const StyledBlur = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  filter: grayscale(70%);
  opacity: 0.7;
`
const StyledText = styled.div`
  position: absolute;
  bottom: 40%;
  left: 40%;
  font-size: 42px;
  line-height: 56px;
  font-weight: 800;
  color: #fff;
`
const ImgItem = styled.div`
  position: relative;
`
const ImgItemImg = styled.div`
  position: relative;
  width: 100%;
  max-height: 560px;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`
const ImgItemThree = styled.div`
  position: relative;
  width: 100%;
  max-height: 560px;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`
const ImgItemFour = styled.div`
  position: relative;
  width: 100%;
  max-height: 560px;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`
const Cancel = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  background-color: #e3e3e3;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`

export const StyledImages = ({ data, setData, cancel = false }) => {
  const [imageSizes, setImageSizes] = React.useState([])

  const handledDeleteImg = (_img) => {
    const index = data.indexOf(_img)

    if (index !== -1) {
      const newData = [...data.slice(0, index), ...data.slice(index + 1)]
      setData(newData)
    }
  }

  const getImageSize = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = url
      img.onload = () => {
        resolve({ width: img.width, height: img.height })
      }
      img.onerror = (error) => {
        reject(error)
      }
    })
  }

  React.useEffect(() => {
    const fetchImageSizes = async () => {
      const sizes = await Promise.all(data.map((img) => getImageSize(img)))
      setImageSizes(sizes)
    }
    fetchImageSizes()
  }, [data, imageSizes])

  return (
    <>
      {data.length > 0 && imageSizes.length > 0 && (
        <>
          {data.length < 2 && (
            <Wrapper>
              <Container>
                {data.map((img, ix) => (
                  <React.Fragment key={ix}>
                    {imageSizes[ix]?.width - imageSizes[ix]?.height > 0 ? (
                      <ImgItem>
                        {cancel && (
                          <Cancel onClick={() => handledDeleteImg(img)}>
                            <img src={images.closeline} alt="img" />
                          </Cancel>
                        )}
                        <img className="img-container" key={ix} src={img} alt="images" />
                      </ImgItem>
                    ) : (
                      <ImgItem>
                        {cancel && (
                          <Cancel onClick={() => handledDeleteImg(img)}>
                            <img src={images.closeline} alt="img" />
                          </Cancel>
                        )}
                        <img className="img-container-auto" key={ix} src={img} alt="images" />
                      </ImgItem>
                    )}
                  </React.Fragment>
                ))}
              </Container>
            </Wrapper>
          )}
          {data.length > 1 && data.length < 3 && (
            <Wrapper>
              {imageSizes[0]?.width - imageSizes[0]?.height > 0 && imageSizes[1]?.width - imageSizes[1]?.height > 0 ? (
                <GridTwoW>
                  {data.map((img, ix) => (
                    <ImgItem key={ix}>
                      {cancel && (
                        <Cancel onClick={() => handledDeleteImg(img)}>
                          <img src={images.closeline} alt="img" />
                        </Cancel>
                      )}
                      <StyledImg src={img} alt="images" />
                    </ImgItem>
                  ))}
                </GridTwoW>
              ) : (
                <GridTwoH>
                  {data.map((img, ix) => (
                    <ImgItem key={ix}>
                      {cancel && (
                        <Cancel onClick={() => handledDeleteImg(img)}>
                          <img src={images.closeline} alt="img" />
                        </Cancel>
                      )}
                      <StyledImg src={img} alt="images" />
                    </ImgItem>
                  ))}
                </GridTwoH>
              )}
            </Wrapper>
          )}
          {data.length === 3 && (
            <Wrapper style={{ maxHeight: '560px' }}>
              {imageSizes[0]?.width - imageSizes[0]?.height < 0 ? (
                <GridThreeW>
                  {data.map((img, ix) => (
                    <ImgItemThree key={ix} className={`grid-threeW-item-${ix}`}>
                      {cancel && (
                        <Cancel onClick={() => handledDeleteImg(img)}>
                          <img src={images.closeline} alt="img" />
                        </Cancel>
                      )}
                      <StyledImg src={img} alt="images" />
                    </ImgItemThree>
                  ))}
                </GridThreeW>
              ) : (
                <GridThree>
                  {data.map((img, ix) => (
                    <ImgItemThree key={ix} className={`grid-three-item-${ix}`}>
                      {cancel && (
                        <Cancel onClick={() => handledDeleteImg(img)}>
                          <img src={images.closeline} alt="img" />
                        </Cancel>
                      )}
                      <StyledImg src={img} alt="images" />
                    </ImgItemThree>
                  ))}
                </GridThree>
              )}
            </Wrapper>
          )}
          {data.length === 4 && (
            <Wrapper>
              <GridFour>
                {data.map((img, ix) => (
                  <ImgItemFour key={ix} className={`grid-four-item-${ix}`}>
                    {cancel && (
                      <Cancel onClick={() => handledDeleteImg(img)}>
                        <img src={images.closeline} alt="img" />
                      </Cancel>
                    )}
                    <StyledImg src={img} alt="images" />
                  </ImgItemFour>
                ))}
              </GridFour>
            </Wrapper>
          )}
          {data.length >= 5 && (
            <Wrapper>
              <GridFive>
                <ImgItemImg className="grid-five-item-0">
                  {cancel && (
                    <Cancel onClick={() => handledDeleteImg(data[0])}>
                      <img src={images.closeline} alt="img" />
                    </Cancel>
                  )}
                  <StyledImg src={data[0]} alt="images" />
                </ImgItemImg>
                <ImgItemImg className="grid-five-item-1">
                  {cancel && (
                    <Cancel onClick={() => handledDeleteImg(data[1])}>
                      <img src={images.closeline} alt="img" />
                    </Cancel>
                  )}
                  <StyledImg src={data[1]} alt="images" />
                </ImgItemImg>
                <ImgItemImg className="grid-five-item-2">
                  {cancel && (
                    <Cancel onClick={() => handledDeleteImg(data[2])}>
                      <img src={images.closeline} alt="img" />
                    </Cancel>
                  )}
                  <StyledImg src={data[2]} alt="images" />
                </ImgItemImg>
                <ImgItemImg className="grid-five-item-3">
                  {cancel && (
                    <Cancel onClick={() => handledDeleteImg(data[3])}>
                      <img src={images.closeline} alt="img" />
                    </Cancel>
                  )}
                  <StyledImg className="grid-five-item-3" src={data[3]} alt="images" />
                </ImgItemImg>
                {data.length > 5 ? (
                  <StyledBlur className="grid-five-item-4">
                    <StyledText>+ {data.length - 5}</StyledText>
                    <StyledImg src={data[4]} alt="images" />
                  </StyledBlur>
                ) : (
                  <ImgItemImg className="grid-five-item-4">
                    {cancel && (
                      <Cancel onClick={() => handledDeleteImg(data[4])}>
                        <img src={images.closeline} alt="img" />
                      </Cancel>
                    )}
                    <StyledImg className="grid-five-item-4" src={data[4]} alt="images" />
                  </ImgItemImg>
                )}
              </GridFive>
            </Wrapper>
          )}
        </>
      )}
    </>
  )
}

import { useTranslation } from '@pancakeswap/localization'
import { Box, Modal, useToast, Button, Input, Text } from '@pancakeswap/uikit'
import { useWeb3LibraryContext, useWeb3React } from '@pancakeswap/wagmi'
import CountUp from 'react-countup'
import useTheme from 'hooks/useTheme'
import { useState } from 'react'
import styled from 'styled-components'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { usePoolsContract, usePoolsV2Contract, usePoolsV3Contract, useTrendContract } from 'hooks/useContract'
import { ToastDescriptionWithTx } from 'components/Toast'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useBalance } from 'wagmi'
import { formatBigNumber } from 'utils/formatBalance'
import { formatEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'

export const trendyColors = {
  MAIN_GREEN: '#48E3E3',
  LIGHT_GREEN: '#71F7D4',
  ORANGE: '#FEA880',
  PURPLE: '#A8B2FF',
  DARK_PURPLE: '#7645d9',
  GRAY: '#D9D9D9',
  DARK_GRAY: '#393939',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  MAIN_GREEN_DISABLE: '#48E3E3',
  LIGHT_GREEN_DISABLE: '#A4E3E3',
  ORANGE_DISABLE: '#FCE0D3',
  PURPLE_DISABLE: '#BFC4E8',
  GRAY_DISABLE: '#F4F4F4',
  DARK_GRAY_DISABLE: '#393939',
  WHITE_DISABLE: '#ECECEC',
  PURPLE_NEW: 'var(--primary-primary-1, #8544F5)',
  COLORTABLE: '#777E90',
}
export interface Mine {
  totalMined: number
  claimed: number
  remain: number
  mineSpeed: number
  mineSpeedLevel: number
  startTime: number
  userClaimedMineLength: number
  unit?: string
  currentReward: number
  balanceTrend: number
}
// STYLE
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
`
const ClaimAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const StyledButton = styled(Button)`
  width: 100%;
  height: 42px;
  border-radius: 12px;
  background: #ff0420;
  backdrop-filter: blur(5px);
`
const depositModal = {}
const depositInput = {
  borderRadius: '10px',
}
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const Error = styled.span`
  margin: -0.5em 0 1em;
  color: ${trendyColors.ORANGE};
  font-size: 18px;
`
const ButtonMax = styled(Button)`
  position: absolute;
  top: 5px;
  right: 10px;
  height: 30px;
  color: ff0420;
  padding: 10px;
  border-radius: 24px;
  background: #fff;
`
const InputAmount = styled(Input)`
  width: 100%;
  border-radius: 36px;
  border: 1px solid #d2d2d2;
  background: #d2d2d2;
  backdrop-filter: blur(5px);
  height: 20px;
  padding: 20px 16px;
  color: #000;
`

const SendTrendModal = ({
  onDismiss,
  mine,
}: {
  // onSuccess: (dataModal) => void
  onDismiss: () => void
  mine: Mine
}) => {
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [confirmedTxHash, setConfirmedTxHash] = useState('')
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const [isValidAmount, setIsValidAmount] = useState(true)
  const mineContract = usePoolsV3Contract()
  const trendContranct = useTrendContract()
  const { chainId } = useActiveWeb3React()
  const date = Math.floor(new Date().getTime() / 1000)
  const [address, setAddress] = useState('')
  const [amount, setAmount] = useState('')
  const [valueAmount, setValueAmount] = useState(0)
  const [checkError, setCheckError] = useState(false)
  const { account } = useActiveWeb3React()
  const [inValid, setInvalid] = useState(false)
  const balance = Number(mine.balanceTrend)
  const amountMax = Number(mine.balanceTrend)
  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      console.log(amount, address)

      return callWithMarketGasPrice(trendContranct, 'transfer', [address], [amount])
    },

    onSuccess: async ({ receipt }) => {
      setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Send TREND successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      onDismiss()
    },
  })
  const handleCheck = () => {
    if (address === '') {
      setCheckError(true)
    } else {
      handleConfirm()
    }
  }
  const onChange = (e) => {
    setAddress(e)
    setCheckError(false)
  }
  // const changeAmount = (e) => {
  //   setAmount(Number(new BigNumber(e).times(getFullDecimalMultiplier(18)).toString()))
  //   setValueAmount(e)
  // }
  const setAmountMax = () => {
    setAmount(new BigNumber(balance).times(getFullDecimalMultiplier(18)).toString())
    setValueAmount(amountMax)
  }
  const min = 0
  const max = Number(balance)
  const handleInputChange = (e) => {
    if (!e) {
      setAmount('')
      setValueAmount(0)
      setInvalid(true)
      return
    }
    if (!Number.isNaN(+e)) {
      const val = String(Math.max(min, Math.min(max, Number(e))))
      setAmount(new BigNumber(val).times(getFullDecimalMultiplier(18)).toString())
      // console.log(new BigNumber(e).times(getFullDecimalMultiplier(18)).toString());
      setValueAmount(Number(val))
      setInvalid(false)
    }
  }
  return (
    <Modal
      style={{ maxWidth: '440px', boxShadow: '0px 4px 16px 0px rgba(17, 10, 65, 0.36)', backdropFilter: 'blur(50px)' }}
      title={''}
      onDismiss={onDismiss}
      hideCloseButton={false}
      borderRadius={25}
      headerBackground="#fff"
      background="#fff"
      bodyPadding="0 10px 30px"
    >
      <Wrapper>
        <Text fontSize="32px" fontWeight="700">
          SEND TREND
        </Text>
        <ClaimAmount>
          <Text fontSize="18px">To</Text>
          <InputAmount
            style={{ fontSize: '14px', borderRadius: '12px' }}
            value={address}
            onChange={(e) => onChange(e.target.value)}
          />
        </ClaimAmount>
        {checkError === true ? <Error>You do not enter an address !!!</Error> : null}
        <ClaimAmount>
          <Text fontSize="18px">Amount</Text>
          <div style={{ position: 'relative', width: '100%' }}>
            <InputAmount
              style={{ borderRadius: '12px' }}
              value={valueAmount}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <ButtonMax onClick={setAmountMax}>Max</ButtonMax>
          </div>
        </ClaimAmount>
        {inValid === true ? <Error>Please enter the value !!!</Error> : null}
        {/* {amount > balance ? <Error>You don &#39;t have enough Trend to send !!</Error> : null} */}
        <StyledButton
          variant={'danger'}
          width="180px"
          disabled={isConfirming || (!isValidAmount ? true : false) || inValid === true}
          onClick={handleCheck}
        >
          {isConfirming ? (
            <ThreeDots className="loading">
              Sending<span>.</span>
              <span>.</span>
              <span>.</span>
            </ThreeDots>
          ) : (
            'Send'
          )}
        </StyledButton>
      </Wrapper>
    </Modal>
  )
}

export default SendTrendModal

import IsIcon from 'components/IsIcon'
import images from 'configs/images'
import moment from 'moment'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 18px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media (max-width: 575px) {
    border-radius: 0;
  }
`
const Heading = styled.p`
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
`
const Styleds = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #3a3a3a;
  }
`
const Bio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px 16px 24px;
  p {
    font-size: 15px;
    line-height: 24px;
    color: #000;
  }
`
const StyledSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

interface IntroduceProps {
  userInfo: any
}

const Introduce: React.FC<React.PropsWithChildren<IntroduceProps>> = ({ userInfo }) => {
  return (
    <Wrapper>
      <Heading>Introduce</Heading>
      {userInfo ? (
        <div>
          {userInfo.bio && (
            <Bio>
              <p>{userInfo.bio}</p>
            </Bio>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {userInfo.following >= 0 && (
              <Styleds>
                <IsIcon w={24} h={18} src={images.following} />
                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                  <p>{userInfo.following}</p>
                  <p>Following</p>
                </div>
              </Styleds>
            )}
            {userInfo.followers >= 0 && (
              <Styleds>
                <IsIcon w={24} h={18} src={images.followers} />
                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                  <p>{userInfo.followers}</p>
                  <p>Followers</p>
                </div>
              </Styleds>
            )}
            {userInfo.birthday && (
              <Styleds>
                <IsIcon w={18} h={18} src={images.birthday} />
                <p>{userInfo.birthday.split('T')[0]}</p>
              </Styleds>
            )}
            {userInfo.phoneNumber && (
              <Styleds>
                <IsIcon w={18} h={18} src={images.phoneicon} />
                <p>{userInfo.phoneNumber}</p>
              </Styleds>
            )}
            {userInfo.email && (
              <Styleds>
                <IsIcon w={18} h={18} src={images.email} />
                <p>{userInfo.email}</p>
              </Styleds>
            )}
            {userInfo.createdAt && (
              <Styleds>
                <IsIcon w={18} h={18} src={images.calendar} />
                <p className="text">{`Joined ${moment(userInfo.createdAt).format('MMMM YYYY')}`}</p>
              </Styleds>
            )}
          </div>
        </div>
      ) : (
        <StyledSkeleton>
          <Skeleton height={45} />
          <Skeleton width={0} height={0} />
          <Skeleton height={18} />
          <Skeleton height={18} />
          <Skeleton height={18} />
          <Skeleton height={18} />
          <Skeleton height={18} />
        </StyledSkeleton>
      )}
    </Wrapper>
  )
}

export default Introduce

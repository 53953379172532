import { Button, ModalSendToken, useToast } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'astore/UserContext'
import useRegisterConfirmTransaction from 'hooks/useRegisterConfirmTransaction'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import tokenOPSFabi from 'config/abi/tokenOPSF.json'
import { useSigner } from 'wagmi'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import images from 'configs/images'
import { ToastDescriptionWithTx } from 'components/Toast'
import CircleLoader from 'components/Loader/CircleLoader'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
const StyledInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
`
const InputTitle = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #000;
`
const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 8px;
  border: 2px solid #ccc;
  border-radius: 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
  background-color: #fff;
  &:disabled {
    background-color: #ccc;
    cursor: pointer;
  }
`
const StyledBalance = styled.p`
  max-width: 320px;
  width: 100%;
  margin: 0;
  padding: 0 3px 3px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #ccc;
`
const BtnSend = styled.button`
  max-width: 50%;
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #fff;
  background-color: #dc2828;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    background-color: #ccc;
  }
`
const StyledInvalid = styled.div`
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #dc2828;
`

export const SendTokenModal = ({ address, setTransaction, onDismiss }) => {
  const account = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [upBalance, setUpBalance] = useState(false)
  const { userData } = useContext(UserContext)
  const [inputAccount, setInputAccount] = useState(account.account)
  const [inputAddress, setInputAddress] = useState(address[0])
  const [inputAmount, setInputAmount] = useState(1)
  const [userBalance, setUserBalance] = useState(userData.balance)
  const [invalid, setInvalid] = useState('')
  const { data: signer } = useSigner()
  const { toastSuccess, toastError, toastInfo, toastWarning } = useToast()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)

  const tokenOPSF = getContract({
    address: addresses.tokenOPSF[CHAIN_ID],
    abi: tokenOPSFabi,
    chainId: CHAIN_ID,
    signer,
  })
  const {
    isRegistting,
    isRegisterd,
    isRegisterConfirmed,
    isRegisterConfirming,
    handleRegister,
    handleRegisterConfirm,
  } = useRegisterConfirmTransaction({
    onRequiresRegister: async () => {
      return Promise.resolve(true)
    },
    onRegister: async () => {
      toastSuccess('loading')
      return tokenOPSF.transfer(inputAddress, inputAmount)
    },
    onRegisterSuccess: async ({ receipt }) => {
      toastSuccess('Success', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      setUpBalance(!upBalance)
      setLoading(false)
    },
    onConfirm: (receipt) => {
      return receipt
    },
    onSuccess: async ({ receipt }) => {
      setLoading(false)
      return Promise.resolve(1)
    },
  })

  useEffect(() => {
    getBalance()
  }, [upBalance])

  const getBalance = async () => {
    const balane = await tokenOPSF.balanceOf(account.account)
    setUserBalance(balane.toString())
  }
  const handleSend = () => {
    handleRegister()
    setLoading(true)
  }

  const isNumber = (e) => {
    const amount = e.replace(/[^0.1-9]/g, '')
    setInputAmount(amount)
    if (Number(amount) > userBalance) {
      setInvalid('Not enough amount')
    } else if (amount === '') {
      setInvalid('Invalid amount')
    } else {
      setInvalid('')
    }
  }

  return (
    <ModalSendToken title="Send Token" onDismiss={onDismiss}>
      <Wrapper>
        {loading && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <CircleLoader size="80px" />
          </div>
        )}
        <Container>
          <StyledInput>
            <InputTitle>From</InputTitle>
            <StyledBalance>Balance: {userBalance}</StyledBalance>
            <Input disabled value={inputAccount} onChange={(e) => setInputAccount(e.target.value)} />
          </StyledInput>
          <StyledInput>
            <InputTitle>To</InputTitle>
            <Input style={{ height: '42px' }} value={inputAddress} onChange={(e) => setInputAddress(e.target.value)} />
          </StyledInput>
          <StyledInput>
            <InputTitle style={{ fontSize: '15px' }}>Amout</InputTitle>
            <Input value={inputAmount} onChange={(e) => isNumber(e.target.value)} />
          </StyledInput>
          <StyledInvalid>{invalid}</StyledInvalid>
        </Container>
        <BtnSend disabled={invalid.length > 0} onClick={handleSend}>
          Send
        </BtnSend>
      </Wrapper>
    </ModalSendToken>
  )
}

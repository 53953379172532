import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import Container from './Container'
import { NextSeo, NextSeoProps } from 'next-seo'

export interface SEOHeadProps {
  meta: NextSeoProps
  optionTitle?: string
}

const StyledPage = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

export const PageMeta = ({ meta, optionTitle }: SEOHeadProps) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { pathname } = useRouter()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const cakePriceUsdDisplay = cakePriceUsd ? `$${cakePriceUsd.toFixed(3)}` : '...'

  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  // let pageTitle = cakePriceUsdDisplay ? [title, cakePriceUsdDisplay].join(' - ') : title
  // if (symbol) {
  //   pageTitle = [symbol, title].join(' - ')
  // }

  return (
    <>
      <NextSeo
        title={optionTitle || meta?.title}
        titleTemplate={meta?.titleTemplate}
        defaultTitle={meta?.defaultTitle}
        noindex={meta?.noindex}
        nofollow={meta?.nofollow}
        robotsProps={meta?.robotsProps}
        description={meta?.description}
        canonical={meta?.canonical}
        mobileAlternate={meta?.mobileAlternate}
        languageAlternates={meta?.languageAlternates}
        openGraph={meta?.openGraph}
        facebook={meta?.facebook}
        twitter={meta?.twitter}
        additionalMetaTags={meta?.additionalMetaTags}
        additionalLinkTags={meta?.additionalLinkTags}
      />
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
      </Head>
    </>
  )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  meta?: NextSeoProps
  optionTitle?: string
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ children, meta, optionTitle, ...props }) => {
  return (
    <>
      <PageMeta meta={meta} optionTitle={optionTitle} />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page

import React from 'react'
import moment from 'moment'

const getTimeDifference = (createdAt) => {
  const now = moment()
  const postTime = moment(createdAt)
  const diffMinutes = now.diff(postTime, 'minutes')
  const diffHours = now.diff(postTime, 'hours')
  const diffDays = now.diff(postTime, 'days')
  const diffWeeks = now.diff(postTime, 'weeks')
  const diffMonths = now.diff(postTime, 'months')
  const diffYears = now.diff(postTime, 'years')

  switch (true) {
    case diffMinutes < 1:
      return 'Just finished'
    case diffMinutes === 1:
      return '1 minute ago'
    case diffMinutes < 60:
      return `${diffMinutes} minutes ago`
    case diffHours === 1:
      return '1 hour ago'
    case diffHours < 24:
      return `${diffHours} hours ago`
    case diffDays === 1:
      return 'Yesterday'
    case diffDays < 7:
      return `${diffDays} days ago`
    case diffWeeks === 1:
      return '1 week ago'
    case diffWeeks <= 4:
      return `${diffWeeks} weeks ago`
    case diffMonths === 1:
      return '1 month ago'
    case diffMonths < 12:
      return `${diffMonths} months ago`
    case diffYears === 1:
      return '1 year ago'
    case diffYears > 1:
      return `${diffYears} years ago`
    default:
      return moment(createdAt).format('ddd D MMM HH:mm')
  }
}

const DayTimeDifference = ({ At }) => {
  const formattedTime = getTimeDifference(At)

  return <>{formattedTime}</>
}

export default DayTimeDifference

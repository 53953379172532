import styled from 'styled-components'
import { Heading, Button } from '@pancakeswap/uikit'
import 'aos/dist/aos.css'
import images from 'configs/images'
import { useRef } from 'react'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  align-self: stretch;
  /* @media (max-width: 575px) {
    margin-top: 40px;
  } */
  * {
    font-family: Inter, sans-serif;
  }
`
const Head = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`
const Title = styled(Heading)`
  color: var(--mode-alpha-900, #1a1a1a);

  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px;
`
const CardList = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 26px;
  align-self: stretch;

  overflow: auto;
  &::-webkit-scrollbar {
    width: 100%;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e4e4e7;
  }
`
const Card = styled.div`
  display: flex;
  min-width: 504px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  @media screen and (max-width: 575px) {
    min-width: 290px;
  }
`
const User = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .userImage {
    display: flex;
    width: 44px;
    height: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    border: 1px solid #e4e4e7;
    background: #fff;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .name {
      color: #1a1a1a;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      @media screen and (max-width: 575px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 70px;
        overflow: hidden;
      }
    }
    .expires {
      color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      @media screen and (max-width: 575px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 70px;
        overflow: hidden;
      }
    }
  }
`
const Reach = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  justify-content: flex-end;
  .item {
    display: flex;
    gap: 4px;
    color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));

    .num {
      color: #1a1a1a;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 820px) {
    flex-wrap: wrap;
  }
`
const TopCard = styled.div`
  display: flex;
  align-items: flex-start;
  /* gap: 24px; */
  justify-content: space-between;
  align-self: stretch;
`
const ContentCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .title {
        color: var(--mode-alpha-900, #1a1a1a);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    gap: 12px;
  }
`
const Term = styled.div`
  display: flex;
  height: 28px;
  min-height: 28px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
  background: #fcfcfc;
  color: #1a1a1a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const CTA = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const CardButton = styled(Button)`
  display: flex;
  height: 36px;
  min-height: 36px;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
  background: #f4f4f5;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
`
const RedButton = styled(CardButton)`
  color: white;
  border: 1px solid red;
  background: red;
`

const TopSignal = () => {
  const journalRef = useRef(null)
  return (
    <Wrap>
      <Head>
        <Title>Top Signal</Title>
        <a
          style={{
            color: '#DC2828',
            textAlign: 'right',
            // fontFamily: 'Inter',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          View more
        </a>
      </Head>
      <CardList>
        <Card>
          <TopCard>
            <User>
              <div className="userImage">
                <img src="https://gateway.pinata.cloud/ipfs/QmWJoi66DaWGr43VnxM3sXBLas6aPsCStdWxmXGjrUVFxJ" alt="" />
              </div>
              <div className="userInfo">
                <div className="name">Atlético</div>
                <div className="expires">Expires in 2 days</div>
              </div>
            </User>
            <Reach>
              <div className="item view">
                View
                <div className="num">27,5k</div>
              </div>
              <div className="item joined">
                Joined
                <div className="num"> 150</div>
              </div>
            </Reach>
          </TopCard>
          <ContentCard>
            <ul>
              <li>
                <span className="title">Status</span>
                <div className="live" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <circle cx="7.9999" cy="7.9999" r="5.6" fill="#1AC057" />
                    </svg>
                  </i>
                  <span
                    style={{
                      color: '#1AC057',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Live
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Rating</span>
                <div className="rating" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="white">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <span className="title">Duration</span>
                <div className="duration" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <Term>Short term</Term>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <span className="title">Strategy</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_164_489)">
                        <path
                          d="M15.3332 4L8.99984 10.3333L5.6665 7L0.666504 12"
                          stroke="#1AC057"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.3335 4H15.3335V8"
                          stroke="#1AC057"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_164_489">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <span
                    style={{
                      color: '#1AC057',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Long
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Trading Type</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M11.4551 17.74C8.27512 17.59 5.74512 14.97 5.74512 11.75C5.74512 8.44 8.43512 5.75 11.7451 5.75C14.9651 5.75 17.5851 8.28 17.7351 11.46L15.6351 10.83C15.2251 9.06 13.6351 7.75 11.7451 7.75C9.53512 7.75 7.74512 9.54 7.74512 11.75C7.74512 13.64 9.05512 15.23 10.8251 15.64L11.4551 17.74ZM21.7451 11.75C21.7451 12.05 21.7351 12.35 21.7051 12.65L19.7351 12.06C19.7451 11.96 19.7451 11.85 19.7451 11.75C19.7451 7.33 16.1651 3.75 11.7451 3.75C7.32512 3.75 3.74512 7.33 3.74512 11.75C3.74512 16.17 7.32512 19.75 11.7451 19.75C11.8451 19.75 11.9551 19.75 12.0551 19.74L12.6451 21.71C12.3451 21.74 12.0451 21.75 11.7451 21.75C6.22512 21.75 1.74512 17.27 1.74512 11.75C1.74512 6.23 6.22512 1.75 11.7451 1.75C17.2651 1.75 21.7451 6.23 21.7451 11.75ZM17.9751 16.01L21.7451 14.75L11.7451 11.75L14.7451 21.75L16.0051 17.98L20.2751 22.25L22.2551 20.27L17.9751 16.01Z"
                        fill="#17141F"
                        fillOpacity="0.45"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M13 8.33C13.85 8.03 14.53 7.35 14.83 6.5H18L15 13.5C15 15.16 16.57 16.5 18.5 16.5C20.43 16.5 22 15.16 22 13.5L19 6.5H21V4.5H14.83C14.42 3.33 13.31 2.5 12 2.5C10.69 2.5 9.58 3.33 9.17 4.5H3V6.5H5L2 13.5C2 15.16 3.57 16.5 5.5 16.5C7.43 16.5 9 15.16 9 13.5L6 6.5H9.17C9.47 7.35 10.15 8.03 11 8.33V19.5H2V21.5H22V19.5H13V8.33ZM20.37 13.5H16.63L18.5 9.14L20.37 13.5ZM7.37 13.5H3.63L5.5 9.14L7.37 13.5ZM12 6.5C11.45 6.5 11 6.05 11 5.5C11 4.95 11.45 4.5 12 4.5C12.55 4.5 13 4.95 13 5.5C13 6.05 12.55 6.5 12 6.5Z"
                        fill="#17141F"
                        fillOpacity="0.45"
                      />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <span className="title">Max profit</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    0000+ %
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Entry</span>
                <div>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    -
                  </span>
                </div>
              </li>
            </ul>
          </ContentCard>
          <CTA>
            <CardButton>View Channel</CardButton>
            <RedButton>View Signal</RedButton>
          </CTA>
        </Card>
        <Card>
          <TopCard>
            <User>
              <div className="userImage">
                <img src="/images/homepage/user1.jpg" alt="" />
              </div>
              <div className="userInfo">
                <div className="name">Savannah Nguyen</div>
                <div className="expires">Expires in 2 days</div>
              </div>
            </User>
            <Reach>
              <div className="item view">
                View
                <div className="num">27,5k</div>
              </div>
              <div className="item joined">
                Joined
                <div className="num"> 150</div>
              </div>
            </Reach>
          </TopCard>
          <ContentCard>
            <ul>
              <li>
                <span className="title">Status</span>
                <div className="live" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <circle cx="7.9999" cy="7.9999" r="5.6" fill="#1AC057" />
                    </svg>
                  </i>
                  <span
                    style={{
                      color: '#1AC057',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Live
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Rating</span>
                <div className="rating" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="white">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <span className="title">Duration</span>
                <div className="duration" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <Term>Short term</Term>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <span className="title">Strategy</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_164_489)">
                        <path
                          d="M15.3332 4L8.99984 10.3333L5.6665 7L0.666504 12"
                          stroke="#1AC057"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.3335 4H15.3335V8"
                          stroke="#1AC057"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_164_489">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <span
                    style={{
                      color: '#1AC057',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Long
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Trading Type</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M11.4551 17.74C8.27512 17.59 5.74512 14.97 5.74512 11.75C5.74512 8.44 8.43512 5.75 11.7451 5.75C14.9651 5.75 17.5851 8.28 17.7351 11.46L15.6351 10.83C15.2251 9.06 13.6351 7.75 11.7451 7.75C9.53512 7.75 7.74512 9.54 7.74512 11.75C7.74512 13.64 9.05512 15.23 10.8251 15.64L11.4551 17.74ZM21.7451 11.75C21.7451 12.05 21.7351 12.35 21.7051 12.65L19.7351 12.06C19.7451 11.96 19.7451 11.85 19.7451 11.75C19.7451 7.33 16.1651 3.75 11.7451 3.75C7.32512 3.75 3.74512 7.33 3.74512 11.75C3.74512 16.17 7.32512 19.75 11.7451 19.75C11.8451 19.75 11.9551 19.75 12.0551 19.74L12.6451 21.71C12.3451 21.74 12.0451 21.75 11.7451 21.75C6.22512 21.75 1.74512 17.27 1.74512 11.75C1.74512 6.23 6.22512 1.75 11.7451 1.75C17.2651 1.75 21.7451 6.23 21.7451 11.75ZM17.9751 16.01L21.7451 14.75L11.7451 11.75L14.7451 21.75L16.0051 17.98L20.2751 22.25L22.2551 20.27L17.9751 16.01Z"
                        fill="#17141F"
                        fillOpacity="0.45"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M13 8.33C13.85 8.03 14.53 7.35 14.83 6.5H18L15 13.5C15 15.16 16.57 16.5 18.5 16.5C20.43 16.5 22 15.16 22 13.5L19 6.5H21V4.5H14.83C14.42 3.33 13.31 2.5 12 2.5C10.69 2.5 9.58 3.33 9.17 4.5H3V6.5H5L2 13.5C2 15.16 3.57 16.5 5.5 16.5C7.43 16.5 9 15.16 9 13.5L6 6.5H9.17C9.47 7.35 10.15 8.03 11 8.33V19.5H2V21.5H22V19.5H13V8.33ZM20.37 13.5H16.63L18.5 9.14L20.37 13.5ZM7.37 13.5H3.63L5.5 9.14L7.37 13.5ZM12 6.5C11.45 6.5 11 6.05 11 5.5C11 4.95 11.45 4.5 12 4.5C12.55 4.5 13 4.95 13 5.5C13 6.05 12.55 6.5 12 6.5Z"
                        fill="#17141F"
                        fillOpacity="0.45"
                      />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <span className="title">Max profit</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    0000+ %
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Entry</span>
                <div>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    -
                  </span>
                </div>
              </li>
            </ul>
          </ContentCard>
          <CTA>
            <CardButton>View Channel</CardButton>
            <RedButton>View Signal</RedButton>
          </CTA>
        </Card>
        <Card>
          <TopCard>
            <User>
              <div className="userImage">
                <img src={images.user} alt="" />
              </div>
              <div className="userInfo">
                <div className="name">Brooklyn Simmons</div>
                <div className="expires">Expires in 2 days</div>
              </div>
            </User>
            <Reach>
              <div className="item view">
                View
                <div className="num">27,5k</div>
              </div>
              <div className="item joined">
                Joined
                <div className="num"> 150</div>
              </div>
            </Reach>
          </TopCard>
          <ContentCard>
            <ul>
              <li>
                <span className="title">Status</span>
                <div className="live" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <circle cx="7.9999" cy="7.9999" r="5.6" fill="#1AC057" />
                    </svg>
                  </i>
                  <span
                    style={{
                      color: '#1AC057',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Live
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Rating</span>
                <div className="rating" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="white">
                      <path
                        d="M11.8116 18.1129C11.3107 17.818 10.6893 17.818 10.1884 18.1129L6.62254 20.2117C5.43793 20.9089 3.98356 19.8864 4.23875 18.5358L5.08064 14.0798C5.18031 13.5522 5.00875 13.0098 4.62383 12.6356L1.3887 9.49005C0.42648 8.55447 0.974316 6.92128 2.30608 6.7552L6.53784 6.22747C7.0972 6.15771 7.57858 5.79877 7.80504 5.28257L9.5348 1.33976C10.0947 0.0635002 11.9053 0.063498 12.4652 1.33976L14.195 5.28257C14.4214 5.79877 14.9028 6.15771 15.4622 6.22747L19.6939 6.7552C21.0257 6.92128 21.5735 8.55447 20.6113 9.49005L17.3762 12.6356C16.9913 13.0098 16.8197 13.5522 16.9194 14.0798L17.7612 18.5358C18.0164 19.8865 16.5621 20.9089 15.3775 20.2117L11.8116 18.1129Z"
                        fill="#FB923C"
                      />
                      <path
                        d="M12.3188 17.2511C11.5049 16.772 10.4951 16.772 9.68116 17.2511L6.11529 19.3499C5.67106 19.6114 5.12567 19.2279 5.22137 18.7214L6.06325 14.2654C6.22522 13.4081 5.94644 12.5268 5.32094 11.9186L2.08581 8.77308C1.72498 8.42224 1.93042 7.8098 2.42983 7.74752L6.66159 7.21978C7.57054 7.10643 8.35279 6.52314 8.72079 5.68432L10.4506 1.74152C10.6605 1.26291 11.3395 1.26292 11.5494 1.74151L13.2792 5.68432C13.6472 6.52314 14.4295 7.10643 15.3384 7.21978L19.5702 7.74751C20.0696 7.8098 20.275 8.42224 19.9142 8.77308L16.6791 11.9186C16.0536 12.5268 15.7748 13.4081 15.9367 14.2654L16.7786 18.7214C16.8743 19.2279 16.3289 19.6114 15.8847 19.3499L12.3188 17.2511Z"
                        stroke="#1A1A1A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                      />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <span className="title">Duration</span>
                <div className="duration" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <Term>Short term</Term>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <span className="title">Strategy</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_164_489)">
                        <path
                          d="M15.3332 4L8.99984 10.3333L5.6665 7L0.666504 12"
                          stroke="#1AC057"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.3335 4H15.3335V8"
                          stroke="#1AC057"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_164_489">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <span
                    style={{
                      color: '#1AC057',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Long
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Trading Type</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <i style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M11.4551 17.74C8.27512 17.59 5.74512 14.97 5.74512 11.75C5.74512 8.44 8.43512 5.75 11.7451 5.75C14.9651 5.75 17.5851 8.28 17.7351 11.46L15.6351 10.83C15.2251 9.06 13.6351 7.75 11.7451 7.75C9.53512 7.75 7.74512 9.54 7.74512 11.75C7.74512 13.64 9.05512 15.23 10.8251 15.64L11.4551 17.74ZM21.7451 11.75C21.7451 12.05 21.7351 12.35 21.7051 12.65L19.7351 12.06C19.7451 11.96 19.7451 11.85 19.7451 11.75C19.7451 7.33 16.1651 3.75 11.7451 3.75C7.32512 3.75 3.74512 7.33 3.74512 11.75C3.74512 16.17 7.32512 19.75 11.7451 19.75C11.8451 19.75 11.9551 19.75 12.0551 19.74L12.6451 21.71C12.3451 21.74 12.0451 21.75 11.7451 21.75C6.22512 21.75 1.74512 17.27 1.74512 11.75C1.74512 6.23 6.22512 1.75 11.7451 1.75C17.2651 1.75 21.7451 6.23 21.7451 11.75ZM17.9751 16.01L21.7451 14.75L11.7451 11.75L14.7451 21.75L16.0051 17.98L20.2751 22.25L22.2551 20.27L17.9751 16.01Z"
                        fill="#17141F"
                        fillOpacity="0.45"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M13 8.33C13.85 8.03 14.53 7.35 14.83 6.5H18L15 13.5C15 15.16 16.57 16.5 18.5 16.5C20.43 16.5 22 15.16 22 13.5L19 6.5H21V4.5H14.83C14.42 3.33 13.31 2.5 12 2.5C10.69 2.5 9.58 3.33 9.17 4.5H3V6.5H5L2 13.5C2 15.16 3.57 16.5 5.5 16.5C7.43 16.5 9 15.16 9 13.5L6 6.5H9.17C9.47 7.35 10.15 8.03 11 8.33V19.5H2V21.5H22V19.5H13V8.33ZM20.37 13.5H16.63L18.5 9.14L20.37 13.5ZM7.37 13.5H3.63L5.5 9.14L7.37 13.5ZM12 6.5C11.45 6.5 11 6.05 11 5.5C11 4.95 11.45 4.5 12 4.5C12.55 4.5 13 4.95 13 5.5C13 6.05 12.55 6.5 12 6.5Z"
                        fill="#17141F"
                        fillOpacity="0.45"
                      />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <span className="title">Max profit</span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    0000+ %
                  </span>
                </div>
              </li>
              <li>
                <span className="title">Entry</span>
                <div>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    -
                  </span>
                </div>
              </li>
            </ul>
          </ContentCard>
          <CTA>
            <CardButton>View Channel</CardButton>
            <RedButton>View Signal</RedButton>
          </CTA>
        </Card>
      </CardList>
    </Wrap>
  )
}

export default TopSignal

import axiosClient from "./axiosClient";
import { ConversationParams, MessagesParams, MessagesStartParams, SearchFriend } from "models";

const userMessages = {
  get: (id: string, params: ConversationParams) => {
    const url = `/conversations/${id}/messages`
    return axiosClient.get(url, { params })
  },
  post: (params: MessagesParams) => {
    const url = `/v1/conversations/send`
    const data = params
    return axiosClient.post(url, data)
  },
  postStart: (params: MessagesStartParams) => {
    const url = `/conversations/start`
    const data = params
    return axiosClient.post(url, data)
  },
  postSeen: (id: string) => {
    const url = `/conversations/${id}/seen`
    return axiosClient.post(url)
  },
  search: (params: SearchFriend) => {
    const url = `/conversations`
    return axiosClient.get(url, {params})
  },
}

export default userMessages
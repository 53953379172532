import images from 'configs/images'
import {
  BtnDetail,
  ImgMine,
  ListPools,
  ListPoolsItem,
  ListRank,
  PoolRewards,
  PoolsItemBody,
  PoolsItemFooter,
  PoolsItemHeader,
  PoolsItemMinmax,
  RankItem,
  RankItemBody,
  RankItemBodyItem,
  RankItemHeader,
  StyledBodyItem,
  StyledBodyItems,
  StyledMinMax,
  StyledTotalLock,
  Title,
  TotalLock,
  Wrapper,
  BtnClose,
  BoxPrice,
  Text,
} from './styled'
import { useEffect, useState } from 'react'
import { formatEther } from '@ethersproject/units'
import contracts from 'config/constants/contracts'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import { Link } from 'react-router-dom'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ChainId } from '../../../packages/swap-sdk/src/constants'
import poolsAbi from 'config/abi/pools.json'
import CountUp from 'react-countup'

import { ThreeDots } from './components/PoolDetails/DepositModal'
import { CurrencyExchange } from 'components/Currency'
import { SEOPage } from 'components/SEOPage'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'

const percentPools = [
  { id: 0, name: 'Small Fish', percent: 200 },
  { id: 1, name: 'Fomo', percent: 220 },
  { id: 2, name: 'Miner', percent: 230 },
  { id: 3, name: 'Medium', percent: 240 },
  { id: 4, name: 'Shark', percent: 250 },
]

const Pool = () => {
  const { account, chainId } = useActiveWeb3React()
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const { t } = useTranslation()
  // account = '0x11205d2c06C2F392C1C20aCFa2b2f2de1882608e'
  // account = '0x15d075D54B0dB34eE43B98a32D62ddcF2c5a83a3'
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [closeBoxPrice, setCloseBoxPrice] = useState(false)
  const { data: signer } = useSigner()

  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'pools')

  const poolContract = getContract({
    address: contracts.pools[CHAIN_ID],
    abi: poolsAbi,
    chainId: CHAIN_ID,
    signer,
  })

  const [arr, setArr] = useState([
    {
      title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
      currentInterest: 0,
      maxLock: 0,
      minLock: 0,
      timeLock: 0,
      totalLock: 0,
      rateOP2USD: 0,
      yourLock: 0,
    },
    {
      title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
      currentInterest: 0,
      maxLock: 0,
      minLock: 0,
      timeLock: 0,
      totalLock: 0,
      rateOP2USD: 0,
      yourLock: 0,
    },
    {
      title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
      currentInterest: 0,
      maxLock: 0,
      minLock: 0,
      timeLock: 0,
      totalLock: 0,
      rateOP2USD: 0,
      yourLock: 0,
    },
    {
      title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
      currentInterest: 0,
      maxLock: 0,
      minLock: 0,
      timeLock: 0,
      totalLock: 0,
      rateOP2USD: 0,
      yourLock: 0,
    },
    {
      title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
      currentInterest: 0,
      maxLock: 0,
      minLock: 0,
      timeLock: 0,
      totalLock: 0,
      rateOP2USD: 0,
      yourLock: 0,
    },
  ])

  const [all, setAll] = useState({
    totalLock: 0,
    usdTotalLock: 0,
  })

  const link = window.location.href

  const review = () => {
    if (link.indexOf('review') !== -1) {
      window.location.href = '#review'
      window.history.replaceState(null, null, link)
      const a = link.slice(link.indexOf('='))
    }
  }

  const getPools = async (ids: number[]) => {
    try {
      const totalLock = await poolContract.totalLock()

      const usdTotalLock = await poolContract.usdTotalLock()
      setAll({
        totalLock: Number(formatEther(totalLock)) + 25933,
        usdTotalLock: Number(formatEther(usdTotalLock)) + 100000,
      })
      const OPPrice = await poolContract.OP2USDT()
      const pools = await poolContract.getPools(ids)
      if (!account) {
        const newPoolInfo = await Promise.all(
          pools.map((items) => {
            return {
              title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
              currentInterest: ((Number(items.currentInterest.toString()) / 10000) * 365).toFixed(2),
              maxLock: formatEther(items.maxLock),
              minLock: formatEther(items.minLock),
              timeLock: 1095,
              totalLock: Number(formatEther(items.totalLock)),
              rateOP2USD: Number(formatEther(OPPrice)),
              yourLock: 0,
            }
          }),
        )
        setArr(newPoolInfo)
        setIsLoadingPage(false)
      } else {
        const userLockAndPool = await poolContract.getPoolsUsers(account, ids)
        const newPoolInfo = await Promise.all(
          pools.map((item, id) => {
            return {
              title: ['Small Fish', 'Fomo', 'Miner', 'Medium', 'Shark'],
              currentInterest: ((Number(item.currentInterest.toString()) / 10000) * 365).toFixed(2),
              maxLock: formatEther(item.maxLock),
              minLock: formatEther(item.minLock),
              timeLock: 1095,
              totalLock: Number(formatEther(item.totalLock)),
              rateOP2USD: Number(formatEther(OPPrice)),
              yourLock: Number(formatEther(userLockAndPool[id].totalLockUSD)),
            }
          }),
        )
        setArr(newPoolInfo)
        setIsLoadingPage(false)
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  useEffect(() => {
    getPools([0, 1, 2, 3, 4])
  }, [account])

  useEffect(() => {
    review()
  }, [link])

  return (
    <>
      <PageMeta meta={findMeta} />
      <Wrapper>
        {/* <div style={{ display: closeBoxPrice ? 'none' : 'flex' }}> */}
        {/*  <BoxPrice> */}

        {/*    <BtnClose onClick={() => setCloseBoxPrice(!closeBoxPrice)}> */}
        {/*      <img src={images.close} alt="icon" /> */}
        {/*    </BtnClose> */}
        {/*  </BoxPrice> */}
        {/* </div> */}
        <TotalLock>
          <div>
            <Title>Pool</Title>
            <Text>
              Pooling resources or assets from multiple participants in a collective manner, often seen in decentralized
              finance ecosystem, to enhance liquidity, generate returns, or facilitate shared investments.
            </Text>
          </div>
          {/* <StyledTotalLock> */}
            <CurrencyExchange />
          {/*  <p>Total Lock</p> */}
          {/*  <h4> */}
          {/*    {all.usdTotalLock > 0 ? ( */}
          {/*      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
          {/*        <h4>$</h4> */}
          {/*        <CountUp */}
          {/*          separator="," */}
          {/*          start={0} */}
          {/*          preserveValue */}
          {/*          delay={0} */}
          {/*          end={all.usdTotalLock} */}
          {/*          decimals={3} */}
          {/*          duration={1} */}
          {/*          style={{ fontSize: isMobile ? '38px' : '48px', fontWeight: '700', color: '#202327' }} */}
          {/*        /> */}
          {/*      </div> */}
          {/*    ) : ( */}
          {/*      <ThreeDots className="loading" style={{ fontSize: '42px' }}> */}
          {/*        Loading<span>.</span> */}
          {/*        <span>.</span> */}
          {/*        <span>.</span> */}
          {/*      </ThreeDots> */}
          {/*    )} */}
          {/*  </h4> */}
          {/* </StyledTotalLock> */}
        </TotalLock>
        <PoolRewards>
          <ListPools>
            {arr.map((items, index) => (
              <ListPoolsItem key={index}>
                <PoolsItemHeader>
                  <p>{items.title[index]}</p>
                  <ImgMine src={images.iconmine} alt="icon" />
                </PoolsItemHeader>
                <PoolsItemMinmax>
                  <div style={{ maxWidth: '200px', width: '100%' }}>
                    <p>Min Stake</p>
                    <StyledMinMax>
                      <h4>
                        $
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number(items.minLock)}
                          decimals={0}
                          duration={1}
                        />
                      </h4>
                    </StyledMinMax>
                  </div>
                  <div style={{ maxWidth: '200px', width: '100%' }}>
                    <p>Max Stake</p>
                    <StyledMinMax>
                      <h4>
                        $
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number(items.maxLock)}
                          decimals={0}
                          duration={1}
                        />
                      </h4>
                    </StyledMinMax>
                  </div>
                </PoolsItemMinmax>
                <PoolsItemBody>
                  <StyledBodyItem>
                    <p>Interest</p>
                    <h4>
                      {items.currentInterest <= 0 ? (
                        <CountUp
                          style={{
                            color: '#202327',
                            fontSize: isMobile ? '16px' : '20px',
                            lineHeight: '28px',
                            fontWeight: '600',
                            textAlign: 'end',
                          }}
                          start={0}
                          preserveValue
                          delay={0}
                          end={7 + index}
                          decimals={2}
                          duration={0.5}
                        />
                      ) : (
                        <CountUp
                          style={{
                            color: '#202327',
                            fontSize: isMobile ? '16px' : '20px',
                            lineHeight: '28px',
                            fontWeight: '600',
                            textAlign: 'end',
                          }}
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number((30 / 365) * items.currentInterest)}
                          decimals={2}
                          duration={0.5}
                        />
                      )}{' '}
                      % / month
                    </h4>
                    {/* <h4>{Number((30 / 365) * items.currentInterest)}</h4> */}
                  </StyledBodyItem>
                  <StyledBodyItem>
                    <p>Max Percent</p>
                    <h4>
                      <CountUp
                        style={{ color: '#202327', fontSize: isMobile ? '16px' : '20px', fontWeight: '600' }}
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={percentPools[index].percent}
                        decimals={0}
                        duration={1}
                      />
                      %
                    </h4>
                    {/* <h4>{timeDisplayLong(items.timeLock) ? timeDisplayLong(items.timeLock * 57600) : '0'}</h4> */}
                  </StyledBodyItem>
                  <StyledBodyItem>
                    <p>Your Lock</p>
                    <StyledBodyItems>
                      <h4>
                        $
                        <CountUp
                          style={{ color: '#202327', fontSize: isMobile ? '16px' : '20px', fontWeight: '600' }}
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number(items.yourLock)}
                          decimals={0}
                          duration={1}
                        />
                      </h4>
                    </StyledBodyItems>
                  </StyledBodyItem>
                  {/* <StyledBodyItem>
                      <p>Total Lock</p>
                      <StyledBodyItems>
                        <h4>
                          $
                          {
                            <CountUp
                              style={{ color: '#202327', fontSize: isMobile ? '16px' : '20px', fontWeight: '600' }}
                              separator=","
                              start={0}
                              preserveValue
                              delay={0}
                              end={Number(items.totalLock)}
                              decimals={0}
                              duration={1}
                            />
                          }
                        </h4>
                        <span>
                          ~
                          {
                            <CountUp
                              separator=","
                              start={0}
                              preserveValue
                              delay={0}
                              end={Number(items.totalLock / items.rateOP2USD)}
                              decimals={0}
                              duration={1}
                            />
                          }
                        </span>
                        <span style={{ color: '#ff0420', fontWeight: '700', fontSize: isMobile ? '16px' : '20px' }}>
                          OP
                        </span>
                      </StyledBodyItems>
                    </StyledBodyItem> */}
                </PoolsItemBody>
                <PoolsItemFooter>
                  <Link to={`poolsDetails/${index}/chainId=${CHAIN_ID}`}>
                    <BtnDetail>Detail</BtnDetail>
                  </Link>
                </PoolsItemFooter>
              </ListPoolsItem>
            ))}
          </ListPools>
        </PoolRewards>
      </Wrapper>
    </>
  )
}
export default Pool

import styled from 'styled-components'

export const Wrapper = styled.div`
  * {
    font-family: Inter, sans-serif;
  }
  max-width: 1128px;
  margin: 0 auto;
  margin-top: 79px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 575px) {
    padding: 16px;
  }
`
export const Container = styled.div`
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const IntroWapper = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`
export const Title = styled.div`
  color: var(--mode-alpha-900, #1a1a1a);
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
export const IntrotText = styled.div`
  max-width: 468px;
  margin-top: 12px;
  color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
  text-align: start;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 20px;
  }
`
export const ContentText = styled.text`
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
export const TableMine = styled.div`
  max-width: 1240px;
  width: 100%;
  padding: 16px;
  backdrop-filter: blur(50px);
  height: 380px;
  margin-bottom: 150px;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#f1f4f9, #f1f4f9), linear-gradient(180deg, #fef0f0 27%, #fca6a6 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
`
export const TitleContainer = styled.p`
  color: #202327;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  @media (max-width: 575px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }
`
export const StyledYourWallet = styled.div`
  width: 100%;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#f1f4f9, #f1f4f9), linear-gradient(180deg, #fef0f0 27%, #fca6a6 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  gap: 30px;
  @media (max-width: 969px) {
    width: 100%;
    padding: 16px;
  }
`
export const YourWalletListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
`
export const YourWalletItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`
export const TitleItem = styled.p`
  width: 100%;
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const YourWalletTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`
export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  @media (max-width: 575px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const StyledSystem = styled.div`
  width: 50%;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#f1f4f9, #f1f4f9), linear-gradient(180deg, #fef0f0 27%, #fca6a6 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  gap: 30px;
  @media (max-width: 969px) {
    width: 100%;
    padding: 16px;
  }
`
export const MinnerItem = styled.div`
  width: 100%;
`
export const ItemMinner = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #d2d2d2;
  p {
    color: #202327;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  span {
    color: #202327;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`
export const StyledTilde = styled.div`
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
export const StyledBtn = styled.button`
  position: absolute;
  right: 12px;
  border: 1px solid #cbd5e0;
  padding: 4px 12px;
  border-radius: 8px;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  background: #fff;
  color: #ff0420;
  cursor: pointer;
  &:disabled {
    background: #ccc;
    color: #f1f1f1;
  }
`
export const StyledValue = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #d2d2d2;
  color: #202327;
`
export const StyledTextValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
export const YourMinedTitle = styled.p`
  width: 100%;
  padding: 24px 0;
  color: #1a1a1a;
  text-align: start;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @media (max-width: 575px) {
    font-size: 20px;
    line-height: 28px;
  }
`
export const StyledImgYourSpeeed = styled.div`
  // width: 300px;
  // max-height: 124px;
  // height: 100%;
  // border-radius: 8px;
  // background: #f1f4f9;
  // padding: 24px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // gap: 24px;
  // border: 2px solid transparent;
  // background-image: linear-gradient(#f1f4f9, #f1f4f9), linear-gradient(180deg, #fef0f0 27%, #fca6a6 100%);
  // background-origin: border-box;
  // background-clip: padding-box, border-box;
  // @media (max-width: 575px) {
  //   width: 100%;
  // }
`
export const StyledTextYourSpeed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  p {
    color: #202327;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  h4 {
    color: #202327;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`
export const StyledGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: 969px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

import React from 'react'
import styled from 'styled-components'
import Introduce from './components/Introduce'


const Wrapper = styled.div`
  max-width: 40%;
  width: 100%;
  @media (max-width: 850px) {
    max-width: 500px;
    margin: 0 auto;
  }
`

interface AuthorInfoProps {
  userInfo: any
}

const AuthorInfo: React.FC<React.PropsWithChildren<AuthorInfoProps>> = ({ userInfo }) => {
  return (
    <Wrapper>
      <Introduce userInfo={userInfo} />
    </Wrapper>
  )
}

export default AuthorInfo

import { BscScanIcon, Flex, IconButton, Button, useModal } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { getBlockExploreLink } from 'utils'
import { Achievement, Profile } from 'state/types'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useContext, useEffect, useMemo, useState } from 'react'
import EditProfileAvatar from './EditProfileAvatar'
import BannerHeader from '../../Nft/market/components/BannerHeader'
import EditProfileModal from './EditProfileModal'
import styled from 'styled-components'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import images from 'configs/images'
import { useHistory } from 'react-router-dom'
import usersApi from 'api/usersApi'
import Skeleton from 'react-loading-skeleton'
import Avatar from 'components/Avatar'
import IsIcon from 'components/IsIcon'
import friendApi from 'api/friendApi'
import { UserContext } from 'astore/UserContext'

const Container = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 750px) {
    height: 100%;
    flex-direction: column;
  }
`
const StyledName = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #1a1a1a;
`
const StyledBtn = styled(Button)`
  width: auto;
  height: 34px;
  padding: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: rgb(220, 40, 40);
  gap: 6px;
`
const ReplyAddFr = styled.div`
  min-height: 60px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: #f7f8fa;
  @media (max-width: 768px) {
    justify-content: center;
  }
  .styl-account {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    background-color: #ccc;
  }
  .styl-user {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
`
const StyledText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`
const BoxRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 24px 24px 24px 0;
  .styl-account {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    background-color: #ccc;
  }
  .styl-user {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
  @media (max-width: 750px) {
    width: 100%;
    padding: 24px;
  }
  @media (max-width: 575px) {
    width: 100%;
    justify-content: center;
    padding: 45px 10px 24px;
  }
`
const BoxLeft = styled.div`
  width: 50%;
  padding: 24px 0 24px 170px;
  @media (max-width: 750px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    width: 100%;
    padding: 65px 24px 0 32px;
  }
`
const Reply = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  gap: 12px;
`

interface HeaderProps {
  userInfo: any
  setUserInfo: any
  accountPath: string
  profile: Profile
  achievements: Achievement[]
  nftCollected: number
  isAchievementsLoading: boolean
  isLoading: boolean
  isProfileLoading: boolean
  onSuccess?: () => void
}

// Account and profile passed down as the profile could be used to render _other_ users' profiles.
const ProfileHeader: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  userInfo,
  setUserInfo,
  accountPath,
  profile,
  achievements,
  nftCollected,
  isAchievementsLoading,
  isLoading,
  isProfileLoading,
  onSuccess,
}) => {
  const { userData, setUser } = useContext(UserContext)
  const { t } = useTranslation()
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useWeb3React()
  const history = useHistory()
  const [checkFollow, setCheckFollow] = useState(null)
  const [isCheckFriend, setIsCheckFriend] = useState(null)
  const [userCt, setUserCt] = useState(false)

  const isConnectedAccount = account?.toLowerCase() === accountPath?.toLowerCase()
  const avatarImage = userInfo?.avatar || images.loadinggif
  // const avatarImage = userInfo?.avatar || '/images/homepage/avatar_none1.png'
  const profileTeamId = userInfo?.banner || '/images/profile/no-team-banner.png'
  const profileUsername = userInfo?.name
  const hasProfile = !profile
  const userDataId = userData?.userId || ''
  const userInfoId = userInfo?._id || ''

  useEffect(() => {
    if (userCt) {
      setUser(userInfo)
      setUserCt(false)
    }
  }, [userCt])

  const [onEditProfileModal] = useModal(
    <EditProfileModal
      onSuccess={() => {
        onSuccess?.()
      }}
      userInfo={userInfo}
      setUserInfo={setUserInfo}
      userCt={userCt}
      setUserCt={setUserCt}
    />,
    false,
  )

  const handledFolow = async () => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      const actions = checkFollow === true ? 'unfollow' : 'follow'
      const body = {
        followeeId: userInfoId,
        action: actions,
      }
      const response = await usersApi.postFollow(body)
      setCheckFollow(!checkFollow)
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const handledFeedback = async (_act) => {
    try {
      const payload = {
        action: _act,
      }
      const __id = userInfoId
      if (__id) {
        const response = await friendApi.friendfeedback(__id, payload)
        setIsCheckFriend(response.data.friendStatus)
      }
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const handledAddUn = async (_action) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      if (userDataId) {
        const action = _action
        const payload = {
          action: action,
        }
        const response = await friendApi.postFriend(userInfoId, payload)
        setIsCheckFriend(response.data.friendStatus)
      }
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const UnFriend = async () => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      if (userInfoId) {
        const _id = userInfoId
        const response = await friendApi.delete(_id)
        setIsCheckFriend(response.data.friendStatus)
      }
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const handledStartMessage = async () => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      if (userInfo && userInfo?._id) {
        history.push('/messages', {
          _id: userInfo?._id,
          _idFr: userInfo?._id,
          name: userInfo?.name,
          avatar: userInfo?.avatar,
          isGroup: false,
          newChat: true,
        })
      }
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  useEffect(() => {
    if (userInfo) {
      setCheckFollow(userInfo?.isFollowing)
      setIsCheckFriend(userInfo?.friendStatus)
    }
  }, [userInfo])

  const bannerImage = useMemo(() => {
    const imagePath = profileTeamId
    // switch (profileTeamId) {
    //   case 1:
    //     return `${imagePath}`
    //   case 2:
    //     return `${imagePath}`
    //   default:
    //     break
    // }
    return `${imagePath}`
  }, [profileTeamId])

  const avatar = useMemo(() => {
    const getIconButtons = () => {
      return (
        <Flex display="inline-flex">
          {accountPath && (
            <IconButton
              as="a"
              target="_blank"
              style={{
                width: 'fit-content',
              }}
              href={getBlockExploreLink(accountPath, 'address') || ''}
              // @ts-ignore
              alt={t('View BscScan for user address')}
            >
              <BscScanIcon width="20px" color="primary" />
            </IconButton>
          )}
        </Flex>
      )
    }

    const getImage = () => {
      return (
        <>
          {userInfo && userInfo?._id === userData?.userId ? (
            <EditProfileAvatar
              src={avatarImage}
              alt={t('User profile picture')}
              onSuccess={() => {
                onSuccess?.()
              }}
              loading={false}
            />
          ) : (
            <Avatar data={avatarImage} width={150} height={150} border={4} />
            // <Skeleton style={{ border: '4px', borderRadius: '50%' }} width={150} height={150} />
          )}
        </>
      )
    }
    return (
      <>
        {getImage()}
        {getIconButtons()}
      </>
    )
  }, [accountPath, avatarImage, isConnectedAccount, onSuccess, hasProfile, t])

  const ReplyAddFriend = () => {
    return (
      <>
        <ReplyAddFr>
          <StyledText>
            {userInfo.name && userInfo.name.length > '30'
              ? `${userInfo.name.substring(0, 5)}...${userInfo.name.substring(userInfo.name.length - 5)}`
              : userInfo.name}{' '}
            has sent you a friend request
          </StyledText>
          <Reply>
            <StyledBtn className="styl-user" onClick={() => handledFeedback('accept')}>
              <p>Accept the invitation</p>
            </StyledBtn>
            <StyledBtn className="styl-account" onClick={() => handledFeedback('reject')}>
              <p>Delete invitation</p>
            </StyledBtn>
          </Reply>
        </ReplyAddFr>
      </>
    )
  }

  return (
    <>
      <BannerHeader bannerImage={bannerImage} avatar={avatar} />
      <Container>
        <BoxLeft>
          {(userInfo && !isLoading) || (userData && !isLoading) ? (
            <StyledName>
              {userInfo && Number(userInfo?.name?.length) > 30 ? (
                `${userInfo.name.substring(0, 5)}...${userInfo.name.substring(userInfo.name.length - 5)}`
              ) : (
                <>{profileUsername}</>
              )}
            </StyledName>
          ) : (
            <Skeleton width={150} height={25} />
          )}
        </BoxLeft>
        <BoxRight>
          {(userInfo && !isLoading) || (userData && !isLoading) ? (
            <>
              {userInfo && userDataId && userInfo?._id === userDataId && (
                <StyledBtn className="styl-account" onClick={onEditProfileModal}>
                  <IsIcon w={18} h={18} src={images.edit} />
                  <p>Edit profile</p>
                </StyledBtn>
              )}
              {userInfo && userInfo?._id !== userDataId && (
                <>
                  {isCheckFriend === null && (
                    <StyledBtn
                      style={{ borderRadius: '24px' }}
                      className="styl-user"
                      onClick={() => handledAddUn('request')}
                    >
                      <IsIcon w={20} h={18} src={images.addfriend} alt={'addFriend'} />
                    </StyledBtn>
                  )}
                  {isCheckFriend === 'can_request' && (
                    <StyledBtn
                      style={{ borderRadius: '24px' }}
                      className="styl-user"
                      onClick={() => handledAddUn('request')}
                    >
                      <IsIcon w={20} h={18} src={images.addfriend} alt={'addFriend'} />
                    </StyledBtn>
                  )}
                  {isCheckFriend === 'are_friends' && (
                    <StyledBtn style={{ borderRadius: '24px' }} className="styl-user" onClick={UnFriend}>
                      <IsIcon w={20} h={18} src={images.acceptedfriend} alt={'acceptedFriend'} />
                    </StyledBtn>
                  )}
                  {isCheckFriend === 'outgoing_request' && (
                    <StyledBtn
                      style={{ borderRadius: '24px' }}
                      className="styl-user"
                      onClick={() => handledAddUn('cancel')}
                    >
                      <IsIcon w={20} h={18} src={images.unfriend} alt={'unFriend'} />
                    </StyledBtn>
                  )}
                  {isCheckFriend === 'incoming_request' && (
                    <StyledBtn style={{ borderRadius: '24px' }} className="styl-user">
                      <IsIcon w={20} h={18} src={images.feedback} alt={'feedback'} />
                    </StyledBtn>
                  )}
                  <StyledBtn style={{ borderRadius: '24px' }} className="styl-user" onClick={handledStartMessage}>
                    <IsIcon w={18} h={18} src={images.message_white} alt={'message'} />
                  </StyledBtn>
                  <StyledBtn onClick={handledFolow} className="styl-user">
                    <p>{checkFollow ? 'UnFollow' : 'Follow'}</p>
                  </StyledBtn>
                </>
              )}
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
              <Skeleton width={120} height={25} />
              <Skeleton width={120} height={25} />
            </div>
          )}
        </BoxRight>
      </Container>
      {isCheckFriend === 'incoming_request' && <ReplyAddFriend />}
    </>
  )
}

export default ProfileHeader

import React, { useEffect, useRef, useState } from 'react'
import Avatar from 'components/Avatar'
import images from 'configs/images'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import IsIcon from 'components/IsIcon'
import postsApi from 'api/postApi'
import Tippy from '@tippyjs/react/headless'
import {
  Action,
  ActionComments,
  BtnSend,
  Comment,
  CommentItem,
  Content,
  Input,
  InputComment,
  Item,
  ModalTippy,
  ModalTippyItem,
  More,
  StyledAction,
  StyledComment,
  StyledContent,
  StyledDayTime,
  ViewsReply,
} from './styled'
import DayTimeDifference from 'components/DayTimeDifference'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from '@pancakeswap/localization'
import { useToast } from '@pancakeswap/uikit'
import StyledAvatar from 'components/StyledAvatar'
import StyledUserName from 'components/StyledUserName'

const CommentsPost = ({
  data,
  userData,
  listComments,
  setListComments,
  listDataPost,
  setListDataPost,
  inputRef,
  pageOrder,
  setPageOrder,
  loading,
  setLoading,
  onDismiss,
}) => {
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [inputReply, setInputReply] = useState('')
  const [inputEdit, setInputEdit] = useState('')
  const [valueTags, setValueTags] = useState('')
  const [getInput, setGetInput] = useState(false)
  const [idInput, setIdInput] = useState()
  const [viewReplySize, setViewReplySize] = useState(3)
  const [viewEdit, setViewEdit] = useState('')
  const inputReplyRef = useRef(null)
  const inputEditRef = useRef(null)
  const pageSize = 3
  const pageIndex = pageOrder

  const postComment = async (_idreply, _content, isTags, parentIdx) => {
    if (!account) {
      onDismiss()
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    if (account) {
      try {
        const payload = {
          postId: data._id,
          content: _content,
          parentId: _idreply,
          tags: isTags,
        }
        const response = await postsApi.postcomment(payload)
        if (_idreply) {
          const parentInx = parentIdx
          const newChildren = { ...listComments[parentInx] }
          newChildren.replies.push(response.data)
          listComments.splice(parentInx, 1, newChildren)
          const newCmts = [...listComments]
          setListComments(newCmts)
          setGetInput(false)
        } else {
          listComments.push(response.data)
          const newCmts = [...listComments]
          setListComments(newCmts)
          setGetInput(false)
        }

        const updatedListData = listDataPost.map((post) => {
          if (post._id === response.data.postId) {
            post.totalComments++
          }
          return post
        })
        setListDataPost(updatedListData)
      } catch (error) {
        console.log('Failed to fetch list posts: ', error)
      }
    }
  }

  const likeComment = async (_idcomment) => {
    if (account) {
      try {
        const idComment = await _idcomment
        const response = await postsApi.likecomment(idComment)
        const updateLikes = (item) => {
          if (item._id === _idcomment) {
            const likedUsers = item.likedUsers
            if (likedUsers.includes(userData.userId)) {
              item.likedUsers = likedUsers.filter((e) => e !== userData.userId)
            } else {
              likedUsers.push(userData.userId)
            }
          }
        }
        const updatedListComment = listComments.map((comment) => {
          updateLikes(comment)
          if (response.data._id === _idcomment) {
            comment.replies.forEach(updateLikes)
          }
          return comment
        })
        setListComments(updatedListComment)
      } catch (error) {
        console.log('Failed to fetch list posts: ', error)
      }
    }
  }

  const deleteComment = async (_idcomment) => {
    if (account) {
      try {
        const response = await postsApi.deletecomment(_idcomment)

        setListComments((prevListComment) =>
          prevListComment.filter((comment) => {
            if (comment._id === _idcomment) {
              return false
            }

            if (comment.replies && comment.replies.length > 0) {
              comment.replies = comment.replies.filter((reply) => reply._id !== _idcomment)
            }

            return true
          }),
        )

        setListDataPost((prevListPost) =>
          prevListPost.map((post) =>
            post._id === data._id
              ? {
                  ...post,
                  totalComments: response.data.totalComments,
                }
              : post,
          ),
        )

        toastSuccess(t('Success!'), t('Delete successfully'))
      } catch (error) {
        console.log('Failed to delete comment: ', error)
      }
    }
  }

  const editComment = async (_idcomment, _isReply) => {
    if (_idcomment && inputEdit.length > 0) {
      try {
        const body = {
          content: inputEdit,
        }
        const response = await postsApi.editcomment(_idcomment, body)

        if (!_isReply) {
          const indexToUpdate = listComments.findIndex((comment) => comment._id === _idcomment)
          if (indexToUpdate !== -1) {
            const updatedListComment = [...listComments]
            updatedListComment[indexToUpdate] = {
              ...updatedListComment[indexToUpdate],
              content: response.data.content,
            }
            setListComments(updatedListComment)
          }
        } else {
          const updatedListComment = listComments.map((comment) => {
            if (comment._id === _idcomment) {
              return {
                ...comment,
                content: response.data.content,
              }
            }
            if (comment.replies && comment.replies.length > 0) {
              const replyIndex = comment.replies.findIndex((reply) => reply._id === _idcomment)
              if (replyIndex !== -1) {
                const updatedReplies = [...comment.replies]
                updatedReplies[replyIndex] = {
                  ...updatedReplies[replyIndex],
                  content: response.data.content,
                }
                return {
                  ...comment,
                  replies: updatedReplies,
                }
              }
            }
            return comment
          })
          setListComments(updatedListComment)
        }
        setViewEdit('')
      } catch (error) {
        console.log('Failed to fetch list posts: ', error)
      }
    }
  }

  const onClickReply = (_ix, _name) => {
    setGetInput(true)
    setIdInput(_ix)
    setValueTags(_name.length)
    setInputReply(
      _name.length > '30'
        ? `@${_name.substring(0, 5)}...${_name.substring(_name.length - 5) + ' '}`
        : `@${_name + ' '}`,
    )
    if (inputReplyRef.current) {
      inputReplyRef.current.focus()
    }
  }

  const handledReplyKey = (event, _idreply, useIdTags, parentIx) => {
    if (event.key === 'Enter') {
      const offset = 0
      const lenghtTags = valueTags

      const istags = [
        {
          id: useIdTags,
          offset: offset,
          length: lenghtTags,
        },
      ]
      postComment(_idreply, inputReply, istags, parentIx)
      setInputReply('')
    }
  }
  const handledReplyClick = (_idreply, useIdTags, parentIx) => {
    const offset = 0
    const lenghtTags = valueTags

    const istags = [
      {
        id: useIdTags,
        offset: offset,
        length: lenghtTags,
      },
    ]
    postComment(_idreply, inputReply, istags, parentIx)
    setInputReply('')
  }

  const handleEditKey = (event, _idCo, _isEdit) => {
    if (event.key === 'Enter') {
      editComment(_idCo, _isEdit)
    }
  }

  const handledClickEdit = (_idedit, _content) => {
    setInputEdit(_content)
    setViewEdit(_idedit)
  }

  useEffect(() => {
    if (inputReplyRef.current && getInput && inputReply !== null) {
      inputReplyRef.current.focus()
    }
  }, [idInput])

  useEffect(() => {
    if (inputEditRef.current && viewEdit !== '') {
      inputEditRef.current.focus()
    }
  }, [viewEdit])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setViewEdit('')
      }
    }
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <Comment>
      {listComments?.map((i, ix) => (
        <CommentItem key={i._id}>
          <StyledAvatar link={`/profile?id=${i.authorInfo._id}`} data={i.authorInfo.avatar} w={32} h={32} />
          <StyledContent>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
              {viewEdit === i._id ? (
                <StyledComment>
                  <InputComment>
                    <Input
                      ref={inputEditRef}
                      value={inputEdit}
                      onChange={(e) => setInputEdit(e.target.value)}
                      onKeyUp={(e) => handleEditKey(e, i._id, false)}
                      placeholder="Edit...."
                    />
                    {inputEdit.length > 0 && (
                      <BtnSend onClick={(e) => editComment(i._id, false)} src={images.send} alt="send" />
                    )}
                  </InputComment>
                </StyledComment>
              ) : (
                <Item>
                  <StyledUserName link={`/profile?id=${i.authorInfo._id}`} data={i.authorInfo.name} font={14} />
                  <Content>
                    <p>{i.content}</p>
                  </Content>
                </Item>
              )}
              {viewEdit !== i._id && (
                <More>
                  <Tippy
                    content="Search"
                    interactive
                    placement={'bottom-start'}
                    trigger="click"
                    render={(attrs) => (
                      <>
                        {i.authorInfo._id === userData.userId ? (
                          <ModalTippy>
                            <ModalTippyItem onClick={() => handledClickEdit(i._id, i.content)}>Edit</ModalTippyItem>
                            <ModalTippyItem onClick={() => deleteComment(i._id)}>Move to trash</ModalTippyItem>
                          </ModalTippy>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  >
                    <img
                      style={{ width: '16px', height: '16px', cursor: 'pointer', marginTop: '3px' }}
                      src={images.morefill}
                      alt="icon"
                    />
                  </Tippy>
                </More>
              )}
            </div>
            {viewEdit === i._id && (
              <StyledAction>
                <Action>
                  <span style={{ color: '#3b85fa' }} onClick={(e) => setViewEdit('')}>
                    cancel
                  </span>
                </Action>
              </StyledAction>
            )}
            {viewEdit !== i._id && (
              <StyledAction>
                <StyledDayTime>
                  <DayTimeDifference At={i.createdAt} />
                </StyledDayTime>
                <Action>
                  <span onClick={() => likeComment(i._id)}>Like</span>
                  <span onClick={() => onClickReply(i._id, i.authorInfo.name)}>Reply</span>
                </Action>
                {i.likedUsers.length > 0 && (
                  <ActionComments>
                    {i.likedUsers.includes(userData?.userId) ? (
                      <IsIcon w={16} h={16} src={images.iconL} curs={true} />
                    ) : (
                      <IsIcon w={16} h={16} src={images.iconlike} curs={true} />
                    )}
                    <span>{i.likedUsers.length}</span>
                  </ActionComments>
                )}
              </StyledAction>
            )}
            {getInput && idInput === i._id && (
              <StyledComment>
                <Avatar data={userData.avatar} width={32} height={32} />
                <InputComment>
                  <Input
                    ref={inputReplyRef}
                    value={inputReply}
                    onChange={(e) => setInputReply(e.target.value)}
                    onKeyUp={(e) => handledReplyKey(e, i._id, i.authorInfo._id, ix)}
                    placeholder="Share your though...."
                  />
                  {inputReply.length > 0 && (
                    <BtnSend
                      onClick={() => handledReplyClick(i._id, i.authorInfo._id, ix)}
                      src={images.send}
                      alt="send"
                    />
                  )}
                </InputComment>
              </StyledComment>
            )}
            {i.replies?.length > 0 && (
              <>
                {i.replies?.slice(0, viewReplySize).map((ii, ixx) => (
                  <div key={ii._id} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <CommentItem>
                      <StyledAvatar
                        link={`/profile?id=${ii.authorInfo._id}`}
                        data={ii.authorInfo.avatar}
                        w={32}
                        h={32}
                      />
                      <StyledContent>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                          {viewEdit === ii._id ? (
                            <StyledComment>
                              <InputComment>
                                <Input
                                  ref={inputEditRef}
                                  value={inputEdit}
                                  onChange={(e) => setInputEdit(e.target.value)}
                                  onKeyUp={(e) => handleEditKey(e, ii._id, true)}
                                  placeholder="Edit...."
                                />
                                {inputEdit.length > 0 && (
                                  <BtnSend onClick={(e) => editComment(ii._id, true)} src={images.send} alt="send" />
                                )}
                              </InputComment>
                            </StyledComment>
                          ) : (
                            <Item>
                              <StyledUserName
                                link={`/profile?id=${ii.authorInfo._id}`}
                                data={ii.authorInfo.name}
                                font={14}
                              />
                              <Content>
                                <p>{ii.content}</p>
                              </Content>
                            </Item>
                          )}
                          {viewEdit !== ii._id && (
                            <More>
                              <Tippy
                                content="Search"
                                interactive
                                placement={'bottom-start'}
                                trigger="click"
                                render={(attrs) => (
                                  <>
                                    {i.authorInfo._id === userData.userId ? (
                                      <ModalTippy>
                                        <ModalTippyItem onClick={() => handledClickEdit(ii._id, ii.content)}>
                                          Edit
                                        </ModalTippyItem>
                                        <ModalTippyItem onClick={() => deleteComment(ii._id)}>
                                          Move to trash
                                        </ModalTippyItem>
                                      </ModalTippy>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              >
                                <img
                                  style={{ width: '16px', height: '16px', cursor: 'pointer', marginTop: '3px' }}
                                  src={images.morefill}
                                  alt="icon"
                                />
                              </Tippy>
                            </More>
                          )}
                        </div>
                        {viewEdit === ii._id && (
                          <StyledAction>
                            <Action>
                              <span style={{ color: '#3b85fa' }} onClick={(e) => setViewEdit('')}>
                                cancel
                              </span>
                            </Action>
                          </StyledAction>
                        )}
                        {viewEdit !== ii._id && (
                          <StyledAction>
                            <StyledDayTime>
                              <DayTimeDifference At={ii.createdAt} />
                            </StyledDayTime>
                            <Action>
                              <span onClick={() => likeComment(ii._id)}>Like</span>
                              <span onClick={() => onClickReply(ii._id, ii.authorInfo.name)}>Reply</span>
                            </Action>
                            {ii.likedUsers.length > 0 && (
                              <ActionComments>
                                {ii.likedUsers.includes(userData?.userId) ? (
                                  <IsIcon w={16} h={16} src={images.iconL} curs={true} />
                                ) : (
                                  <IsIcon w={16} h={16} src={images.iconlike} curs={true} />
                                )}
                                <span>{ii.likedUsers.length}</span>
                              </ActionComments>
                            )}
                          </StyledAction>
                        )}
                      </StyledContent>
                    </CommentItem>
                    {getInput && idInput === ii._id && (
                      <StyledComment>
                        <Avatar data={userData.avatar} width={32} height={32} />
                        <InputComment>
                          <Input
                            ref={inputReplyRef}
                            value={inputReply}
                            onChange={(e) => setInputReply(e.target.value)}
                            onKeyUp={(e) => handledReplyKey(e, ii._id, ii.authorInfo._id, ix)}
                            placeholder="Share your though...."
                          />
                          {inputReply.length > 0 && (
                            <BtnSend
                              onClick={() => handledReplyClick(ii._id, ii.authorInfo._id, ix)}
                              src={images.send}
                              alt="send"
                            />
                          )}
                        </InputComment>
                      </StyledComment>
                    )}
                  </div>
                ))}
                {i.replies.length > viewReplySize && (
                  <ViewsReply onClick={() => setViewReplySize(viewReplySize + 3)}>
                    <IsIcon w={16} h={16} src={images.viewsmorereply} curs={true} />
                    <p>View more reply</p>
                  </ViewsReply>
                )}
              </>
            )}
          </StyledContent>
        </CommentItem>
      ))}
      {listComments.length >= pageIndex * pageSize && (
        <ViewsReply onClick={() => setPageOrder(pageOrder + 1)}>
          <IsIcon w={16} h={16} src={images.viewsmorereply} curs={true} />
          <p>View more comments</p>
        </ViewsReply>
      )}
      {loading && (
        <>
          <CommentItem>
            <Skeleton width={28} height={28} borderRadius="50%" />
            <StyledContent>
              <Skeleton width={200} height={42} borderRadius="18px" />
            </StyledContent>
          </CommentItem>
          <CommentItem>
            <Skeleton width={28} height={28} borderRadius="50%" />
            <StyledContent>
              <Skeleton width={240} height={42} borderRadius="18px" />
            </StyledContent>
          </CommentItem>
          <CommentItem>
            <Skeleton width={28} height={28} borderRadius="50%" />
            <StyledContent>
              <Skeleton width={250} height={70} borderRadius="18px" />
            </StyledContent>
          </CommentItem>
          <CommentItem>
            <Skeleton width={28} height={28} borderRadius="50%" />
            <StyledContent>
              <Skeleton width={300} height={60} borderRadius="18px" />
            </StyledContent>
          </CommentItem>
        </>
      )}
    </Comment>
  )
}
export default CommentsPost

import Avatar from 'components/Avatar'
import {
  BoddyChats,
  BoxMessage,
  BtnIcon,
  BtnsendAddress,
  CallMessage,
  Chats,
  ContainerChat,
  FooterChats,
  HeaderChats,
  IconGroup,
  ImageMessage,
  ImgMore,
  InputSend,
  Item,
  ModalTippy,
  ModalTippyItem,
  ReceivedMessage,
  SentMessage,
  StyledAddress,
  StyledBack,
  StyledChat,
  StyledChooseFile,
  StyledClick,
  StyledInputSend,
  StyledItemChat,
  StyledMore,
  StyledNameChats,
  StyledText,
  StyledTextMess,
  TextMessage,
  ViewMore,
} from '../styled'
import UserName from 'components/UserName'
import images from 'configs/images'
import { useEffect, useRef, useState } from 'react'
import userMessages from 'api/userMessages'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import Skeleton from 'react-loading-skeleton'
import IsIcon from 'components/IsIcon'
import useCall from 'hooks/useCall'
import Tippy from '@tippyjs/react/headless'
import EthereumAddress from 'ethereum-address'
import { useModal } from '@pancakeswap/uikit'
import { SendTokenModal } from './ModalSendToken'
import axios from 'axios'
import CircleLoader from 'components/Loader/CircleLoader'

const Conversation = ({ userData, idFriend, inFoFr, db, setActive }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { handleSend } = useCall()
  const [openModalValues, setOpenModalValues] = useState('')
  const [listMessages, setListMessages] = useState([])
  const [contentMsg, setContentMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [imgHash, setImgHash] = useState([])
  const [conversationId, setConversationId] = useState('')
  const [transaction, setTransaction] = useState('')
  const [beforeId, setBeforeId] = useState('')
  const [afterId, setAfterId] = useState('')
  const [pageIndex, setPageIndex] = useState(1)
  const [checkViewMore, setCheckViewMore] = useState(true)
  const inputRef = useRef(null)

  const fetchMessageDetail = async (_idC) => {
    try {
      if (_idC) {
        const params = {
          beforeId: beforeId,
          afterId: afterId,
          pageSize: 20,
          pageIndex: pageIndex,
        }
        const response = await userMessages.get(_idC, params)
        if (response.data.length > 0) {
          setListMessages(listMessages.concat(response.data))
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setCheckViewMore(false)
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log('Failed to fetch list posts: ', error)
    }
  }

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, 'conversations'), where('id', '==', conversationId)),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data())
        fetchMessageDetail(conversationId)
      },
    )
    return () => unsubscribe()
  }, [conversationId])

  const fetchStartMessage = async () => {
    try {
      setIsLoading(true)
      if (!idFriend.newChat || idFriend.newChat === null) {
        const __id = idFriend._id
        const response = await userMessages.postSeen(__id)
        setConversationId(response.data.conversationId)
        fetchMessageDetail(response.data.conversationId)
        if (inputRef.current) {
          inputRef.current.focus()
        }
      } else {
        const params = {
          receiverId: idFriend._idFr,
        }
        const response = await userMessages.postStart(params)
        setConversationId(response.data.conversationId)
        fetchMessageDetail(response.data.conversationId)
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log('Failed to fetch list posts: ', error)
    }
  }

  useEffect(() => {
    fetchStartMessage()
  }, [idFriend._id, beforeId])

  const fetchMoreData = () => {
    const lastMessage = listMessages[listMessages.length - 1]
    setBeforeId(lastMessage._id)
  }

  const sendMessage = async () => {
    try {
      if (idFriend) {
        if (idFriend.isGroup) {
          const params = {
            receiverId: idFriend._id,
            content: contentMsg,
            isGroup: idFriend.isGroup,
          }
          const response = await userMessages.post(params)
          if (inputRef.current) {
            inputRef.current.focus()
          }
          setContentMsg('')
        } else {
          const params = {
            receiverId: idFriend._idFr,
            content: contentMsg,
            isGroup: idFriend.isGroup,
          }
          const response = await userMessages.post(params)
          if (inputRef.current) {
            inputRef.current.focus()
          }
          setContentMsg('')
        }
      }
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  // const isEthereumAddress = (address) => {
  //   return EthereumAddress.isAddress(address)
  // }
  // const testAddress = '0xB30E43ebe3a9d2B2cC5122dcf7D929685d8aa9f9'
  // console.log(isEthereumAddress(testAddress))

  const extractEthereumAddress = (input) => {
    const regex = /(0x[a-fA-F0-9]{40})/g
    const matches = input.match(regex)
    return matches ? matches : []
  }

  const handleSendAddress = (ethereumAddresses) => {
    setOpenModalValues(ethereumAddresses)
    console.log('Sending to addresses:', ethereumAddresses)
  }

  const sendFileToIPFS = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      const resFile = await axios({
        method: 'post',
        url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
        data: formData,
        headers: {
          pinata_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_KEY}`,
          pinata_secret_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_SECRET}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`
      setImgHash([...imgHash, ImgHash])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('Error sending File to IPFS: ')
      console.log(error)
    }
  }

  const renderMessage = (message) => {
    const ethereumAddresses = extractEthereumAddress(message.content)

    return (
      <>
        {message.type === 'text' && (
          <StyledText>
            <BoxMessage>
              {extractEthereumAddress(message.content) && (
                <>
                  <TextMessage style={{ marginBottom: `${ethereumAddresses.length > 0 && '4px'}` }}>
                    {message.content}
                  </TextMessage>
                  {ethereumAddresses.length > 0 && (
                    <BtnsendAddress onClick={() => handleSendAddress(ethereumAddresses)}>Send</BtnsendAddress>
                  )}
                </>
              )}
            </BoxMessage>
          </StyledText>
        )}
        {message.type === 'call' && (
          <CallMessage>
            {message.content === 'Video Call' ? (
              <IsIcon w={26} h={26} src={images.video_call} curs={true} alt="call" />
            ) : (
              <IsIcon w={26} h={26} src={images.audio_call} curs={true} alt="call" />
            )}
            {message.content}
          </CallMessage>
        )}
        {message.type === 'image' && <ImageMessage src={message.content} alt="Image" />}
      </>
    )
  }

  const [openSendTokenModal, onDismissSendTokenModal] = useModal(
    <SendTokenModal
      address={openModalValues}
      setTransaction={setTransaction}
      onDismiss={() => onDismissSendTokenModal}
    />,
    true,
    false,
    'removeSendTokenModal',
  )

  useEffect(() => {
    if (openModalValues.length > 0) {
      openSendTokenModal()
    }
  }, [openModalValues])

  return (
    <>
      {idFriend && (
        <Chats>
          <HeaderChats>
            {!inFoFr ? (
              <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
                <StyledBack onClick={() => setActive(null)}>
                  <IsIcon w={18} h={18} src={images.arrowleft} curs={true} />
                </StyledBack>
                <div style={{ width: '40px', height: '40px' }}>
                  <Avatar width={40} height={40} data={idFriend?.avatar} />
                </div>
                <StyledNameChats>
                  <UserName data={idFriend.name} />
                </StyledNameChats>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
                <StyledBack onClick={() => setActive(null)}>
                  <IsIcon w={18} h={18} src={images.arrowleft} curs={true} />
                </StyledBack>
                <div style={{ width: '40px', height: '40px' }}>
                  <Avatar width={40} height={40} data={idFriend?.avatar} />
                </div>
                <StyledNameChats>
                  <UserName
                    data={idFriend.name.length > '15' ? `${idFriend.name.substring(0, 16)}...` : idFriend.name}
                  />
                  {/* <p>{idFriend ? 'online' : 'offline'}</p> */}
                </StyledNameChats>
              </div>
            )}
            {!idFriend.isGroup && (
              <IconGroup>
                <BtnIcon onClick={() => handleSend('InvitationTypeVideoCall', idFriend._idFr)}>
                  <IsIcon w={18} h={18} src={images.video_solid} alt="more" curs={true} />
                </BtnIcon>
                <BtnIcon onClick={() => handleSend('InvitationTypeVoiceCall', idFriend._idFr)}>
                  <IsIcon w={17} h={17} src={images.phone_solid} alt="more" curs={true} />
                </BtnIcon>
                {/* <img style={{ cursor: 'pointer' }} src={images.morefill} alt="more" /> */}
              </IconGroup>
            )}
          </HeaderChats>
          <BoddyChats>
            <ContainerChat>
              {listMessages.slice().map((i, ix) => (
                <div key={ix}>
                  {i.senderId === userData.userId ? (
                    <StyledItemChat>
                      <StyledMore className="style-show-more" key={i.senderId}>
                        <Tippy
                          interactive
                          offset={[5, 5]}
                          placement={'bottom-start'}
                          trigger="click"
                          render={(attrs) => (
                            <ModalTippy>
                              <ModalTippyItem>Edit</ModalTippyItem>
                              <ModalTippyItem>Move to trash</ModalTippyItem>
                            </ModalTippy>
                          )}
                        >
                          <ImgMore>
                            <img
                              style={{ width: '16px', height: '16px', cursor: 'pointer', margin: '6px' }}
                              src={images.morefill}
                              alt="icon"
                            />
                          </ImgMore>
                        </Tippy>
                      </StyledMore>
                      <SentMessage key={ix}>{renderMessage(i)}</SentMessage>
                    </StyledItemChat>
                  ) : (
                    <Item>
                      <Avatar data={i.authorInfo?.avatar} width={32} height={32} />
                      <StyledChat>
                        <ReceivedMessage key={ix}>{renderMessage(i)}</ReceivedMessage>
                        <StyledMore className="style-show-more" key={i.senderId}>
                          <Tippy
                            interactive
                            offset={[5, 5]}
                            placement={'top-start'}
                            trigger="click"
                            render={(attrs) => (
                              <ModalTippy>
                                <ModalTippyItem>Edit</ModalTippyItem>
                                <ModalTippyItem>Move to trash</ModalTippyItem>
                              </ModalTippy>
                            )}
                          >
                            <ImgMore>
                              <img
                                style={{ width: '16px', height: '16px', cursor: 'pointer', margin: '6px' }}
                                src={images.morefill}
                                alt="icon"
                              />
                            </ImgMore>
                          </Tippy>
                        </StyledMore>
                      </StyledChat>
                    </Item>
                  )}
                </div>
              ))}
              {isLoading && (
                <div>
                  <SentMessage className="stychats">
                    <Skeleton borderRadius={12} width={60} height={24} />
                  </SentMessage>
                  <Item>
                    <Skeleton width={32} height={32} borderRadius={50} />
                    <ReceivedMessage className="stychats">
                      <Skeleton borderRadius={12} width={80} height={24} />
                      <Skeleton borderRadius={12} width={120} height={24} />
                    </ReceivedMessage>
                  </Item>
                  <SentMessage className="stychats">
                    <Skeleton borderRadius={12} width={80} height={24} />
                    <Skeleton borderRadius={12} width={120} height={24} />
                  </SentMessage>
                  <Item>
                    <Skeleton width={32} height={32} borderRadius={50} />
                    <ReceivedMessage className="stychats">
                      <Skeleton borderRadius={12} width={80} height={24} />
                      <Skeleton borderRadius={12} width={120} height={24} />
                    </ReceivedMessage>
                  </Item>
                  <SentMessage className="stychats">
                    <Skeleton borderRadius={12} width={80} height={24} />
                    <Skeleton borderRadius={12} width={120} height={24} />
                  </SentMessage>
                  <Item>
                    <Skeleton width={32} height={32} borderRadius={50} />
                    <ReceivedMessage className="stychats">
                      <Skeleton borderRadius={12} width={60} height={24} />
                      <Skeleton borderRadius={12} width={80} height={24} />
                      <Skeleton borderRadius={12} width={120} height={24} />
                    </ReceivedMessage>
                  </Item>
                </div>
              )}
            </ContainerChat>
            {listMessages.length >= 20 && checkViewMore && !isLoading && (
              <ViewMore onClick={fetchMoreData}>view more</ViewMore>
            )}
          </BoddyChats>
          <FooterChats>
            <StyledClick>
              <StyledChooseFile>
                {/* <label htmlFor="UpImg" className="custom-file-2"> */}
                <img style={{ width: '18px', height: '18px' }} src="./images/icons/chooseimg.png" alt="choose file" />
                {/* </label>
                <input type="file" id="UpImg" name="files[]" accept="image/*" onChange={(e) => sendFileToIPFS(e)} /> */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              </StyledChooseFile>
            </StyledClick>
            <StyledInputSend>
              <InputSend
                ref={inputRef}
                value={contentMsg}
                onChange={(e) => setContentMsg(e.target.value)}
                onKeyUp={handleKeyDown}
                type="text"
                placeholder="Aa"
              />
              <StyledClick>
                <img src={images.face_smile} alt="icon" />
              </StyledClick>
            </StyledInputSend>
            <StyledClick onClick={sendMessage}>
              {contentMsg.length > 0 && <img src={images.send} alt="send" />}
            </StyledClick>
          </FooterChats>
        </Chats>
      )}
    </>
  )
}

export default Conversation

import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 79px;
  display: flex;
  flex-direction: row;
`
export const ListChats = styled.div`
  min-width: 300px;
  width: 30%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  @media (max-width: 625px) {
    min-width: 100%;
    width: 100%;
  }
`
export const BoxChats = styled.div`
  width: 100%;
  height: 70px;
  padding: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
`
export const BoxSearch = styled.div`
  width: 100%;
  padding: 16px;
`

export const StyledItemchats = styled.div`
  width: 70%;
  height: calc(100vh - 80px);
  @media (max-width: 625px) {
    width: 100%;
  }
`
export const Chats = styled.div`
  width: 100%;
  height: 100%;
`
export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #1b1b1b;
  }
`
export const More = styled.div`
  width: 48px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #ccc;
  cursor: pointer;
  }
`
export const HeaderChats = styled.div`
  width: 100%;
  height: 70px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  @media (max-width: 575px) {
    padding: 12px 4px;
  }
`
export const BoddyChats = styled.div`
  overflow: auto;
  max-height: calc(100vh - 210px);
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
export const ContainerChat = styled.div`
  width: 100%;
  height: auto;
  padding: 0 8px;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  .stychats {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`
export const FooterChats = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const StyledSearch = styled.div`
  width: 100%;
  height: 37px;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f1f2f5;
`
export const ImgSearch = styled.img`
  width: 18px;
  height: 18px;
  margin: 0 4px 0 12px;
`
export const InputSearch = styled.input`
  width: 100%;
  height: 34px;
  background: #f1f2f5;
  border: none;
  border-radius: 0 18px 18px 0;
  color: #1b1b1b;
  padding: 0 6px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  &:focus-visible {
    outline: none;
  }
`
export const ListItemChats = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  .activeItem {
    background: #fef1f1;
    border-radius: 8px;
  }
`
export const StyledItemChats = styled.div`
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 180px);
  height: 100%;
  padding: 0 16px;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const ItemChats = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  &:active {
    background: #fef1f1;
    border-radius: 8px;
  }
  &:hover {
    background: #f3f3f3;
    border-radius: 8px;
  }
`
export const Item = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 4px;
  .style-show-more {
    display: none;
  }
  &:hover {
    .style-show-more {
      display: flex;
    }
  }
`
export const StyledName = styled.div`
  max-width: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .noSeen {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #000;
  }
  .seen {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #ccc;
  }
`
export const StyledNameChats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #ccc;
  }
`
export const StyledInputSend = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border-radius: 18px;
  background: #f1f2f5;
`
export const InputSend = styled.input`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  border: none;
  padding: 0 16px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #1b1b1b;
  background: #f1f2f5;
  &:focus-visible {
    outline: none;
  }
`
export const StyledText = styled.div`
  max-width: 300px;
  width: 100%;
  height 100%;
  margin: 2px;
`
export const StyledAddress = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: blue;
  text-direction: underline;
  word-break: break-word;
`
export const StyledTextMess = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  word-break: break-word;
`
export const SentMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  text-align: right;
`

export const ReceivedMessage = styled.div`
  text-align: left;
`

export const BoxMessage = styled.div`
  position: relative;
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const TextMessage = styled.p`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: #050505;
  background-color: #f0f0f0;
  padding: 8px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  text-align: start;
  word-break: break-word;
`
export const ImageMessage = styled.img`
  max-width: 250px;
  width: 100%;
  height: auto;
  margin: 2px;
  border-radius: 8px;
`
export const CallMessage = styled.div`
  width: 130px;
  height: 45px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #050505;
  background-color: #f0f0f0;
  padding: 0 12px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  margin: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
export const StyledClick = styled.div`
  width: 40px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`
export const ViewMore = styled.div`
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
`
export const StyledBack = styled.div`
  display: none;
  @media (max-width: 625px) {
  width 32px;
  height 32px;
  background-color: #fff;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #fde0e0;
  }
  }
`
export const TitleLine = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  padding: 6px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #65676b;
`
export const IconGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
`
export const BtnIcon = styled.div`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #fde0e0;
  }
`
export const StyledMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalTippy = styled.div`
  max-width: 160px;
  width: 160px;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
  color: #000;
  background: #fff;
`
export const ModalTippyItem = styled.div`
  width: 100%;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  text-align: end;
  color: #000;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
export const StyledChat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
export const StyledItemChat = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  .style-show-more {
    display: none;
  }
  &:hover {
    .style-show-more {
      display: flex;
    }
  }
`
export const ImgMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
export const BtnsendAddress = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 18px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: #dc2828;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  &:hover {
    opacity: 0.7;
  }
`
export const StyledChooseFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0;
  border-radius: 8px;
  input[type='file'] {
    display: none;
  }
  .custom-file-2 {
    margin: 0;
    cursor: pointer;
  }
`

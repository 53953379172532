import { useContext, useEffect } from 'react'
import { useModal } from '@pancakeswap/uikit'
import { useNetwork, useSigner } from 'wagmi'
import { WrongNetworkModal } from 'components/NetworkModal/WrongNetworkModal'
import { atom, useAtom } from 'jotai'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { UserContext } from 'astore/UserContext'
import useCall from 'hooks/useCall'
import { ModalCheckRegister } from './ModalCheckRegister'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
// import useNotify from 'hooks/useNotify'
export const hideWrongNetworkModalAtom = atom(false)

export const ModalRegister = () => {
  const { userData, clearData } = useContext(UserContext)
  const { chains, chain } = useNetwork()
  const { account, chainId } = useWeb3React()
  const { isWrongNetwork } = useActiveWeb3React()
  const { init } = useCall()
  // const { notificationPermission } = useNotify()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { data: signer } = useSigner()
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const [dismissWrongNetwork, setDismissWrongNetwork] = useAtom(hideWrongNetworkModalAtom)
  const currentChain = chains.find((c) => c.id === Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN))
  const [openModalChange, onCloseNetwork] = useModal(
    <WrongNetworkModal currentChain={currentChain} onDismiss={() => setDismissWrongNetwork(true)} />,
    false,
    false,
    'ModalWrongNetwork',
  )
  const [openModal, onDismiss] = useModal(
    <ModalCheckRegister onCheck={() => checkRegis()} onDismiss={() => onDismiss} />,
    true,
    false,
    'removeModalCheckRegister',
  )

  const checkRegis = async () => {
    if (account && userData && userData?.address !== account) {
      clearData()
      window?.localStorage?.removeItem('user_social')
      window?.localStorage?.removeItem('token')
      window?.localStorage?.removeItem('refreshToken')
    }
    if (chainId != null && chainId === Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)) {
      onCloseNetwork()
      if (account) {
        const register = await refferCT.isReferrer(account)
        if (!register) {
          openModal()
        }
      }
    } else {
      openModalChange()
    }
  }
  useEffect(() => {
    if (chain?.id) checkRegis()
    // if (userData.userId) init()
    // if (userData.userId) notificationPermission()
  }, [account, chain, isWrongNetwork, localStorage])
  return <></>
}

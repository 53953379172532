import { Button } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const Wrapper = styled.div`
  * {
    font-family: Inter, sans-serif;
  }
  max-width: 680px;
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 99px auto;
`
export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  .fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(244, 244, 244, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: none;
  }
`
export const HeaderPost = styled.div`
  width: 100%;
  padding: 12px 12px 2px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
`
export const CardAction = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: 575px) {
    padding: 0 12px;
  }
`
export const User = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      .text {
        color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
`
export const Control = styled.div`
  display: flex;
  align-items: flex-start;
`
export const StyledIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  &:hover {
    background: #f1f1f1;
  }
`
export const Content = styled.div`
  width: 100%;
  padding: 4px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--mode-alpha-900, #1a1a1a);
`
export const InteractCurrent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 8px;
  .like {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    span {
      color: var(--mode-alpha-900, #1a1a1a);
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .comment {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--mode-alpha-900, #1a1a1a);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`
export const InteractAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-top: 1px solid var(--colors-base-200, rgba(26, 26, 26, 0.2));
`
export const ItemAction = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: #f2f2f2;
  }
  span {
    height: 24px;
    color: var(--mode-alpha-900, #1a1a1a);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`
export const StyledShare = styled.div`
  max-width: 655px;
  width: 100%;
  height: 100%;
`
export const Shared = styled.div`
  width: 100%;
  padding: 0 12px;
`
export const StyledMore = styled.div`
  max-width: 680px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 12px;
  background: #fff;
  &:hover {
    opacity: 0.5;
  }
`
export const StyledSkeleton = styled.div`
  width: 100%;
  min-height: 200px;
  height: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  @media (max-width: 575px) {
    border-radius: 0;
  }
`
export const HeaderSkeloton = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`
export const BodySkeloton = styled.div`
  width: 100%;
  height: 100px;
`
export const FooterSkeloton = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
export const ModalTippy = styled.div`
  max-width: 280px;
  width: 280px;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
  color: #000;
  background: #fff;
`
export const ModalTippyItem = styled.div`
  width: 100%;
  height: 34px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  text-align: end;
  color: #000;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
export const StyledLine = styled.hr`
  margin: 0 12px;
  border-top: 1px solid #ccc;
`
export const StyledIconRef = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
export const WrapperNotFound = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledTextNotFound = styled.div`
  padding: 0 0 40px;
  color: #68778d;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`
export const BtnBacktoHome = styled(Button)`
  max-width: 250px;
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 12px;
  background: var(--primary-600-default, #ff0420);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`

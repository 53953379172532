import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { connectorLocalStorageKey, useToast } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useCallback, useContext } from 'react'
import { useAppDispatch } from 'state'
import { ConnectorNames } from 'config/wallet'
import { useConnect, useDisconnect, useNetwork, ConnectorNotFoundError, UserRejectedRequestError } from 'wagmi'
import { clearUserStates } from '../utils/clearUserStates'
import { useActiveChainId } from './useActiveChainId'
import { useSessionChainId } from './useSessionChainId'
import { ethers } from 'ethers'
import moment from 'moment'
import useActiveWeb3React from './useActiveWeb3React'
import { UserContext } from 'astore/UserContext'
import accountApi from 'api/accountApi'

const useAuth = () => {
  const { userData, setUser, clearData } = useContext(UserContext)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { connectAsync, connectors } = useConnect()
  const { chain } = useNetwork()
  const { disconnectAsync } = useDisconnect()
  const { toastError, toastSuccess } = useToast()
  const { chainId } = useActiveChainId()
  const account = useActiveWeb3React()
  const [, setSessionChainId] = useSessionChainId()
  const user = window.localStorage.getItem('user_social')
  const userDataLocal = user ? JSON.parse(user) : ''

  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID)
      try {
        const connected = await connectAsync({ connector: findConnector, chainId })
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chainId', connected.chain.id)
          setSessionChainId(connected.chain.id)
        }
        // console.log('userDataLocal', userDataLocal)
        // console.log('userDataLocal', userDataLocal.address)

        if ((userDataLocal && connected.account !== userDataLocal.userInfo.address) || userDataLocal === '')
          await getSignatureMessage(connected.account)
      } catch (error) {
        console.error(error)
        clearData()
        window?.localStorage?.removeItem(connectorLocalStorageKey)
        window?.localStorage?.removeItem('user_social')
        window?.localStorage?.removeItem('refreshToken')
        window?.localStorage?.removeItem('token')
        if (error instanceof ConnectorNotFoundError) {
          toastError(t('Provider Error'))
          return
        }
        if (error instanceof UserRejectedRequestError) {
          return
        }
        if (error instanceof Error) {
          toastError(error.message, t('Please authorize to access your account'))
        }
      }
    },
    [connectors, connectAsync, chainId, setSessionChainId, toastError, t],
  )

  const getSignatureMessage = async (account) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum as any)
      const signer = provider.getSigner()
      const currentTimeInMilliseconds = moment().valueOf()
      const messageToSign = 'SocialFi wants to sign in Wallet App by OP Net at: ' + currentTimeInMilliseconds
      const signature = await signer.signMessage(messageToSign)
      const payload = {
        address: account,
        signature: signature,
        deviceToken: '',
        nonce: currentTimeInMilliseconds,
      }
      const response = await accountApi.post(payload)
      toastSuccess(t('Success'), t('Connect Success'))
      setUser(response.data.userInfo)
      window?.localStorage?.setItem('user_social', JSON.stringify({ userInfo: response.data.userInfo }))
      window?.localStorage?.setItem('refreshToken', response.data.refreshToken)
      window?.localStorage?.setItem('token', response.data.token)
    } catch (error) {
      clearData()
      toastError(t('Error'), t('Connect Failed'))
      window?.localStorage?.removeItem('user_social')
      window?.localStorage?.removeItem('refreshToken')
      window?.localStorage?.removeItem('token')
      console.log('Failed to fetch userInfo: ', error)
    }
  }

  const logout = useCallback(async () => {
    try {
      await disconnectAsync()
      await clearData()
      window?.localStorage?.removeItem('user_social')
      window?.localStorage?.removeItem('refreshToken')
      window?.localStorage?.removeItem('token')
    } catch (error) {
      console.error(error)
    } finally {
      clearUserStates(dispatch, { chainId: chain?.id, isDeactive: true })
    }
  }, [disconnectAsync, dispatch, chain?.id])

  const connetSocial = useCallback(async () => {
    try {
      await getSignatureMessage(account.account)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        toastError(error.message, t('Please try again'))
      }
    }
  }, [account])

  return { login, logout, connetSocial }
}

export default useAuth

import React, { useContext } from 'react'
import styled from 'styled-components'
import Trend from './components/Trend'
import Follow from './components/Follow'
import Section from './components/Section'
import { UserContext } from 'astore/UserContext'
import SideBar from 'components/SideBar'
import { PageMeta } from 'components/Layout/Page'
import { SEOPage } from 'components/SEOPage'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const WrapperV1 = styled.div`
  width: 100%;
  min-height: 90vh;
  height: 100%;
  font-style: normal;
  background: #f4f4f5;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 79px;
  padding: 24px;
  gap: 24px;

  @media screen and (max-width: 899px) {
    padding: 0;
  }
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  font-style: normal;
  background: #f4f4f5;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 79px;
  padding: 24px;
  gap: 24px;

  @media screen and (max-width: 899px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`
const Container = styled.div`
  max-width: 680px;
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  position: relative;
  background: #f4f4f5;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 3;
`
const StyledRightBar = styled.div`
  @media screen and (max-width: 1360px) {
    display: none;
  }
`
const RightBar = styled.div`
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 4;
`

const HomePage: React.FC = () => {
  const { userData } = useContext(UserContext)
  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'home')

  return (
    <>
      <PageMeta meta={findMeta} />
      <StyledWrapper>
        <SideBar />
        <WrapperV1>
          <Container>
            <Section userData={userData} />
          </Container>
          <StyledRightBar>
            <RightBar>
              <Trend />
              <Follow />
            </RightBar>
          </StyledRightBar>
        </WrapperV1>
      </StyledWrapper>
    </>
  )
}

export default HomePage

import axiosClient from './axiosClient'
import {
  ListResponse,
  Params,
  Posts,
  QueryList,
  PostStatus,
  PostShare,
  ListComment,
  PostComment,
  EditPost,
  EditComment,
} from 'models'

const postsApi = {
  getAll: (params: Params): Promise<ListResponse<Posts>> => {
    const url = `/posts`
    return axiosClient.get(url, { params })
  },

  get: (params: QueryList) => {
    const url = `/posts`
    return axiosClient.get(url, { params })
  },
  getpostId: (postId: string) => {
    const url = `/posts/${postId}`
    return axiosClient.get(url)
  },

  post: (id: string, params: Params) => {
    const url = `/posts?authorId=${id}`
    return axiosClient.get(url, { params })
  },

  likepost: (id: string) => {
    const url = `/posts/${id}/likes`
    return axiosClient.post(url)
  },

  statusPost: (body: PostStatus) => {
    const url = `/posts`
    return axiosClient.post(url, body)
  },

  sharePost: (body: PostShare) => {
    const url = `/posts/share`
    return axiosClient.post(url, body)
  },
  editpost: (id: string, body: EditPost) => {
    const url = `/posts/${id}`
    return axiosClient.put(url, body)
  },
  deletepost: (id: string) => {
    const url = `/posts/${id}`
    return axiosClient.delete(url)
  },

  comment: (params: ListComment) => {
    const url = `/comments`
    return axiosClient.get(url, { params })
  },

  commentpost: (id: string) => {
    const url = `/comments/${id}/likes`
    return axiosClient.post(url)
  },
  postcomment: (body: PostComment) => {
    const url = `/comments`
    return axiosClient.post(url, body)
  },
  likecomment: (id: string) => {
    const url = `/comments/${id}/likes`
    return axiosClient.post(url)
  },
  editcomment: (id: string, body: EditComment) => {
    const url = `/comments/${id}`
    return axiosClient.put(url, body)
  },
  deletecomment: (id: string) => {
    const url = `/comments/${id}`
    return axiosClient.delete(url)
  },
}

export default postsApi

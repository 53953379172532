import axiosClient from "./axiosClient"

const accountApi = {
  post: (data) => {
    const url = `/wallet_connect/auth`
    return axiosClient.post(url, data)
  },
}

export default accountApi

import { useCallback, useContext } from 'react'
import { UserContext } from 'astore/UserContext'
import messagesApi from 'api/messagesApi'

const useCall = () => {
  const { userData } = useContext(UserContext)

  // const generateToken = (tokenServerUrl, userID) => {
  //   return fetch(`${tokenServerUrl}/access_token?userID=${userID}&expired_ts=7200`, {
  //     method: 'GET',
  //   }).then((res) => res.json())
  // }

  const handleSend = async (type, _id: string) => {
    const { ZegoUIKitPrebuilt } = await import('@zegocloud/zego-uikit-prebuilt')
    const callee = _id.trim()
    if (!callee) {
      alert('userID Invalid!!')
      return
    }
    const callType =
      type === 'InvitationTypeVoiceCall'
        ? ZegoUIKitPrebuilt.InvitationTypeVoiceCall
        : ZegoUIKitPrebuilt.InvitationTypeVideoCall
    const users = callee.split(',').map((id) => ({
      userID: id.trim(),
      userName: id,
    }))
    const zpLocal = await window?.localStorage.getItem('zp')
    const kitToken = await JSON.parse(zpLocal)
    const zps = await ZegoUIKitPrebuilt.create(kitToken)
    zps
      .sendCallInvitation({
        callees: users,
        callType: callType,
        timeout: 60,
      })
      .then((res) => {
        console.warn(res)
        if (res.errorInvitees.length) {
          alert('The user dose not exist or is offline.')
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const init = useCallback(async () => {
    const { ZegoUIKitPrebuilt } = await import('@zegocloud/zego-uikit-prebuilt')
    const { ZIM } = await import('zego-zim-web')
    const userID = userData.userId
    const userName = userData.name
    // const { token } = await generateToken('https://nextjs-token-callinvitation.vercel.app/api', userID)
    const getToken = await messagesApi.getToken()
    const token = getToken.data.token

    const KitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(1630035383, token, null, userID, userName)

    const zp = ZegoUIKitPrebuilt.create(KitToken)

    window?.localStorage.setItem('zp', JSON.stringify(KitToken))
    zp.addPlugins({ ZIM })
  }, [userData])

  return { init, handleSend }
}

export default useCall

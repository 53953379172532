import React, { useRef, useState } from 'react'
import images from 'configs/images'
import useAuth from 'hooks/useAuth'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWallet } from 'hooks/useWallet'
import { StyledImages } from 'components/StyledImages'
import { PostDetail } from 'components/Post/PostDetailModal'
import { PostShare } from 'components/Post/ShareDetail'
import postsApi from 'api/postApi'
import Skeleton from 'react-loading-skeleton'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {
  BodySkeloton,
  CardAction,
  Container,
  Content,
  Control,
  FooterSkeloton,
  HeaderPost,
  HeaderSkeloton,
  InteractAction,
  InteractCurrent,
  ItemAction,
  ModalTippy,
  ModalTippyItem,
  Shared,
  StyledIcon,
  StyledLine,
  StyledMore,
  StyledShare,
  StyledSkeleton,
  User,
  Wrapper,
} from './styled'
import IsIcon from 'components/IsIcon'
import DayTimeDifference from 'components/DayTimeDifference'
import StyledUserName from 'components/StyledUserName'
import Tippy from '@tippyjs/react/headless'
import { useToast } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { EditStatusModal } from '../EditStatusModal/EditStatusModal'
import StyledAvatar from 'components/StyledAvatar'
import styled from 'styled-components'

const StyledIconRef = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const status = [
  {
    title: 'everyone',
    icon: images.public,
  },
  {
    title: 'onlyMe',
    icon: images.only_me,
  },
  {
    title: 'friends',
    icon: images.friends,
  },
]

const Post = ({ userData, listDataPost, setListDataPost, pageOrder, setPageOrder, isLoading, setIsLoading }) => {
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [postModals, setPostModals] = useState({})
  const inputRef = useRef(null)
  const yourRef = useRef(null)
  const [showCopied, setShowCopied] = useState(false)

  const handleOpenModal = (item) => {
    // document.body.style.overflow = 'hidden'
    setPostModals(item)
  }

  const handleLikePost = async (postId) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      await connetSocial()
      return
    }
    try {
      if (postId && account) {
        const response = await postsApi.likepost(postId)
        const updatedListData = listDataPost.map((post) => {
          if (post._id === postId) {
            if (post.likedUsers.includes(userData.userId)) {
              post.likedUsers = post.likedUsers.filter((e) => e !== userData.userId)
            } else {
              post.likedUsers.push(userData.userId)
            }
          }
          return post
        })
        setListDataPost(updatedListData)
      }
    } catch (error) {
      console.log('Failed to fetch userInfo: ', error)
    }
  }

  const SkeletonLoading = () => {
    return (
      <StyledSkeleton>
        <HeaderSkeloton>
          <Skeleton width={44} height={44} borderRadius={50} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Skeleton width={80} height={16} />
            <Skeleton width={120} height={16} />
          </div>
        </HeaderSkeloton>
        <BodySkeloton />
        <FooterSkeloton>
          <Skeleton width={60} height={14} />
          <Skeleton width={60} height={14} />
          <Skeleton width={60} height={14} />
        </FooterSkeloton>
      </StyledSkeleton>
    )
  }

  const deletePost = async (postId) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      await connetSocial()
      return
    }
    try {
      if (postId && account) {
        const response = await postsApi.deletepost(postId)
        const indexToDelete = listDataPost.findIndex((post) => post._id === postId)
        if (indexToDelete !== -1) {
          listDataPost.splice(indexToDelete, 1)
        }
        toastSuccess(t('Success'), t('Move to trash Success'))
      }
    } catch (error) {
      toastError(t('Error'), t('Please try again'))
      console.log('Failed to fetch userInfo: ', error)
    }
  }

  const copyText = (text) => {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const handleRef = (_idpost) => {
    const param = window.location.origin
    const text = `${param}/posts?id=${_idpost}`
    copyText(text)
    setShowCopied(true)
  }

  const handleLeave = () => {
    setTimeout(() => {
      setShowCopied(false)
    }, 100)
  }

  const shareOnTelegram = (_idpost) => {
    const param = window.location.origin
    const messageText = `${param}/posts?id=${_idpost}`
    const telegramShareURL = 'https://t.me/share/url?url=' + encodeURIComponent(messageText)
    window.open(telegramShareURL, '_blank')
  }

  const shareOnTwitter = (_idpost) => {
    const param = window.location.origin
    const messageText = `${param}/posts?id=${_idpost}`

    const imageUrl = 'https://opsocialfi.com/images/thumnal.jpg'

    const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      messageText,
    )}&media=${encodeURIComponent(imageUrl)}`

    // const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(messageText)}`

    window.open(twitterShareURL, '_blank')
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Wrapper>
        {listDataPost.map((i, ix) => (
          <Container key={ix}>
            <HeaderPost>
              <User>
                <StyledAvatar data={i.authorInfo.avatar} link={`/profile?id=${i.authorInfo._id}`} w={44} h={44} />
                <div className="userInfo">
                  <StyledUserName link={`/profile?id=${i.authorInfo._id}`} data={i.authorInfo.name} />
                  <div className="status">
                    <span className="text">
                      <DayTimeDifference At={i.createdAt} />
                    </span>
                    <IsIcon w={14} h={14} src={status.find((st) => st.title === i.audience)?.icon} curs={true} />
                  </div>
                </div>
              </User>
              <Control>
                <StyledIcon key={i._id}>
                  <Tippy
                    interactive
                    placement={'bottom-end'}
                    trigger="click"
                    render={(attrs) => (
                      <>
                        {i.authorInfo._id === userData.userId ? (
                          <ModalTippy>
                            <ModalTippyItem onClick={() => handleOpenModal({ ...postModals, [i._id]: 'edit' })}>
                              <IsIcon w={18} h={18} src={images.editstatus} curs={true} />
                              Edit article
                            </ModalTippyItem>
                            <StyledLine />
                            <ModalTippyItem onClick={() => deletePost(i._id)}>
                              <IsIcon w={18} h={18} src={images.trash} curs={true} />
                              Move to trash
                            </ModalTippyItem>
                          </ModalTippy>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  >
                    <img
                      style={{ width: '21px', height: '21px', cursor: 'pointer' }}
                      src={images.morefill}
                      alt="icon"
                    />
                  </Tippy>
                </StyledIcon>
                {i.authorInfo._id !== userData.userId && (
                  <StyledIcon>
                    <IsIcon w={24} h={24} src={images.closeline} curs={true} />
                  </StyledIcon>
                )}
                {postModals[i._id] === 'edit' && (
                  <div className="fullscreen-modal">
                    <EditStatusModal
                      userData={userData}
                      dataEdit={i}
                      listDataPost={listDataPost}
                      setListDataPost={setListDataPost}
                      onDismiss={() => {
                        // document.body.style.overflow = 'auto'
                        setPostModals({ ...postModals, [i._id]: null })
                      }}
                    />
                  </div>
                )}
              </Control>
            </HeaderPost>
            <Content>{i.content}</Content>
            {i.isShared === false ? (
              <>
                {i.images.length > 0 && (
                  <div style={{ width: '100%' }}>
                    <StyledImages data={i.images} setData={null} />
                  </div>
                )}
              </>
            ) : (
              <>
                {i.sharedInfo && (
                  <Shared>
                    <StyledShare>
                      <div style={{ width: '100%' }}>
                        {i.sharedInfo.images.length > 0 && <StyledImages data={i.sharedInfo.images} setData={null} />}
                      </div>
                      <Container>
                        <HeaderPost>
                          <User>
                            <StyledAvatar
                              data={i.sharedInfo.authorInfo.avatar}
                              link={`/profile?id=${i.sharedInfo.authorInfo._id}`}
                              w={44}
                              h={44}
                            />
                            <div className="userInfo">
                              <StyledUserName
                                link={`/profile?id=${i.sharedInfo.authorInfo._id}`}
                                data={i.sharedInfo.authorInfo.name}
                              />
                              <div className="status">
                                <span className="text">
                                  <DayTimeDifference At={i.sharedInfo.createdAt} />
                                </span>
                                <IsIcon
                                  w={14}
                                  h={14}
                                  src={status.find((st) => st.title === i.sharedInfo.audience)?.icon}
                                  curs={true}
                                />
                              </div>
                            </div>
                          </User>
                        </HeaderPost>
                        <Content>{i.sharedInfo.content}</Content>
                      </Container>
                    </StyledShare>
                  </Shared>
                )}
              </>
            )}

            <CardAction>
              {(i.totalComments > 0 || i.likedUsers.length > 0) && (
                <InteractCurrent>
                  {i.likedUsers.length > 0 ? (
                    <div className="like">
                      <IsIcon src={images.iconL} curs={true} />
                      <span>{i.likedUsers.length}</span>
                    </div>
                  ) : (
                    <div />
                  )}
                  {i.totalComments > 0 && (
                    <div className="comment">
                      <span>{i.totalComments}</span>
                      <span>{i.totalComments <= 2 ? 'Comment' : 'Comments'}</span>
                    </div>
                  )}
                </InteractCurrent>
              )}
              <InteractAction>
                {!userData ? (
                  <ItemAction onClick={() => handleLikePost(i._id)}>
                    <IsIcon src={images.iconlike} curs={true} />
                    <span>Like</span>
                  </ItemAction>
                ) : (
                  <ItemAction onClick={() => handleLikePost(i._id)}>
                    {i.likedUsers.includes(userData.userId) ? (
                      <IsIcon src={images.iconL} curs={true} />
                    ) : (
                      <IsIcon src={images.iconlike} curs={true} />
                    )}
                    <span>Like</span>
                  </ItemAction>
                )}
                <ItemAction onClick={() => handleOpenModal({ ...postModals, [i._id]: 'detail' })}>
                  <IsIcon src={images.commentsicon} curs={true} />
                  <span>Comment</span>
                </ItemAction>
                {i.isShared === false ? (
                  <>
                    <Tippy
                      interactive
                      trigger="click"
                      render={(attrs) => (
                        <>
                          <ModalTippy>
                            <ModalTippyItem onClick={() => shareOnTwitter(i._id)}>
                              <IsIcon w={24} h={24} src={images.logotwitter} curs={true} />
                              Share with Twitter
                            </ModalTippyItem>
                            <ModalTippyItem onClick={() => shareOnTelegram(i._id)}>
                              <IsIcon w={24} h={24} src={images.logotelegram} curs={true} />
                              Share with Telegram
                            </ModalTippyItem>
                            {i.authorId !== userData.userId && (
                              <ModalTippyItem onClick={() => handleOpenModal({ ...postModals, [i._id]: 'share' })}>
                                <IsIcon w={24} h={24} src={images.logoshare} curs={true} />
                                Share now
                              </ModalTippyItem>
                            )}
                            <ModalTippyItem onClick={() => handleRef(i._id)}>
                              <StyledIconRef id="linkpost" src={images.copylink} onMouseLeave={handleLeave} />
                              Copy Link
                              <Tooltip anchorId="linkpost" content={showCopied ? 'Copied' : 'Copy'} />
                            </ModalTippyItem>
                          </ModalTippy>
                        </>
                      )}
                    >
                      <ItemAction>
                        <IsIcon src={images.shareicon} curs={true} />
                        <span>Share</span>
                      </ItemAction>
                    </Tippy>
                  </>
                ) : (
                  <>
                    <Tippy
                      interactive
                      trigger="click"
                      render={(attrs) => (
                        <>
                          <ModalTippy>
                            <ModalTippyItem onClick={() => shareOnTwitter(i._id)}>
                              <IsIcon w={24} h={24} src={images.logotwitter} curs={true} />
                              Share with Twitter
                            </ModalTippyItem>
                            <ModalTippyItem onClick={() => shareOnTelegram(i._id)}>
                              <IsIcon w={24} h={24} src={images.logotelegram} curs={true} />
                              Share with Telegram
                            </ModalTippyItem>
                            {i.authorId !== userData.userId && i?.sharedInfo?.authorId !== userData.userId && (
                              <ModalTippyItem onClick={() => handleOpenModal({ ...postModals, [i._id]: 'share' })}>
                                <IsIcon w={24} h={24} src={images.logoshare} curs={true} />
                                Share now
                              </ModalTippyItem>
                            )}
                            <ModalTippyItem onClick={() => handleRef(i._id)}>
                              <StyledIconRef id="linkpost" src={images.copylink} onMouseLeave={handleLeave} />
                              Copy Link
                              <Tooltip anchorId="linkpost" content={showCopied ? 'Copied' : 'Copy'} />
                            </ModalTippyItem>
                          </ModalTippy>
                        </>
                      )}
                    >
                      <ItemAction>
                        <IsIcon src={images.shareicon} curs={true} />
                        <span>Share</span>
                      </ItemAction>
                    </Tippy>
                  </>
                )}

                {postModals[i._id] === 'detail' && (
                  <div className="fullscreen-modal">
                    <PostDetail
                      data={i}
                      listDataPost={listDataPost}
                      setListDataPost={setListDataPost}
                      userData={userData}
                      inputRef={inputRef}
                      onDismiss={() => {
                        // document.body.style.overflow = 'auto'
                        setPostModals({ ...postModals, [i._id]: null })
                      }}
                    />
                  </div>
                )}
                {postModals[i._id] === 'share' && (
                  <div className="fullscreen-modal">
                    <PostShare
                      userData={userData}
                      dataShare={i}
                      listDataPost={listDataPost}
                      setListDataPost={setListDataPost}
                      onDismiss={() => {
                        // document.body.style.overflow = 'auto'
                        setPostModals({ ...postModals, [i._id]: null })
                      }}
                    />
                  </div>
                )}
              </InteractAction>
            </CardAction>
          </Container>
        ))}
        {!isLoading && listDataPost.length > pageOrder && (
          <StyledMore ref={yourRef} onClick={() => setPageOrder(pageOrder + 1)}>
            <p>Show more</p>
          </StyledMore>
        )}
      </Wrapper>
      {isLoading && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <SkeletonLoading />
          <SkeletonLoading />
        </div>
      )}
    </div>
  )
}

export default Post

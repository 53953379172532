import React from 'react'
import { Grid, ModalPostStatus, useToast } from '@pancakeswap/uikit'
import postsApi from 'api/postApi'
import Avatar from 'components/Avatar'
import { StyledImages } from 'components/StyledImages'
import UserName from 'components/UserName'
import images from 'configs/images'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import styled from 'styled-components'
import IsIcon from 'components/IsIcon'
import DayTimeDifference from 'components/DayTimeDifference'
import { useTranslation } from '@pancakeswap/localization'

const Wrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BtnPost = styled.button`
  width: 100%;
  height: 36px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 600;
  background: rgb(220, 40, 40);
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`
const StyledSelect = styled.select`
  padding: 1px;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ccc;
  color: #000;
  &:focus-visible {
    outline: none;
  }
`
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 45px;
  font-size: 16px;
  line-height: 18px;
  border: none;
  border-radius: none;
  background: none;
  padding: 10px 0;
  color: #000;
  &:focus-visible {
    outline: none;
  }
`
const StyledImgSS = styled.div`
  overflow: auto;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
const StyledImg = styled.div`
  max-height: 410px;
  width: auto;
  height: 100%;
`
const StyledShare = styled.div`
  max-width: 645px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
`
const TopCard = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
`
const User = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      .text {
        color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
`
const Content = styled.div`
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--mode-alpha-900, #1a1a1a);
`
const HeaderPost = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const status = [
  {
    title: 'everyone',
    icon: images.public,
  },
  {
    title: 'onlyMe',
    icon: images.only_me,
  },
  {
    title: 'friends',
    icon: images.friends,
  },
]

export const PostShare = ({ userData, dataShare, listDataPost, setListDataPost, onDismiss }) => {
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [audience, setAudience] = React.useState('everyone')
  const [content, setContent] = React.useState('')

  const fetchShare = async () => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      const payload = {
        postId: dataShare._id,
        content: content,
        audience: audience,
      }
      const response = await postsApi.sharePost(payload)
      const updatedListDataPost = listDataPost.map((post) => {
        if (post._id !== response.data.postId) {
          //  post.totalComments = post.totalComments + 1
        }
        return post
      })
      setListDataPost(updatedListDataPost)
      onDismiss()
      toastSuccess(t('Success!'), t('Share Success!'))
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  return (
    <ModalPostStatus title="Articles" color="#fff" onDismiss={onDismiss}>
      {dataShare && (
        <Wrapper>
          <Grid style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', paddingBottom: ' 12px' }}>
              <Avatar data={userData.avatar} width={44} height={44} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <UserName data={userData.name} />
                <StyledSelect value={audience} onChange={(e) => setAudience(e.target.value)}>
                  <option value="everyone">Everyone</option>
                  <option value="onlyMe">Only Me</option>
                  <option value="friends">Friends</option>
                </StyledSelect>
              </div>
            </div>
            <StyledImgSS>
              <StyledImg>
                <StyledTextarea
                  placeholder="What's on your mind?"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
                <div style={{ padding: '12px 0 0' }}>
                  {dataShare && (
                    <>
                      {dataShare.isShared === true ? (
                        <StyledShare>
                          <div style={{ width: '100%', height: '100%' }}>
                            {dataShare.sharedInfo.images.length > 0 && (
                              <StyledImages data={dataShare.sharedInfo.images} setData={null} />
                            )}
                          </div>
                          <HeaderPost>
                            <TopCard>
                              <User>
                                <Avatar data={dataShare.sharedInfo.authorInfo.avatar} width={44} height={44} />
                                <div className="userInfo">
                                  <UserName data={dataShare.sharedInfo.authorInfo.name} />
                                  <div className="status">
                                    <span className="text">
                                      <DayTimeDifference At={dataShare.sharedInfo.createdAt} />
                                    </span>
                                    <IsIcon
                                      w={14}
                                      h={14}
                                      src={status.find((st) => st.title === dataShare.sharedInfo.audience)?.icon}
                                      curs={true}
                                    />
                                  </div>
                                </div>
                              </User>
                            </TopCard>
                            <Content>{dataShare.sharedInfo.content}</Content>
                          </HeaderPost>
                        </StyledShare>
                      ) : (
                        <StyledShare>
                          <div style={{ width: '100%', height: '100%' }}>
                            {dataShare.images.length > 0 && <StyledImages data={dataShare.images} setData={null} />}
                          </div>
                          <HeaderPost>
                            <TopCard>
                              <User>
                                <Avatar data={dataShare.authorInfo.avatar} width={44} height={44} />
                                <div className="userInfo">
                                  <UserName data={dataShare.authorInfo.name} />
                                  <div className="status">
                                    <span className="text">
                                      <DayTimeDifference At={dataShare.createdAt} />
                                    </span>
                                    <IsIcon
                                      w={14}
                                      h={14}
                                      src={status.find((st) => st.title === dataShare.audience)?.icon}
                                      curs={true}
                                    />
                                  </div>
                                </div>
                              </User>
                            </TopCard>
                            <Content>{dataShare.content}</Content>
                          </HeaderPost>
                        </StyledShare>
                      )}
                    </>
                  )}
                </div>
              </StyledImg>
            </StyledImgSS>
            <BtnPost onClick={fetchShare}>Share</BtnPost>
          </Grid>
        </Wrapper>
      )}
    </ModalPostStatus>
  )
}

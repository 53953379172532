import axiosClient from "./axiosClient";
import { UserParams, UserDataFollow } from "models";

const usersApi = {
  getId: (id: string) => {
    const url = `/users/${id}`
    return axiosClient.get(url)
  },

  get: () => {
    const url = `/users/me`
    return axiosClient.get(url)
  },

  postFollow: (data: UserDataFollow) => {
    const url = `/users/follow`
    return axiosClient.post(url, data)
  },

  put: (params: UserParams) => {
    const url = `/users`
    const data = params
    return axiosClient.put(url, data)
  },
}

export default usersApi;
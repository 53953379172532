import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
export const Container = styled.div`
  width: 100%;
  max-height: 60vh;
  height: auto;
`
export const Headers = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px 12px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`
export const Avatar = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border-radius: var(--radius-radi-10, 1000px);
  border: 1px solid var(--mode-base-200, #e4e4e7);
  background: #fff;
`
export const StyledDayTime = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #56676b;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 575px) {
    display: none;
  }
`
export const UserInfor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const StyledIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  &:hover {
    background: #f1f1f1;
  }
`

export const Body = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  width: 100%;
  height: auto;
  padding: 4px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--mode-alpha-900, #1a1a1a);
  @media (max-width: 575px) {
    padding: 4px 12px;
  }
`
export const InteractAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 3px 0;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-top: 1px solid var(--colors-base-200, rgba(26, 26, 26, 0.2));
  border-bottom: 1px solid var(--colors-base-200, rgba(26, 26, 26, 0.2));
`
export const ItemAction = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: #f2f2f2;
  }
  span {
    height: 24px;
    color: var(--mode-alpha-900, #1a1a1a);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`
export const CardAction = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: 575px) {
    padding: 0 12px;
  }
`
export const InteractCurrent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 8px;
  .like {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    span {
      color: var(--mode-alpha-900, #1a1a1a);
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .comment {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--mode-alpha-900, #1a1a1a);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`
export const Shared = styled.div`
  width: 100%;
  padding: 0 12px;
`
export const StyledShares = styled.div`
  max-width: 640px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media (max-width: 662px) {
    max-width: 618px;
  }
`
export const ModalTippy = styled.div`
  max-width: 280px;
  width: 280px;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
  color: #000;
  background: #fff;
`
export const ModalTippyItem = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  text-align: end;
  color: #000;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
export const StyledLine = styled.hr`
  margin: 0 12px;
  border-top: 1px solid #ccc;
`
export const StyledComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 6px;
`
export const InputComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--mode-alpha-100, rgba(26, 26, 26, 0.1));
  background: var(--mode-base-50, #fcfcfc);
`
export const Input = styled.input`
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--mode-alpha-900, #1a1a1a);
  background: #fff;
  border: none;
  &:focus-visible {
    outline: none;
  }
`
export const BtnSend = styled.img`
  width: 21px;
  height: 21px;
  margin: 4px 6px;
  cursor: pointer;
`

import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Button, Flex } from '@pancakeswap/uikit'
import moment from 'moment'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import CountUp from 'react-countup'

const InfoDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  padding: 40px;
  border-radius: 24px;
  border: 1px solid var(--white-white-12, rgba(255, 255, 255, 0.12));
  background: var(--black-black-20, rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(5.5px);
    @media (max-width: 575px) {
      padding: 24px;
    }

  .imagesvector {
    padding: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }

  .note {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #adabb2;
      span {
        font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
      }
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 20px;
      span {
        color: #fff;
        font-size: 12px;
        line-height: 20px;
      }
    }
`

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  h4 {
    color: #202327;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    &.value {
      font-weight: 700;
    }
  }
  &.time {
    div {
      line-height: 29px;
    }
  }
  &.total-lock,
  &.commission {
    color: #adabb2;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 12px;
    padding: 10px 24px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }
`
const NoteDeposit = styled.span`
  // color: #adabb2;
  //background: #ffffcc;
  // max-width: 600px;
  // padding: 16px;
  // border-radius: 10px;
`
const responsiveTextSize = ['16px', '16px', '16px', '18px', '20px']
const responsiveTextSizeBNB = ['10px', '12px', '12px', '14px', '16px']

const DetailInfoPool = ({ poolInfo, poolId, ...props }) => {
  const { account, chainId } = useActiveWeb3React()
  const currentInterest = poolInfo.currentInterest
  const currentReward = poolInfo.currentReward
  const totalReward = poolInfo.totalRewardUSD
  const startTime = poolInfo.startTime
  const rateBNB2USD = poolInfo.rateBNB2USD
  const [now, setNow] = useState(moment().unix())

  const getNoteDeposit = () => {
    let note
    if (chainId === 97 && now - poolInfo.startTime > 3600) {
      note = (
        <NoteDeposit className="note">
          {/* <i> */}
          <span>Please note:</span> after <b style={{ textDecoration: 'underline' }}>{timeDisplayLong(604800)}</b> of
          deposit, you can&apos;t add more to this pool. If you would like to stake more, you can stake a different
          wallet or a different pool.
          {/* </i> */}
        </NoteDeposit>
      )
    } else if (chainId === 137 && now - poolInfo.startTime > 604800) {
      note = (
        <NoteDeposit className="note">
          {/* <i> */}
          <span>Please note:</span> after <b style={{ textDecoration: 'underline' }}>{timeDisplayLong(604800)}</b> of
          deposit, you can&apos;t add more to this pool. If you would like to stake more, you can stake a different
          wallet or a different pool.
          {/* </i> */}
        </NoteDeposit>
      )
    } else {
      note = null
    }
    return note
  }

  useEffect(() => {
    setInterval(() => {
      setNow(moment().unix())
    }, 1000)
  }, [])

  const timeDisplayLong = (seconds: number) => {
    let y = Math.floor(seconds / 31536000)
    let mo = Math.floor((seconds % 31536000) / 2628000)
    let d = Math.floor(((seconds % 31536000) % 2628000) / 86400)
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)

    let yDisplay = y > 0 ? y + (y === 1 ? ' year ' : ' years ') : ''
    let moDisplay = mo > 0 ? mo + (mo === 1 ? ' month, ' : ' months ') : ''
    let dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : ''
    let hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : ''
    let mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : ''
    let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds ') : ''
    return yDisplay + moDisplay + dDisplay + hDisplay + mDisplay + sDisplay
  }

  const timeDisplay = (seconds: number) => {
    let y = Math.floor(seconds / 31536000)
    let mo = Math.floor((seconds % 31536000) / 2628000)
    let d = Math.floor(((seconds % 31536000) % 2628000) / 86400)
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)

    let yDisplay = y > 0 ? y + (y === 1 ? ' Years ' : ' Years ') : ''
    let moDisplay = mo > 0 ? mo + (mo === 1 ? ' M, ' : ' M ') : ''
    let dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' day ') : ''
    let hDisplay = h > 0 ? (h < 10 ? '0' + h + (h === 1 ? ':' : ':') : h + (h === 1 ? ':' : ':')) : ''
    let mDisplay = m > 0 ? (m < 10 ? '0' + m + (m === 1 ? ':' : ':') : m + (m === 1 ? ':' : ':')) : ''
    let sDisplay = s > 0 ? (s < 10 ? '0' + s : s) : ''
    return yDisplay + moDisplay + dDisplay + hDisplay + mDisplay + sDisplay
  }
  const percentPools = [
    { id: 0, name: 'Small Fish', percent: 200 },
    { id: 1, name: 'Fomo', percent: 220 },
    { id: 2, name: 'Miner', percent: 230 },
    { id: 3, name: 'Medium', percent: 240 },
    { id: 4, name: 'Shark', percent: 250 },
  ]
  return (
    <InfoDetail>
      <Line>
        <Text style={{ color: '#ADABB2', fontWeight: 400 }} fontSize={responsiveTextSize}>
          Current Interest
        </Text>
        <Text style={{ color: '#E2E1E5', fontWeight: '700' }} fontSize={responsiveTextSize} className="value">
          {
            <CountUp
              start={0}
              preserveValue
              delay={0}
              end={Number((30 / 365) * currentInterest)}
              decimals={2}
              duration={0.5}
            />
          }{' '}
          % / month
        </Text>
      </Line>
      <Line>
        <Text style={{ color: '#ADABB2', fontWeight: 400 }} fontSize={responsiveTextSize}>
          Max Percent
        </Text>
        <Text
          style={{ color: '#E2E1E5', fontWeight: '700', textAlign: 'end' }}
          fontSize={responsiveTextSize}
          className="value"
        >
          <CountUp
            start={0}
            preserveValue
            delay={0}
            end={percentPools[poolId].percent}
            decimals={0}
            duration={0.5}
            separator=","
          />
          %
        </Text>
      </Line>
      <Line>
        <Text style={{ color: '#ADABB2', fontWeight: 400 }} fontSize={responsiveTextSize}>
          Spend Time
        </Text>
        <Text style={{ color: '#E2E1E5', fontWeight: '700' }} fontSize={responsiveTextSize} className="value">
          {startTime === 0 ? 0 : timeDisplay(Number(now - startTime))}
        </Text>
      </Line>
      <Line>
        <Text style={{ color: '#ADABB2', fontWeight: 400 }} fontSize={responsiveTextSize}>
          Current Reward
        </Text>
        {currentReward === 0 ? (
          <Text style={{ color: '#E2E1E5', fontWeight: '700' }} fontSize={responsiveTextSize} className="value">
            0
          </Text>
        ) : (
          <Amount>
            <Text style={{ color: '#E2E1E5' }} fontSize={responsiveTextSize} className="value">
              {'$'}
              <CountUp
                start={0}
                preserveValue
                delay={0}
                end={Number(currentReward * rateBNB2USD)}
                decimals={currentReward > 0 ? 1 : 0}
                duration={0.5}
                separator=","
              />
              &nbsp;
            </Text>
            {/* <Text
              fontSize={responsiveTextSizeBNB}
              className="value"
              style={{ display: 'flex', alignItems: 'center', gap: 6, color: '#ADABB2', fontWeight: '400' }}
            >
              ~{' '}
              <CountUp
                start={0}
                preserveValue
                delay={0}
                end={Number(currentReward)}
                decimals={totalReward > 0 ? 4 : 2}
                duration={0.5}
                separator=","
              />{' '}
              <h4 style={{ color: '#ff0420' }}>OP</h4>
            </Text> */}
          </Amount>
        )}
      </Line>
      <Line>
        <Text fontSize={responsiveTextSize} style={{ fontWeight: 400, color: '#ADABB2' }}>
          Total Reward
        </Text>
        {totalReward === 0 ? (
          <Text style={{ color: 'white', fontWeight: '700' }} fontSize={responsiveTextSize} className="value">
            0
          </Text>
        ) : (
          <Amount>
            <Text style={{ color: 'white', fontWeight: '700' }} fontSize={responsiveTextSize} className="value">
              {'$'}
              <CountUp
                style={{ color: 'white', fontWeight: '700' }}
                start={0}
                preserveValue
                delay={0}
                end={Number(totalReward)}
                decimals={totalReward > 0 ? 1 : 0}
                duration={0.5}
                separator=","
              />
              &nbsp;
            </Text>
            {/* <Text
              fontSize={responsiveTextSizeBNB}
              className="value"
              style={{ display: 'flex', alignItems: 'center', gap: 6, color: '#ADABB2', fontWeight: '400' }}
            >
              ~{' '}
              <CountUp
                start={0}
                preserveValue
                delay={0}
                end={Number(totalReward)}
                decimals={totalReward > 0 ? 4 : 0}
                duration={0.5}
                separator=","
              />{' '}
              <h4 style={{ color: '#ff0420' }}>OP</h4>
            </Text> */}
          </Amount>
        )}
      </Line>
      <Line style={{ display: 'flex', flexDirection: 'column' }} className="total-lock">
        <Text
          fontSize={responsiveTextSize}
          className="value"
          style={{ color: '#ADABB2', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}
        >
          Total Lock
        </Text>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '30px',
            color: '#E2E1E5',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {`$`}
          <CountUp
            start={0}
            preserveValue
            delay={0}
            end={poolInfo.totalLockUSD}
            decimals={poolInfo.totalLockUSD > 0 ? 1 : 0}
            duration={0.5}
            separator=","
          />
          <div
            style={{
              gap: 6,
              display: 'flex',
              alignItems: 'center',
              color: '#ADABB2',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '400',
            }}
          ></div>
        </Text>
      </Line>
      <Line></Line>
    </InfoDetail>
  )
}
export default DetailInfoPool

import styled from 'styled-components'
import images from 'configs/images'
import Avatar from 'components/Avatar'
import Banner from 'components/Banner'
import CircleLoader from 'components/Loader/CircleLoader'

const EditOverlay = styled.div`
  background: rgba(0, 0, 0, 0.6) url('/images/icons/camera.svg') no-repeat center center;
  border-radius: 50%;
  left: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 300ms;
  width: 100%;
  z-index: 1;
`

const StyledEditProfileEditBanner = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;

    ${EditOverlay} {
      opacity: 1;
    }
  }
`
const StyledLoading = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EditBanner: React.FC<React.PropsWithChildren<{ src: string; alt?: string; loading: boolean; onSuccess: () => void }>> = ({
  src,
  alt,
  loading,
  onSuccess,
}) => {
  return (
    <StyledEditProfileEditBanner>
      {loading ? (
        <StyledLoading>
          <CircleLoader size='32px' />
        </StyledLoading>
      ) : (
        <>
          <Banner data={src} />
          <EditOverlay style={{ width: "50px", height: "50px"}} />
        </>
      )}
    </StyledEditProfileEditBanner>
  )
}

export default EditBanner

import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Flex, Text, useModal, useToast } from '@pancakeswap/uikit'
import Page, { PageMeta } from 'components/Layout/Page'
import ProfileHeader from './components/ProfileHeader'
import {
  StyledAction,
  StyledPost,
  Wrapper,
  BtnPost,
  UserInfo,
  Left,
  Right,
  More,
  Info,
  ListPost,
  TitleListPost,
  TitlePost,
  StyledWrapper,
} from './styled'
import { useTranslation } from '@pancakeswap/localization'
import { PostStatusModal } from 'components/Post/PostStatusModal/PostStatusModal'
import Post from 'components/Post/ListPost'
import Avatar from 'components/Avatar'
import AuthorInfo from './components/AuthorInfo'
import images from 'configs/images'
import postsApi from 'api/postApi'
import usersApi from 'api/usersApi'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { UserContext } from 'astore/UserContext'
import { useLocation } from 'react-router-dom'
import SideBar from 'components/SideBar'
import { SEOPage } from 'components/SEOPage'

interface UserInfo {
  address: string
  avatar: string
  followers: number
  following: number
  genderId: number
  name: string
  role: string
  topFriends: string[]
  totalFriends: number
  __v: number
  _id: string
}

const Profile: FC<React.PropsWithChildren> = ({ children }) => {
  const { userData } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const { account } = useActiveWeb3React()
  const [idUser, setIdUser] = useState(null)
  const { t } = useTranslation()
  const location = useLocation()
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const [listDataPrivate, setListDataPrivate] = React.useState([])
  const [pageOrder, setPageOrder] = React.useState(1)

  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'profile')

  useEffect(() => {
    if (location.search) {
      setIsLoading(true)
      const urlParams = new URLSearchParams(location.search)
      const userIdQuery = urlParams.get('id')
      if (userIdQuery !== idUser) {
        setIdUser(userIdQuery)
        setListDataPrivate([])
        setPageOrder(1)
      }
    }
  }, [location.search, idUser])

  const fetchUserInfo = async () => {
    setIsLoading(true)
    if (idUser) {
      try {
        const response = await usersApi.getId(idUser)
        setUserInfo(response.data)
      } catch (error) {
        console.log('Failed to fetch userInfo: ', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const fetchListPost = async () => {
    setIsLoading(true)
    if (idUser) {
      try {
        const params = {
          authorId: idUser,
          pageSize: 21,
          pageIndex: pageOrder,
        }
        const response = await postsApi.get(params)
        setListDataPrivate(listDataPrivate.concat(response.data))
      } catch (error) {
        console.log('Failed to fetch list posts: ', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchListPost()
  }, [pageOrder])

  useEffect(() => {
    if (idUser) {
      fetchUserInfo()
      fetchListPost()
    }
  }, [idUser])

  const [openPostStatusModal, onDismissPostStatusModal] = useModal(
    <PostStatusModal
      userData={userData}
      listDataPost={listDataPrivate}
      setListDataPost={setListDataPrivate}
      onDismiss={() => onDismissPostStatusModal}
    />,
    true,
    false,
    'removeModalPostStatusModal',
  )

  return (
    <StyledWrapper>
      <PageMeta meta={findMeta} />
      <SideBar />
      <Wrapper>
        <div style={{ width: '100%', background: '#fff' }}>
          <ProfileHeader
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            accountPath={null}
            profile={null}
            achievements={null}
            nftCollected={null}
            isAchievementsLoading={false}
            isLoading={isLoading}
            isProfileLoading={false}
          />
          <StyledAction>
            <Left></Left>
            <Right>
              <More>
                <img src={images.morefill} alt="fill" />
              </More>
            </Right>
          </StyledAction>
        </div>
        <StyledPost>
          <AuthorInfo userInfo={userInfo} />
          <ListPost>
            <Info>
              {userInfo && userInfo._id === userData?.userId ? (
                <>
                  <div style={{ width: '44px', height: '44px' }}>
                    <Avatar data={userInfo?.avatar} />
                  </div>
                  <BtnPost onClick={openPostStatusModal}>What&rsquo;s on your mind ?</BtnPost>
                </>
              ) : (
                <>{userInfo && userInfo._id !== userData?.userId && <TitlePost>Posts</TitlePost>}</>
              )}
            </Info>
            <TitleListPost>List Posts</TitleListPost>
            <Post
              userData={userData}
              listDataPost={listDataPrivate}
              setListDataPost={setListDataPrivate}
              pageOrder={pageOrder}
              setPageOrder={setPageOrder}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            {listDataPrivate.length <= 0 && !account && (
              <Page style={{ minHeight: 'auto' }}>
                <Flex p="24px" flexDirection="column" alignItems="center">
                  <Avatar data={userInfo?.avatar} width={64} height={64} />
                  <Text textAlign="center" maxWidth="420px" pt="8px" bold>
                    {!account ? (
                      <>{t('Please enter a valid address, or connect your wallet to view')}</>
                    ) : (
                      <>{t('Please share interesting things with everyone')}</>
                    )}
                  </Text>
                </Flex>
              </Page>
            )}
          </ListPost>
        </StyledPost>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Profile

const images = {
  user: '/images/homepage/Avatar.jpg',
  connect: '/images/homepage/connect.png',
  home: '/images/homepage/home.svg',
  Signal: '/images/homepage/Signal.svg',
  News: '/images/homepage/News.svg',
  Ranking: '/images/homepage/Ranking.svg',
  Wallet: '/images/homepage/Wallet.svg',
  Friends: '/images/homepage/Friends.svg',
  Message: '/images/homepage/Message.svg',
  iconPool: '/images/homepage/pools.svg',
  iconReferral: '/images/homepage/iconreferral.svg',
  iconOPSF: '/images/homepage/iconopsf.svg',
  iconTokenomic: '/images/homepage/tokenomic.svg',
  dot: '/images/homepage/dot.svg',
  wallet: '/images/homepage/Message.png',
  star: '/images/homepage/star.png',
  logo: '../images/logo/logo-opsocialfi.svg',
  mask: '../images/Maskgroup.png',
  btnlogout: '/images/homepage/btnlogout.svg',
  // news
  avatarNone: '/images/homepage/avatar_none1.png',
  avatar_none: '/images/homepage/avatar_none1.png',

  closeline: '/images/icons/close-line.svg',
  iconshare: '/images/icons/iconshare.svg',
  iconL: '/images/icons/iconL.png',
  iconR: '/images/icons/iconR.svg',
  iconlike: '/images/icons/iconlike.svg',
  morefill: '/images/icons/more-fill.svg',
  iconcomment: '/images/icons/iconcomment.svg',
  send: '/images/icons/send.svg',
  loadinggif: '/images/loading/loadingcircle.gif',
  viewmore: '/images/icons/viewmore.svg',
  notification: '/images/icons/notification.svg',
  down: '/images/icons/down.svg',
  viewsmorereply: '/images/icons/views-more-reply.svg',
  iconusername: '/images/icons/iconusername.png',
  venus: '/images/icons/venus.png',
  following: '/images/icons/following.svg',
  followers: '/images/icons/followers.svg',
  icon_search: '/images/icons/icon_search.svg',
  video_call: '/images/icons/video_call.png',
  un_video_call: '/images/icons/un_video_call.png',
  audio_call: '/images/icons/audio_call.png',
  face_smile: '/images/icons/face-smile.svg',
  message: '/images/icons/message.svg',
  message_white: '/images/icons/message_white.svg',
  video_solid: '/images/icons/video_solid.svg',
  phone_solid: '/images/icons/phone_solid.svg',
  stars: '/images/icons/star.svg',
  wallet_icon: '/images/icons/wallet_icon.png',
  walletiwhite: '/images/icons/walletiwhite.svg',
  addfriend: '/images/icons/add-friend.svg',
  unfriend: '/images/icons/un-friend.svg',
  acceptedfriend: '/images/icons/accepted-friend.svg',
  shareicon: '/images/icons/share-icon.svg',
  commentsicon: '/images/icons/comments-icon.svg',
  likeicon: '/images/icons/like-icon.svg',
  public: '/images/icons/public.svg',
  only_me: '/images/icons/only-me.svg',
  friends: '/images/icons/friends.svg',
  calendar: '/images/icons/calendar-icon.svg',
  birthday: '/images/icons/birthday.svg',
  circleuser: '/images/icons/circle-user.svg',
  email: '/images/icons/email.svg',
  phoneicon: '/images/icons/phone-icon.svg',
  edit: '/images/icons/edit.svg',
  feedback: '/images/icons/feedback.svg',
  nobanner: '/images/profile/no-team-banner.png',
  arrowleft: '/images/icons/arrow-left.svg',
  iconMoney: '/images/icons/money.png',
  editstatus: '/images/icons/editstatus.svg',
  trash: '/images/icons/trash.svg',
  dayccc: '/images/icons/dayccc.svg',
  copy: '/images/icons/copy.svg',
  close: '/images/icons/close.svg',
  logotelegram: '/images/icons/logotelegram.svg',
  copylink: '/images/icons/copylink.svg',
  logotwitter: '/images/icons/logotwitter.svg',
  logoshare: '/images/icons/logoshare.svg',
  pricedow: '/images/icons/pricedow.svg',
  priceup: '/images/icons/priceup.svg',


  supplyopfsnew: '/images/tokenomic/supplyopfsnew.svg',
  ratio1: '/images/tokenomic/ratio1.svg',
  ratio2: '/images/tokenomic/ratio2.svg',
  ratio3: '/images/tokenomic/ratio3.svg',
  ratio4: '/images/tokenomic/ratio4.svg',
  ratio5: '/images/tokenomic/ratio5.svg',
  ratio6: '/images/tokenomic/ratio6.svg',
  ratio7: '/images/tokenomic/ratio7.svg',
  ratio8: '/images/tokenomic/ratio8.svg',
  ratio9: '/images/tokenomic/ratio9.svg',
  iconpologon: '/images/tokenomic/iconpologon.svg',
  illus: '/images/tokenomic/illus.jpg',
  mineop: '/images/tokenomic/mineop.svg',
  earnop: '/images/tokenomic/earnop.svg',
  engageop: '/images/tokenomic/engageop.svg',
  imgrewards: '/images/tokenomic/imgrewards.svg',
  platinum: '/images/tokenomic/platinum.svg',
  titanium: '/images/tokenomic/titanium.svg',
  gold: '/images/tokenomic/gold.svg',
  silver: '/images/tokenomic/silver.svg',
  bronze: '/images/tokenomic/bronze.svg',
  minetime: '/images/tokenomic/minetime.svg',
  polygonred: '/images/tokenomic/polygonred.svg',
  iconmine: '/images/tokenomic/iconmine.svg',
  iconBack: '/images/icons/iconBack.png',

  success: '/images/loading/success.svg',
  error: '/images/loading/error.svg',
  danger: '/images/loading/danger.svg',

  sharerefe: '/images/referral/share.svg',
  copyrefe: '/images/referral/copy.svg',
  panelrefe: '/images/referral/panelrefe.jpg',
  opconnect: '/images/referral/opconnect.png',
  yourrefe: '/images/referral/your.svg',
  boxrefe: '/images/referral/box.svg',

  speedometer: '/images/mining/speedometer.png',
  group: '/images/mining/group.png',
  logomining: '/images/mining/logo.png',
  logostyle: '/images/mining/logostyle.png',
  imgyourspeed: '/images/mining/iconyourspeed.svg',
  framegroup: '/images/mining/framegroup.svg',
  iconsystem: '/images/mining/iconsystem.svg',
  iconyourwallet: '/images/mining/iconyourwallet.svg',
  dollar: '/images/mining/dollar.png',
  iconUSDT: '/images/mining/iconUSDT.svg',


}

export default images

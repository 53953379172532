import { ModalPostDetail, useModal, useToast } from '@pancakeswap/uikit'
import React, { useEffect, useState } from 'react'
import images from 'configs/images'
import Tippy from '@tippyjs/react/headless'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import { StyledImages } from 'components/StyledImages'
import {
  Body,
  Content,
  Headers,
  InteractAction,
  InteractCurrent,
  ItemAction,
  StyledDayTime,
  StyledShares,
  UserInfor,
  Wrapper,
  Container,
  CardAction,
  StyledIcon,
  ModalTippyItem,
  StyledLine,
  ModalTippy,
  StyledComment,
  InputComment,
  Input,
  BtnSend,
  Shared,
} from './styled'
import postsApi from 'api/postApi'
import IsIcon from 'components/IsIcon'
import CommentsPost from './components/Comments'
import StyledAvatar from 'components/StyledAvatar'
import StyledUserName from 'components/StyledUserName'
import DayTimeDifference from 'components/DayTimeDifference'
import { PostShare } from '../ShareDetail'
import { useTranslation } from '@pancakeswap/localization'
import { EditStatusModal } from '../EditStatusModal/EditStatusModal'
import Avatar from 'components/Avatar'
import Skeleton from 'react-loading-skeleton'

const status = [
  {
    title: 'everyone',
    icon: images.public,
  },
  {
    title: 'onlyMe',
    icon: images.only_me,
  },
  {
    title: 'friends',
    icon: images.friends,
  },
]

export const PostDetail = ({ data, userData, listDataPost, setListDataPost, inputRef, onDismiss }) => {
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [loading, setLoading] = useState(true)
  const [listComments, setListComments] = React.useState([])
  const [dataPostItem, setDataPostItem] = useState(data)
  const [getInput, setGetInput] = useState(false)
  const [inputComment, setInputComment] = useState('')
  const [pageOrder, setPageOrder] = useState(1)
  const pageSize = 3
  const pageIndex = pageOrder
  const desc = 'desc'

  const fetchListComment = async () => {
    if (data) {
      try {
        setLoading(true)
        const params = {
          postId: data._id,
          pageSize: pageSize,
          pageIndex: pageIndex,
          sort: desc,
        }
        const response = await postsApi.comment(params)
        setListComments(listComments.concat(response.data))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('Failed to fetch list posts: ', error)
      }
    }
  }

  const likePost = async (postId) => {
    if (!account) {
      onPresentConnectModal()
      onDismiss()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      if (postId && account) {
        const response = await postsApi.likepost(postId)
        const updatedListData = listDataPost.map((post) => {
          if (post._id === postId) {
            if (post.likedUsers.includes(userData.userId)) {
              post.likedUsers = post.likedUsers.filter((e) => e !== userData.userId)
            } else {
              post.likedUsers.push(userData.userId)
            }
          }
          return post
        })
        if (response.data.likedUsers.includes(userData.userId)) {
          response.data.likedUsers = response.data.likedUsers.filter((e) => e !== userData.userId)
        } else {
          response.data.likedUsers.push(userData.userId)
        }
        setDataPostItem(response.data)
        setListDataPost(updatedListData)
      }
    } catch (error) {
      console.log('Failed to fetch list posts: ', error)
    }
  }

  const postComment = async (_idreply, _content, isTags, parentIdx) => {
    if (!account) {
      onDismiss()
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    if (account) {
      try {
        const payload = {
          postId: data._id,
          content: _content,
          parentId: _idreply,
          tags: isTags,
        }
        const response = await postsApi.postcomment(payload)
        if (_idreply) {
          const parentInx = parentIdx
          const newChildren = { ...listComments[parentInx] }
          newChildren.replies.push(response.data)
          listComments.splice(parentInx, 1, newChildren)
          const newCmts = [...listComments]
          setListComments(newCmts)
          setGetInput(false)
        } else {
          listComments.push(response.data)
          const newCmts = [...listComments]
          setListComments(newCmts)
          setGetInput(false)
        }

        const updatedListData = listDataPost.map((post) => {
          if (post._id === response.data.postId) {
            post.totalComments++
          }
          return post
        })
        setListDataPost(updatedListData)
      } catch (error) {
        console.log('Failed to fetch list posts: ', error)
      }
    }
  }

  const handledCommentClick = async () => {
    const idReply = ''
    const istags = []
    const ix = 0
    postComment(idReply, inputComment, istags, ix)
    setInputComment('')
  }

  const handledCommentKey = async (event) => {
    if (event.key === 'Enter') {
      const idReply = ''
      const istags = []
      const ix = 0
      postComment(idReply, inputComment, istags, ix)
      setInputComment('')
    }
  }

  const handleButtonClick = () => {
    inputRef.current.focus()
  }

  const handledOpenModalShare = () => {
    onDismiss()
    openSharePostModal()
  }

  const handleOpenModalEdit = () => {
    onDismiss()
    openEditPostModal()
  }

  const deletePost = async (postId) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      if (postId && account) {
        const response = await postsApi.deletepost(postId)
        const indexToDelete = listDataPost.findIndex((post) => post._id === postId)
        if (indexToDelete !== -1) {
          listDataPost.splice(indexToDelete, 1)
        }
        toastSuccess(t('Success'), t('Move to trash Success'))
      }
    } catch (error) {
      toastError(t('Error'), t('Please try again'))
      console.log('Failed to fetch userInfo: ', error)
    }
  }

  useEffect(() => {
    fetchListComment()
  }, [pageOrder])

  const [openSharePostModal, onDismissSharePostModal] = useModal(
    <PostShare
      userData={userData}
      dataShare={data}
      listDataPost={listDataPost}
      setListDataPost={setListDataPost}
      onDismiss={() => {
        onDismissSharePostModal()
      }}
    />,
    false,
  )

  const [openEditPostModal, onDismissEditPostModal] = useModal(
    <EditStatusModal
      userData={userData}
      dataEdit={data}
      listDataPost={listDataPost}
      setListDataPost={setListDataPost}
      onDismiss={() => onDismissEditPostModal()}
    />,
    false,
  )

  return (
    <ModalPostDetail
      title={`Article by ${
        data.authorInfo.name.length > 40
          ? `  ${data.authorInfo.name.substring(0, 5)}...${data.authorInfo.name.substring(
              data.authorInfo.name.length - 5,
            )}`
          : data.authorInfo.name
      } `}
      color="#fff"
      onDismiss={onDismiss}
    >
      {data && (
        <>
          <Wrapper>
            <Container>
              <Headers>
                <UserInfor>
                  <StyledAvatar
                    link={`/profile?id=${data.authorInfo._id}`}
                    data={data.authorInfo.avatar}
                    w={44}
                    h={44}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <StyledUserName link={`/profile?id=${data.authorInfo._id}`} data={data.authorInfo.name} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '4px',
                      }}
                    >
                      <StyledDayTime>
                        <DayTimeDifference At={data.createdAt} />
                      </StyledDayTime>
                      <IsIcon w={14} h={14} src={status.find((st) => st.title === data.audience)?.icon} curs={true} />
                    </div>
                  </div>
                </UserInfor>
                <StyledIcon>
                  <Tippy
                    interactive
                    placement={'bottom-end'}
                    trigger="click"
                    render={(attrs) => (
                      <>
                        {data.authorInfo._id === userData.userId ? (
                          <ModalTippy>
                            <ModalTippyItem onClick={handleOpenModalEdit}>
                              <IsIcon w={18} h={18} src={images.editstatus} curs={true} />
                              Edit article
                            </ModalTippyItem>
                            <StyledLine />
                            <ModalTippyItem onClick={() => deletePost(data._id)}>
                              <IsIcon w={18} h={18} src={images.trash} curs={true} />
                              Move to trash
                            </ModalTippyItem>
                          </ModalTippy>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  >
                    <img
                      style={{ width: '21px', height: '21px', cursor: 'pointer' }}
                      src={images.morefill}
                      alt="icon"
                    />
                  </Tippy>
                </StyledIcon>
              </Headers>
              <Body>
                <Content>{data.content}</Content>
                {data.isShared === false ? (
                  <>
                    {data.images.length > 0 && (
                      <div style={{ width: '100%', height: '100%' }}>
                        <StyledImages data={data.images} setData={null} />
                      </div>
                    )}
                  </>
                ) : (
                  <Shared>
                    <StyledShares>
                      {data.sharedInfo?.images.length > 0 && (
                        <div style={{ width: '100%', height: '100%' }}>
                          <StyledImages data={data.sharedInfo.images} setData={null} />
                        </div>
                      )}
                      <Headers>
                        <UserInfor>
                          <StyledAvatar
                            link={`/profile?id=${data.sharedInfo?.authorInfo._id}`}
                            data={data.sharedInfo?.authorInfo.avatar}
                            w={44}
                            h={44}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledUserName
                              link={`/profile?id=${data.sharedInfo?.authorInfo._id}`}
                              data={data.sharedInfo?.authorInfo.name}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '4px',
                              }}
                            >
                              <StyledDayTime>
                                <DayTimeDifference At={data.sharedInfo?.createdAt} />
                              </StyledDayTime>
                              <IsIcon
                                w={14}
                                h={14}
                                src={status.find((st) => st.title === data.sharedInfo?.audience)?.icon}
                                curs={true}
                              />
                            </div>
                          </div>
                        </UserInfor>
                      </Headers>
                      <Content>{data.sharedInfo?.content}</Content>
                    </StyledShares>
                  </Shared>
                )}
                <CardAction>
                  {(data.totalComments > 0 || data.likedUsers.length > 0) && (
                    <InteractCurrent>
                      {data.likedUsers.length > 0 ? (
                        <div className="like">
                          <IsIcon src={images.iconL} />
                          <span>{data.likedUsers.length}</span>
                        </div>
                      ) : (
                        <div />
                      )}
                      {data.totalComments > 0 && (
                        <div className="comment">
                          <span>{data.totalComments}</span>
                          <span>{data.totalComments <= 2 ? 'Comment' : 'Comments'}</span>
                        </div>
                      )}
                    </InteractCurrent>
                  )}
                  <InteractAction>
                    {!userData ? (
                      <ItemAction onClick={() => likePost(data._id)}>
                        <IsIcon src={images.iconlike} curs={true} />
                        <span>Like</span>
                      </ItemAction>
                    ) : (
                      <ItemAction onClick={() => likePost(data._id)}>
                        {data.likedUsers.includes(userData.userId) ? (
                          <IsIcon src={images.iconL} curs={true} />
                        ) : (
                          <IsIcon src={images.iconlike} curs={true} />
                        )}
                        <span>Like</span>
                      </ItemAction>
                    )}
                    <ItemAction onClick={handleButtonClick}>
                      <IsIcon src={images.commentsicon} curs={true} />
                      <span>Comment</span>
                    </ItemAction>
                    {data.authorInfo._id !== userData.userId && (
                      <ItemAction onClick={() => handledOpenModalShare()}>
                        <IsIcon src={images.shareicon} curs={true} />
                        <span>Share</span>
                      </ItemAction>
                    )}
                  </InteractAction>
                </CardAction>
              </Body>
              {listComments.length > 0 && (
                <CommentsPost
                  data={data}
                  userData={userData}
                  listComments={listComments}
                  setListComments={setListComments}
                  listDataPost={listDataPost}
                  setListDataPost={setListDataPost}
                  inputRef={inputRef}
                  pageOrder={pageOrder}
                  setPageOrder={setPageOrder}
                  loading={loading}
                  setLoading={setLoading}
                  onDismiss
                />
              )}
              {/* {loading && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', padding: '16px' }}>
                  <CommentItem>
                    <Skeleton width={28} height={28} borderRadius="50%" />
                    <StyledContent>
                      <Skeleton width={200} height={42} borderRadius="18px" />
                    </StyledContent>
                  </CommentItem>
                  <CommentItem>
                    <Skeleton width={28} height={28} borderRadius="50%" />
                    <StyledContent>
                      <Skeleton width={240} height={42} borderRadius="18px" />
                    </StyledContent>
                  </CommentItem>
                  <CommentItem>
                    <Skeleton width={28} height={28} borderRadius="50%" />
                    <StyledContent>
                      <Skeleton width={250} height={70} borderRadius="18px" />
                    </StyledContent>
                  </CommentItem>
                  <CommentItem>
                    <Skeleton width={28} height={28} borderRadius="50%" />
                    <StyledContent>
                      <Skeleton width={300} height={60} borderRadius="18px" />
                    </StyledContent>
                  </CommentItem>
                </div>
              )} */}
            </Container>
          </Wrapper>
          {listComments?.length > 0 && <div style={{ width: '100%', borderBottom: '1px solid #ccc' }} />}
          <StyledComment>
            <Avatar data={userData?.avatar} width={32} height={32} />
            <InputComment>
              <Input
                value={inputComment}
                ref={inputRef}
                onChange={(e) => setInputComment(e.target.value)}
                onKeyUp={handledCommentKey}
                placeholder="Share your though...."
              />
              {inputComment.length > 0 && <BtnSend onClick={handledCommentClick} src={images.send} alt="send" />}
            </InputComment>
          </StyledComment>
        </>
      )}
    </ModalPostDetail>
  )
}

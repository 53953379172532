import { formatEther, formatUnits } from '@ethersproject/units'
import { useMatchBreakpoints, useModal } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWallet } from 'hooks/useWallet'
import images from 'configs/images'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import ClaimPoolModal from './components/ClaimModal'
import SendTrendModal from './components/sendModal'
import TableDataPool from './components/yourMineHistory'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import contracts from 'config/constants/contracts'
import poolsMineAbi from 'config/abi/poolsMine.json'
import OPSFAbi from 'config/abi/tokenOPSF.json'
import {
  Container,
  ContentText,
  IntroWapper,
  IntrotText,
  Item,
  ItemMinner,
  MinnerItem,
  StyledBtn,
  StyledGroup,
  StyledHeader,
  StyledImgYourSpeeed,
  StyledSystem,
  StyledTextValue,
  StyledTextYourSpeed,
  StyledTilde,
  StyledValue,
  StyledYourWallet,
  TableMine,
  Title,
  TitleContainer,
  TitleItem,
  Wrapper,
  YourMinedTitle,
  YourWalletItem,
  YourWalletListItem,
  YourWalletTitle,
} from './styled'
import { PageMeta } from 'components/Layout/Page'
import { SEOPage } from 'components/SEOPage'

function Mining() {
  let { account, chainId } = useActiveWeb3React()
  // account = '0x3F4c57deC6f3FB1578af7fd927D05A98999a7057'
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { data: signer } = useSigner()
  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'ops')

  const poolCT = getContract({
    address: contracts.poolsMine[CHAIN_ID],
    abi: poolsMineAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const OPSFCT = getContract({
    address: contracts.tokenOPSF[CHAIN_ID],
    abi: OPSFAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { onPresentConnectModal } = useWallet()
  const [usersClaimed, setUserClaimed] = useState([])
  const [claimDisable, setClaimDisable] = useState(false)
  const [sendDisable, setSendDisable] = useState(false)
  const [available, setAvailable] = useState({
    total: 0,
    show: 0,
  })

  const [mineData, setMineData] = useState({
    totalMined: 0,
    claimed: 0,
    remain: 0,
    mineSpeed: 0,
    mineSpeedLevel: 0,
    startTime: 0,
    userClaimedMineLength: 0,
    currentReward: 0,
    OPSF2USDT: 0,
    balanceTrend: 0,
  })

  const [systemData, setSystemData] = useState({
    totalMiner: 0,
    defaultTrend: 0,
    totalMined: 0,
    totalClaimed: 0,
  })

  const initAvailable = async () => {
    const currentRewardOPSF = await poolCT.currentRewardOPS(account)
    const total = Number(formatUnits(currentRewardOPSF, 8))
    const avai = {
      total,
      show: total - total / 2880,
    }
    // if (available.total === 0) updateAvailable(avai)
    setAvailable(avai)
  }

  const getAvailable = () => {
    if (!account) {
      setAvailable({
        total: 0,
        show: 0,
      })
    } else {
      initAvailable()
      setTimeout(async () => {
        initAvailable()
      }, 120000)
    }
  }

  // const updateAvailable = (avai) => {
  //   const newAvai = {
  //     ...avai,
  //     show: avai.show + ((avai.total - avai.show) * 10) / 100,
  //   }
  //   setTimeout(async () => {
  //     if (avai.show > 0) await setAvailable(newAvai)
  //     updateAvailable(newAvai)
  //   }, 3000)
  // }

  useEffect(() => {
    getMine()
    getMineSystem()
  }, [account])

  const getMine = async () => {
    try {
      if (!account) {
        setIsLoading(true)
      } else {
        const getUsersClaimMinedLength = await poolCT.getUsersClaimMinedLength(account)
        const users = await poolCT.usersMine(account)
        const currentRewardOPSF = await poolCT.currentRewardOPS(account)

        if (Number(formatUnits(currentRewardOPSF, 8)) === 0) {
          setClaimDisable(true)
        } else {
          setClaimDisable(false)
        }

        await getAvailable()
        const OPSF2USDT = await poolCT.OPS2USDT()
        const balance = await OPSFCT.balanceOf(account)
        const balanceAccount = Number(formatUnits(balance, 8))
        if (balanceAccount === 0) {
          setSendDisable(true)
        } else {
          setSendDisable(false)
        }
        setMineData({
          totalMined: Number(formatUnits(users.totalMined, 8)),
          claimed: Number(users.claimed),
          remain: Number(formatUnits(users.remain, 8)),
          mineSpeed: Number(users.mineSpeed),
          mineSpeedLevel: 0,
          startTime: Number(users.startTime),
          userClaimedMineLength: Number(getUsersClaimMinedLength),
          currentReward: Number(formatUnits(currentRewardOPSF, 8)),
          OPSF2USDT: Number(formatEther(OPSF2USDT)),
          balanceTrend: balanceAccount,
        })
        await getMineHistory(getUsersClaimMinedLength)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getMineSystem = async () => {
    if (!account) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
      const totalMiner = await poolCT.totalMiner()
      const totalMined = await poolCT.totalMined()
      const totalClaimed = await poolCT.totalClaimed()
      const OPSF2USDT = await poolCT.OPS2USDT()
      setSystemData({
        totalMiner: Number(totalMiner),
        defaultTrend: Number(formatEther(OPSF2USDT)),
        totalMined: Number(formatEther(totalMined)),
        totalClaimed: Number(formatEther(totalClaimed)),
      })
    }
  }

  const getMineHistory = async (getUsersClaimMinedLength) => {
    try {
      if (!account) {
        setIsLoading(true)
      } else {
        setIsLoading(false)

        if (Number(getUsersClaimMinedLength) > 0) {
          const currenReward = await poolCT.currentRewardOPS(account)
          const currentRewardOPS = currenReward.toString()
          const OPS2USDT = await poolCT.OPS2USDT()
          await poolCT.getUsersClaimMined(account, 10, 0).then((res) => {
            const arr = res.list.map((claimed: any, i: number) => {
              return {
                date: Number(claimed.date.toString()),
                amount: Number(formatUnits(claimed.amount.toString(), 8)),
                totalLock: Number(formatUnits(claimed.totalLock.toString(), 8)),
                power: Number(claimed.interrest.toString()) / 100,
                currentReward: Number(formatUnits(currentRewardOPS, 8)),
                rateUSD: Number(formatEther(OPS2USDT)),
              }
            })

            setUserClaimed(arr)
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSuccess = () => {
    getMine()
  }

  const handleClick = () => {
    if (!account) {
      onPresentConnectModal()
    } else {
      openClaimModal()
    }
  }
  const handleSend = () => {
    if (!account) {
      onPresentConnectModal()
    } else {
      openSendModal()
    }
  }

  const [openClaimModal, onDismissModal] = useModal(
    <ClaimPoolModal
      onDismiss={() => onDismissModal()}
      onSuccess={() => handleSuccess()}
      getMine={() => getMine()}
      mine={mineData}
    />,
    true,
    false,
    'removeModal',
  )

  const [openSendModal, onDismissSendModal] = useModal(
    <SendTrendModal onDismiss={() => onDismissSendModal} mine={mineData} />,
    true,
    false,
    'removeModal',
  )

  return (
    <>
      <PageMeta meta={findMeta} />
      <Wrapper>
        <Container>
          <IntroWapper>
            <StyledHeader>
              <Title>Mining OPS</Title>
              <IntrotText>
                Mining on the blockchain ensures the validation of transactions, enhances network security, and upholds
                decentralized integrity.
              </IntrotText>
            </StyledHeader>
            <StyledImgYourSpeeed>
              <img style={{ width: '172px', height: '172px' }} src={images.imgyourspeed} alt="img" />
            </StyledImgYourSpeeed>
          </IntroWapper>
          <StyledGroup>
            <StyledYourWallet>
              <YourWalletTitle>
                <img src={images.iconyourwallet} alt="icon" />
                <TitleContainer>Your Wallet</TitleContainer>
              </YourWalletTitle>
              <YourWalletListItem>
                <YourWalletItem>
                  <TitleItem>Remained OPS (Locking)</TitleItem>
                  <Item>
                    <StyledValue>
                      <img style={{ width: '24px', height: '24px' }} src={images.logomining} alt="icon" />
                      {!account ? (
                        <ContentText>0 OPS</ContentText>
                      ) : (
                        <StyledTextValue>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={mineData.totalMined}
                            decimals={mineData.totalMined > 0 ? 3 : 0}
                            duration={0.5}
                            separator=","
                          />
                          OPS
                        </StyledTextValue>
                      )}
                    </StyledValue>
                    <StyledTilde>~</StyledTilde>
                    <StyledValue>
                      <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                      {!account ? (
                        <ContentText>0</ContentText>
                      ) : (
                        <StyledTextValue>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={mineData.totalMined * mineData.OPSF2USDT}
                            decimals={mineData.totalMined > 0 ? 3 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </StyledTextValue>
                      )}
                    </StyledValue>
                  </Item>
                </YourWalletItem>
                <YourWalletItem>
                  <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <TitleItem style={{ fontSize: '18px', fontWeight: '600' }}>Total Claimed</TitleItem>
                  </div>
                </YourWalletItem>
                <YourWalletItem>
                  <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <TitleItem>Available OPS</TitleItem>
                  </div>
                  <Item>
                    <StyledValue>
                      <img style={{ width: '24px', height: '24px' }} src={images.logomining} alt="icon" />
                      {!account ? (
                        <ContentText>0 OPS</ContentText>
                      ) : (
                        <StyledTextValue>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={available.total}
                            decimals={available.total > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                          OPS
                        </StyledTextValue>
                      )}
                    </StyledValue>
                    <StyledTilde>~</StyledTilde>
                    <StyledValue style={{ position: 'relative' }}>
                      <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                      {!account ? (
                        <ContentText>0</ContentText>
                      ) : (
                        <StyledTextValue>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={available.show * mineData.OPSF2USDT}
                            decimals={available.show > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                          <StyledBtn onClick={handleClick} aria-disabled={claimDisable} disabled={available.show <= 0}>
                            CLAIM
                          </StyledBtn>
                        </StyledTextValue>
                      )}
                    </StyledValue>
                  </Item>
                </YourWalletItem>
              </YourWalletListItem>
            </StyledYourWallet>
            {/* <StyledSystem>
            <YourWalletTitle>
              <img src={images.iconsystem} alt="icon" />
              <TitleContainer>System</TitleContainer>
            </YourWalletTitle>
            <YourWalletListItem>
              <MinnerItem>
                <TitleItem>Minner</TitleItem>
                <ItemMinner>
                  <img src={images.framegroup} alt="icon" />
                  <p>
                    {Number(systemData.totalMiner)} <span>Minner</span>
                  </p>
                </ItemMinner>
              </MinnerItem>
              <YourWalletItem>
                <TitleItem>Total Mined</TitleItem>
                <Item>
                  <StyledValue>
                    <img style={{ width: '24px', height: '24px' }} src={images.logomining} alt="icon" />
                    <StyledTextValue>
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={Number(systemData.totalMined)}
                        decimals={Number(systemData.totalMined) > 0 ? 3 : 0}
                        duration={0.5}
                        separator=","
                      />
                      OPS
                    </StyledTextValue>
                  </StyledValue>
                  <StyledTilde>~</StyledTilde>
                  <StyledValue>
                    <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                    <StyledTextValue>
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={Number(systemData.totalMined) * systemData.defaultTrend}
                        decimals={Number(systemData.totalMined) > 0 ? 3 : 0}
                        duration={0.5}
                        separator=","
                      />
                    </StyledTextValue>
                  </StyledValue>
                </Item>
              </YourWalletItem>
              <YourWalletItem>
                <TitleItem>Total Claimed</TitleItem>
                <Item>
                  <StyledValue>
                    <img style={{ width: '24px', height: '24px' }} src={images.logomining} alt="icon" />
                    <StyledTextValue>
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={Number(systemData.totalClaimed)}
                        decimals={Number(systemData.totalClaimed) > 0 ? 3 : 0}
                        duration={0.5}
                      />
                      OPS
                    </StyledTextValue>
                  </StyledValue>
                  <StyledTilde>~</StyledTilde>
                  <StyledValue>
                    <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                    <StyledTextValue>
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={Number(systemData.totalClaimed) * systemData.defaultTrend}
                        decimals={Number(systemData.totalClaimed) > 0 ? 3 : 0}
                        duration={0.5}
                      />
                    </StyledTextValue>
                  </StyledValue>
                </Item>
              </YourWalletItem>
            </YourWalletListItem>
          </StyledSystem> */}
          </StyledGroup>
        </Container>
        {account && <YourMinedTitle>Your Mined History</YourMinedTitle>}
        {!account ? null : (
          <TableMine style={{ fontFamily: 'Inter, sans-serif !important', color: '#000' }}>
            <TableDataPool
              account={account}
              mine={mineData}
              userClaimedMineLength={mineData.userClaimedMineLength}
              mineHistory={usersClaimed}
            />
          </TableMine>
        )}
      </Wrapper>
    </>
  )
}

export default Mining

import React from 'react'
import UserName from 'components/UserName'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledUserName = ({ data, link, font = 16 }) => {
  const StyledUserName = styled.div`
    cursor: pointer;
    text-decoration: none;
    &:hover {
      p {
        text-decoration: underline;
      }
    }
  `

  return (
    <>
      <StyledUserName>
        <Link to={link}>
          <UserName data={data} font={font} />
        </Link>
      </StyledUserName>
    </>
  )
}

export default StyledUserName

import { Price24h } from 'models'
import axiosClient from './axiosClient'

const priceTokenApi = {
  get: (params: Price24h) => {
    const url = `/pair`
    return axiosClient.get(url, { params })
  },
}

export default priceTokenApi

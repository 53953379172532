import { useToast } from '@pancakeswap/uikit'
import axios from 'axios'
import queryString from 'query-string'

const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_DEFAULT_API,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(
  async (config) => {
    const token = await window.localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (error.response && error.response.status === 401) {
      originalRequest._retry = true

      try {
        const refreshToken = await window?.localStorage?.getItem('refreshToken')
        if (!refreshToken) {
          // throw new Error('Refresh token not available')
        }
        if (refreshToken) {
          const newAccessToken = await refreshAccessToken(refreshToken)
        }

        return axiosClient(originalRequest)
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError)
        throw refreshError
      }
    }

    console.error('Network error or unexpected error:', error)
    throw error
  },
)

const refreshAccessToken = async (refreshToken) => {
  try {
    const refreshedTokenResponse = await axios.post(`${process.env.NEXT_PUBLIC_DEFAULT_API}/auth/refresh`, {
      token: refreshToken,
    })
    const newAccessToken = refreshedTokenResponse.data.token
    const newAccessRefreshToken = refreshedTokenResponse.data.refreshToken
    window?.localStorage?.setItem('token', newAccessToken)
    window?.localStorage?.setItem('refreshToken', newAccessRefreshToken)

    return newAccessToken
  } catch (error: any) {
    if (error.response.status === 401) {
      window?.localStorage?.removeItem('user_social')
      window?.localStorage?.removeItem('token')
      window?.localStorage?.removeItem('refreshToken')
      window.location.reload()
    }
    console.error('Failed to refresh token:', error)
    throw error
  }
}

export default axiosClient

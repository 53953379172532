import { useTranslation } from '@pancakeswap/localization'
import { Box, Modal, useToast, Button, Input, Text } from '@pancakeswap/uikit'
import CountUp from 'react-countup'
import { useState } from 'react'
import styled from 'styled-components'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import contracts from 'config/constants/contracts'
import poolsMineAbi from 'config/abi/poolsMine.json'

export const trendyColors = {
  MAIN_GREEN: '#48E3E3',
  LIGHT_GREEN: '#71F7D4',
  ORANGE: '#FEA880',
  PURPLE: '#A8B2FF',
  DARK_PURPLE: '#7645d9',
  GRAY: '#D9D9D9',
  DARK_GRAY: '#393939',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  MAIN_GREEN_DISABLE: '#48E3E3',
  LIGHT_GREEN_DISABLE: '#A4E3E3',
  ORANGE_DISABLE: '#FCE0D3',
  PURPLE_DISABLE: '#BFC4E8',
  GRAY_DISABLE: '#F4F4F4',
  DARK_GRAY_DISABLE: '#393939',
  WHITE_DISABLE: '#ECECEC',
  PURPLE_NEW: 'var(--primary-primary-1, #8544F5)',
  COLORTABLE: '#777E90',
}
export interface Mine {
  totalMined: number
  claimed: number
  remain: number
  mineSpeed: number
  mineSpeedLevel: number
  startTime: number
  userClaimedMineLength: number
  unit?: string
  currentReward: number
  balanceTrend: number
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 32px;
`
const ClaimAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledButton = styled(Button)`
  background: #ff0420;
  height: 44px;
`
const depositModal = {
  padding: '-10px',
}
const depositInput = {
  borderRadius: '10px',
}
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const Error = styled.span`
  margin: -0.5em 0 1em;
  color: ${trendyColors.ORANGE};
`

const BgValue = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #d2d2d2;
  color: #202327;
`
const ClaimPoolModal = ({
  mine,
  // isV2,
  //   account,
  getMine,
  onDismiss,
  onSuccess,
}: {
  // onSuccess: (dataModal) => void
  getMine: () => void
  onSuccess: () => void
  onDismiss: () => void
  mine: Mine
}) => {
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [confirmedTxHash, setConfirmedTxHash] = useState('')
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const [isValidAmount, setIsValidAmount] = useState(true)
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { data: signer } = useSigner()

  const poolCT = getContract({
    address: contracts.poolsMine[CHAIN_ID],
    abi: poolsMineAbi,
    chainId: CHAIN_ID,
    signer,
  })

  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      return callWithMarketGasPrice(poolCT, 'claimRewardOPS')
    },
    onSuccess: async ({ receipt }) => {
      setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Claim reward OPS successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      getMine()
      onDismiss()
      onSuccess()
    },
  })

  return (
    <Modal
      style={depositModal}
      title={''}
      onDismiss={onDismiss}
      hideCloseButton={false}
      borderRadius={25}
      headerBackground="#fff"
      background="#fff"
      bodyPadding="0"
    >
      <Wrapper>
        <ClaimAmount>
          <Text fontSize="24px" fontWeight="600" style={{ paddingBottom: '12px' }}>
            CLAIM OPS
          </Text>
          <Text style={{ width: '100%', padding: '8px' }} fontSize="18px">
            Current reward OPS:
          </Text>
          <BgValue>
            <Text fontSize="17px" fontWeight="500" style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <CountUp
                  separator=","
                  start={0}
                  preserveValue
                  delay={0}
                  end={Number(mine.currentReward)}
                  decimals={mine.currentReward === 0 ? 0 : 8}
                  duration={0.5}
                />{' '}
                OPS
              </div>
            </Text>
          </BgValue>
        </ClaimAmount>
        <StyledButton
          variant={'danger'}
          width="100%"
          disabled={isConfirming || (!isValidAmount ? true : false)}
          onClick={handleConfirm}
        >
          {isConfirming ? (
            <ThreeDots className="loading">
              Claiming<span>.</span>
              <span>.</span>
              <span>.</span>
            </ThreeDots>
          ) : (
            'Claim'
          )}
        </StyledButton>
      </Wrapper>
    </Modal>
  )
}

export default ClaimPoolModal

import axiosClient from "./axiosClient";
import { AddFriend, FeedBackFriend } from "models";

const friendApi = {
  postFriend: (id: string, body: AddFriend) => {
    const url = `/v1/friends/${id}/connect`
    return axiosClient.post(url, body)
  },
  friendfeedback: (id: string, body: FeedBackFriend) => {
    const url = `/v1/friends/${id}/feedback`
    return axiosClient.post(url, body)
  },
  delete: (id: string) => {
    const url = `/v1/friends/${id}`
    return axiosClient.delete(url)
  },
}

export default friendApi
import postsApi from 'api/postApi'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from 'astore/UserContext'
import { useLocation, Link } from 'react-router-dom'
import {
  BodySkeloton,
  BtnBacktoHome,
  CardAction,
  Container,
  Content,
  Control,
  FooterSkeloton,
  HeaderPost,
  HeaderSkeloton,
  InteractAction,
  InteractCurrent,
  ItemAction,
  ModalTippy,
  ModalTippyItem,
  Shared,
  StyledIcon,
  StyledIconRef,
  StyledLine,
  StyledShare,
  StyledSkeleton,
  StyledTextNotFound,
  User,
  Wrapper,
  WrapperNotFound,
} from './styled'
import StyledAvatar from 'components/StyledAvatar'
import StyledUserName from 'components/StyledUserName'
import DayTimeDifference from 'components/DayTimeDifference'
import IsIcon from 'components/IsIcon'
import Tippy from '@tippyjs/react/headless'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import images from 'configs/images'
import { EditStatusModal } from '../EditStatusModal/EditStatusModal'
import { StyledImages } from 'components/StyledImages'
import { PostDetail } from '../PostDetailModal'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import Skeleton from 'react-loading-skeleton'
import { useToast } from '@pancakeswap/uikit'
import { PostShare } from '../ShareDetail'
import { PageMeta } from 'components/Layout/Page'

interface authorInfo {
  avatar: string
  name: string
}
interface shareInfo {
  audience: string
  authorId: string
  authorInfo: authorInfo
  content: string
  createdAt: string
  images: []
  isDeleted: false
  isEdited: false
  isShared: false
  likedUsers: string[]
  postId: string
  totalComments: number
}

interface postsData {
  _id: string
  authorId: string
  authorInfo: authorInfo
  content: string
  createdAt: string
  images: string[]
  likedUsers: string[]
  audience: string
  hashtags: any
  isDeleted: boolean
  isEdited: boolean
  isShared: boolean
  totalComments: number
  sharedInfo: shareInfo
}

const status = [
  {
    title: 'everyone',
    icon: images.public,
  },
  {
    title: 'onlyMe',
    icon: images.only_me,
  },
  {
    title: 'friends',
    icon: images.friends,
  },
]

const PostDetailPage = () => {
  const { account } = useActiveWeb3React()
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { t } = useTranslation()
  const location = useLocation()
  const { userData } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [postId, setPostID] = useState('')
  const [postData, setPostData] = useState<postsData>()
  const { toastError, toastSuccess } = useToast()
  const [postModals, setPostModals] = useState({})
  const [postNotFound, setPostNotFound] = useState(false)
  const [showCopied, setShowCopied] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search)
      const userIdQuery = urlParams.get('id')
      if (userIdQuery) {
        setPostID(userIdQuery)
      } else setPostNotFound(true)
    }
  }, [location.search])

  useEffect(() => {
    const fethchListPost = async () => {
      try {
        setLoading(true)
        const response = await postsApi.getpostId(postId)
        if (response.data.length <= 0) {
          setPostNotFound(true)
        }
        setPostData(response.data)
        setLoading(false)
      } catch (error) {
        setPostNotFound(true)
        setLoading(false)
      }
    }
    if (postId) {
      fethchListPost()
    }
  }, [postId])

  const handleLikePost = async (postId) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      await connetSocial()
      return
    }
    try {
      if (postId && account) {
        const response = await postsApi.likepost(postId)
        const updatedPostData = { ...postData }

        if (postData.likedUsers.includes(userData.userId)) {
          updatedPostData.likedUsers = postData.likedUsers.filter((e) => e !== userData.userId)
        } else {
          updatedPostData.likedUsers.push(userData.userId)
        }

        setPostData(updatedPostData)
      }
    } catch (error) {
      console.log('Failed to fetch userInfo: ', error)
    }
  }

  const handleOpenModal = (item) => {
    // document.body.style.overflow = 'hidden'
    setPostModals(item)
  }

  const deletePost = async (postId) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      await connetSocial()
      return
    }
    try {
      if (postId && account) {
        const response = await postsApi.deletepost(postId)
        setPostNotFound(true)
        toastSuccess(t('Success'), t('Move to trash Success'))
      }
    } catch (error) {
      toastError(t('Error'), t('Please try again'))
      console.log('Failed to fetch userInfo: ', error)
    }
  }

  const copyText = (text) => {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const handleRef = (_idpost) => {
    const param = window.location.origin
    const text = `${param}/posts?id=${_idpost}`
    copyText(text)
    setShowCopied(true)
  }

  const handleLeave = () => {
    setTimeout(() => {
      setShowCopied(false)
    }, 100)
  }

  const shareOnTelegram = (_idpost) => {
    const param = window.location.origin
    const messageText = `${param}/posts?id=${_idpost}`
    const telegramShareURL = 'https://t.me/share/url?url=' + encodeURIComponent(messageText)
    window.open(telegramShareURL, '_blank')
  }

  const shareOnTwitter = (_idpost) => {
    const param = window.location.origin
    const messageText = `${param}/posts?id=${_idpost}`

    const imageUrl = 'https://opsocialfi.com/images/thumnal.jpg'

    const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      messageText,
    )}&media=${encodeURIComponent(imageUrl)}`

    window.open(twitterShareURL, '_blank')
  }

  const SkeletonLoading = () => {
    return (
      <StyledSkeleton>
        <HeaderSkeloton>
          <Skeleton width={44} height={44} borderRadius={50} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Skeleton width={80} height={16} />
            <Skeleton width={120} height={16} />
          </div>
        </HeaderSkeloton>
        <BodySkeloton />
        <FooterSkeloton>
          <Skeleton width={60} height={14} />
          <Skeleton width={60} height={14} />
          <Skeleton width={60} height={14} />
        </FooterSkeloton>
      </StyledSkeleton>
    )
  }

  const meta = postData && {
    key: 'post',
    title: `OP SocialFi - Post Details`,
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/posts?:id',
      type: 'website',
      title: 'OP SocialFi - Post Details',
      locale: 'vi_VN',
      images: [
        {
          alt: `OP SocialFi - Post Details`,
          url: `https://gateway.pinata.cloud/ipfs/QmcnXDf6J3jrz49KBiGKdicDcqvNdUS4aS5mckmyWLMK9i`,
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description: `OP SocialFi - Post Details`,
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description: `OP SocialFi - Post Details`,
    robotsProps: {},
  }

  return (
    <>
      {postNotFound ? (
        <WrapperNotFound>
          <StyledTextNotFound>Did not find the article</StyledTextNotFound>
          <Link to="/">
            <BtnBacktoHome>Back to Home</BtnBacktoHome>
          </Link>
        </WrapperNotFound>
      ) : (
        <>
          <PageMeta meta={meta} />
          <Wrapper>
            {!postData ? (
              <>{loading && <SkeletonLoading />}</>
            ) : (
              <Container>
                <HeaderPost>
                  <User>
                    <StyledAvatar
                      data={postData.authorInfo.avatar}
                      link={`/profile?id=${postData.authorId}`}
                      w={44}
                      h={44}
                    />
                    <div className="userInfo">
                      <StyledUserName link={`/profile?id=${postData.authorId}`} data={postData.authorInfo.name} />
                      <div className="status">
                        <span className="text">
                          <DayTimeDifference At={postData.createdAt} />
                        </span>
                        <IsIcon
                          w={14}
                          h={14}
                          src={status.find((st) => st.title === postData.audience)?.icon}
                          curs={true}
                        />
                      </div>
                    </div>
                  </User>
                  <Control>
                    <StyledIcon>
                      <Tippy
                        interactive
                        placement={'bottom-end'}
                        trigger="click"
                        render={(attrs) => (
                          <>
                            {postData.authorId === userData.userId ? (
                              <ModalTippy>
                                <ModalTippyItem
                                  onClick={() => handleOpenModal({ ...postModals, [postData._id]: 'edit' })}
                                >
                                  <IsIcon w={18} h={18} src={images.editstatus} curs={true} />
                                  Edit article
                                </ModalTippyItem>
                                <StyledLine />
                                <ModalTippyItem onClick={() => deletePost(postData._id)}>
                                  <IsIcon w={18} h={18} src={images.trash} curs={true} />
                                  Move to trash
                                </ModalTippyItem>
                              </ModalTippy>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      >
                        <img
                          style={{ width: '21px', height: '21px', cursor: 'pointer' }}
                          src={images.morefill}
                          alt="icon"
                        />
                      </Tippy>
                    </StyledIcon>
                    {postData.authorId !== userData.userId && (
                      <StyledIcon>
                        <IsIcon w={24} h={24} src={images.closeline} curs={true} />
                      </StyledIcon>
                    )}
                    {postModals[postData._id] === 'edit' && (
                      <div className="fullscreen-modal">
                        <EditStatusModal
                          userData={userData}
                          dataEdit={postData}
                          listDataPost={postData}
                          setListDataPost={setPostData}
                          onDismiss={() => {
                            // document.body.style.overflow = 'auto'
                            setPostModals({ ...postModals, [postData._id]: null })
                          }}
                        />
                      </div>
                    )}
                  </Control>
                </HeaderPost>
                <Content>{postData.content}</Content>
                {postData.isShared === false ? (
                  <>
                    {postData.images.length > 0 && (
                      <div style={{ width: '100%' }}>
                        <StyledImages data={postData.images} setData={null} />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {postData.sharedInfo && (
                      <Shared>
                        <StyledShare>
                          <div style={{ width: '100%' }}>
                            {postData.sharedInfo.images.length > 0 && (
                              <StyledImages data={postData.sharedInfo.images} setData={null} />
                            )}
                          </div>
                          <Container>
                            <HeaderPost>
                              <User>
                                <StyledAvatar
                                  data={postData.sharedInfo.authorInfo.avatar}
                                  link={`/profile?id=${postData.sharedInfo.authorId}`}
                                  w={44}
                                  h={44}
                                />
                                <div className="userInfo">
                                  <StyledUserName
                                    link={`/profile?id=${postData.sharedInfo.authorId}`}
                                    data={postData.sharedInfo.authorInfo.name}
                                  />
                                  <div className="status">
                                    <span className="text">
                                      <DayTimeDifference At={postData.sharedInfo.createdAt} />
                                    </span>
                                    <IsIcon
                                      w={14}
                                      h={14}
                                      src={status.find((st) => st.title === postData.sharedInfo.audience)?.icon}
                                      curs={true}
                                    />
                                  </div>
                                </div>
                              </User>
                            </HeaderPost>
                            <Content>{postData.sharedInfo.content}</Content>
                          </Container>
                        </StyledShare>
                      </Shared>
                    )}
                  </>
                )}

                <CardAction>
                  {(postData.totalComments > 0 || postData.likedUsers.length > 0) && (
                    <InteractCurrent>
                      {postData.likedUsers.length > 0 ? (
                        <div className="like">
                          <IsIcon src={images.iconL} curs={true} />
                          <span>{postData.likedUsers.length}</span>
                        </div>
                      ) : (
                        <div />
                      )}
                      {postData.totalComments > 0 && (
                        <div className="comment">
                          <span>{postData.totalComments}</span>
                          <span>{postData.totalComments <= 2 ? 'Comment' : 'Comments'}</span>
                        </div>
                      )}
                    </InteractCurrent>
                  )}
                  <InteractAction>
                    {!userData ? (
                      <ItemAction onClick={() => handleLikePost(postData._id)}>
                        <IsIcon src={images.iconlike} curs={true} />
                        <span>Like</span>
                      </ItemAction>
                    ) : (
                      <ItemAction onClick={() => handleLikePost(postData._id)}>
                        {postData.likedUsers.includes(userData.userId) ? (
                          <IsIcon src={images.iconL} curs={true} />
                        ) : (
                          <IsIcon src={images.iconlike} curs={true} />
                        )}
                        <span>Like</span>
                      </ItemAction>
                    )}
                    <ItemAction onClick={() => handleOpenModal({ ...postModals, [postData._id]: 'detail' })}>
                      <IsIcon src={images.commentsicon} curs={true} />
                      <span>Comment</span>
                    </ItemAction>
                    {postData.isShared === false ? (
                      <>
                        <Tippy
                          interactive
                          trigger="click"
                          render={(attrs) => (
                            <>
                              <ModalTippy>
                                <ModalTippyItem onClick={() => shareOnTwitter(postData._id)}>
                                  <IsIcon w={24} h={24} src={images.logotwitter} curs={true} />
                                  Share with Twitter
                                </ModalTippyItem>
                                <ModalTippyItem onClick={() => shareOnTelegram(postData._id)}>
                                  <IsIcon w={24} h={24} src={images.logotelegram} curs={true} />
                                  Share with Telegram
                                </ModalTippyItem>
                                {postData.authorId !== userData.userId && (
                                  <ModalTippyItem
                                    onClick={() => handleOpenModal({ ...postModals, [postData._id]: 'share' })}
                                  >
                                    <IsIcon w={24} h={24} src={images.logoshare} curs={true} />
                                    Share now
                                  </ModalTippyItem>
                                )}
                                <ModalTippyItem onClick={() => handleRef(postData._id)}>
                                  <StyledIconRef id="linkpost" src={images.copylink} onMouseLeave={handleLeave} />
                                  Copy Link
                                  <Tooltip anchorId="linkpost" content={showCopied ? 'Copied' : 'Copy'} />
                                </ModalTippyItem>
                              </ModalTippy>
                            </>
                          )}
                        >
                          <ItemAction>
                            <IsIcon src={images.shareicon} curs={true} />
                            <span>Share</span>
                          </ItemAction>
                        </Tippy>
                      </>
                    ) : (
                      <>
                        <Tippy
                          interactive
                          trigger="click"
                          render={(attrs) => (
                            <>
                              <ModalTippy>
                                <ModalTippyItem onClick={() => shareOnTwitter(postData._id)}>
                                  <IsIcon w={24} h={24} src={images.logotwitter} curs={true} />
                                  Share with Twitter
                                </ModalTippyItem>
                                <ModalTippyItem onClick={() => shareOnTelegram(postData._id)}>
                                  <IsIcon w={24} h={24} src={images.logotelegram} curs={true} />
                                  Share with Telegram
                                </ModalTippyItem>
                                {postData.authorId !== userData.userId &&
                                  postData?.sharedInfo?.authorId !== userData.userId && (
                                    <ModalTippyItem
                                      onClick={() => handleOpenModal({ ...postModals, [postData._id]: 'share' })}
                                    >
                                      <IsIcon w={24} h={24} src={images.logoshare} curs={true} />
                                      Share now
                                    </ModalTippyItem>
                                  )}
                                <ModalTippyItem onClick={() => handleRef(postData._id)}>
                                  <StyledIconRef id="linkpost" src={images.copylink} onMouseLeave={handleLeave} />
                                  Copy Link
                                  <Tooltip anchorId="linkpost" content={showCopied ? 'Copied' : 'Copy'} />
                                </ModalTippyItem>
                              </ModalTippy>
                            </>
                          )}
                        >
                          <ItemAction>
                            <IsIcon src={images.shareicon} curs={true} />
                            <span>Share</span>
                          </ItemAction>
                        </Tippy>
                      </>
                    )}

                    {postModals[postData._id] === 'detail' && (
                      <div className="fullscreen-modal">
                        <PostDetail
                          data={postData}
                          listDataPost={postData}
                          setListDataPost={setPostData}
                          userData={userData}
                          inputRef={inputRef}
                          onDismiss={() => {
                            // document.body.style.overflow = 'auto'
                            setPostModals({ ...postModals, [postData._id]: null })
                          }}
                        />
                      </div>
                    )}
                    {postModals[postData._id] === 'share' && (
                      <div className="fullscreen-modal">
                        <PostShare
                          userData={userData}
                          dataShare={postData}
                          listDataPost={postData}
                          setListDataPost={setPostData}
                          onDismiss={() => {
                            // document.body.style.overflow = 'auto'
                            setPostModals({ ...postModals, [postData._id]: null })
                          }}
                        />
                      </div>
                    )}
                  </InteractAction>
                </CardAction>
              </Container>
            )}
          </Wrapper>
        </>
      )}
    </>
  )
}

export const PostDetailPageLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default PostDetailPage

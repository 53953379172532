import { Button, LinkExternal, useMatchBreakpoints, useModal } from '@pancakeswap/uikit'
import images from 'configs/images'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import contracts from 'config/constants/contracts'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import { ChainId } from '../../../packages/swap-sdk/src/constants'
import poolsAbi from 'config/abi/pools.json'
import poolMineAbi from 'config/abi/poolsMine.json'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { formatEther } from '@ethersproject/units'
import { useEffect, useRef, useState } from 'react'
import ClaimPoolModal from './components/ClaimCommModal'
import { getBlockExploreLink } from 'utils'
import { shortenURL } from 'views/Referral/util'
import CommRecieverHistory from './components/CommRecieverHistory'
import CommClaimHistory from './components/CommClaimHistory'

const PoolRewards = styled.div`
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  padding: 120px 20px;
  min-height: 90vh;
  @media (max-width: 767px) {
    padding: 120px 20px;
  }
`
const Rewards = styled.div`
  position: relative;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  background: var(--neutral-100, #1a1e23);
  box-shadow: 0px 8px 32px -6px rgba(20, 23, 26, 0.08), 0px 6px 20px -6px rgba(20, 23, 26, 0.12);
  h3 {
    color: var(--White-White, #fff);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
  h4 {
    color: var(--White-White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    color: var(--White-White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    @media (max-width: 840px) {
      max-width: 500px;
    }
  }
  a {
    color: var(--primary-700, #f71c35);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
  }
  span {
    color: var(--neutral-400, #a9b9cc);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    padding: 20px;
    gap: 20px;
  }
`
const Commission = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`
const Text = styled.p`
  max-width: 552px;
  padding-top: 12px;
  color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`
const ImgPoolRewards = styled.img`
  position: absolute;
  bottom: 10px;
  right: 20px;
  @media (max-width: 888px) {
    width: 130px;
    bottom: 0;
    right: 0;
  }
  @media (max-width: 575px) {
    width: 95px;
    bottom: -18px;
    right: -20px;
  }
`
const StyledRewardsRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const CommissionHis = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media (max-width: 896px) {
    flex-direction: column;
  }
`
const BtnGroups = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`

const data = [
  {
    amountUSD: {
      type: 'BigNumber',
      hex: '0x8ac71e6ca058c67d',
    },
    date: {
      type: 'BigNumber',
      hex: '0x660d85c9',
    },
    from: '0xEc4789f5d5cfA0b1279e2bFFA034f05C40033D95',
  },
  {
    amountUSD: {
      type: 'BigNumber',
      hex: '0x8ac71e6ca058c67d',
    },
    date: {
      type: 'BigNumber',
      hex: '0x660d85c9',
    },
    from: '0xEc4789f5d5cfA0b1279e2bFFA034f05C40033D95',
  },
  {
    amountUSD: {
      type: 'BigNumber',
      hex: '0x8ac71e6ca058c67d',
    },
    date: {
      type: 'BigNumber',
      hex: '0x660d85c9',
    },
    from: '0xEc4789f5d5cfA0b1279e2bFFA034f05C40033D95',
  },
  {
    amountUSD: {
      type: 'BigNumber',
      hex: '0x8ac71e6ca058c67d',
    },
    date: {
      type: 'BigNumber',
      hex: '0x660d85c9',
    },
    from: '0xEc4789f5d5cfA0b1279e2bFFA034f05C40033D95',
  },
  {
    amountUSD: {
      type: 'BigNumber',
      hex: '0x8ac71e6ca058c67d',
    },
    date: {
      type: 'BigNumber',
      hex: '0x660d85c9',
    },
    from: '0xEc4789f5d5cfA0b1279e2bFFA034f05C40033D95',
  },
  {
    amountUSD: {
      type: 'BigNumber',
      hex: '0x8ac71e6ca058c67d',
    },
    date: {
      type: 'BigNumber',
      hex: '0x660d85c9',
    },
    from: '0xEc4789f5d5cfA0b1279e2bFFA034f05C40033D95',
  },
]

const Commissions = () => {
  const { account, chainId } = useActiveWeb3React()
  const { data: signer } = useSigner()
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const { isMobile, isTablet } = useMatchBreakpoints()
  const poolContract = getContract({
    address: contracts.pools[CHAIN_ID],
    abi: poolsAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const poolMineContract = getContract({
    address: contracts.poolsMine[CHAIN_ID],
    abi: poolMineAbi,
    chainId: CHAIN_ID,
    signer,
  })

  const limit = 100000
  const page = 0
  const [loadingModalClaim, setLoadingModalClaim] = useState(false)
  const [loadingHis, setLoadingHis] = useState(false)
  const [remainCommission, setRemainCommission] = useState(0)
  const isClaimRef = useRef('')
  const [remainDirectCommission, setRemainDirectCommission] = useState(0)
  const [rewardsKol, setRewardsKol] = useState(0)

  const [titleHistory, setTitleHistory] = useState('')

  const [userReceiverComm, setUserReceiverComm] = useState([])
  const [userClaimComm, setUserClaimComm] = useState([])
  const [userClaimedCommLength, setUserClaimedCommLength] = useState(0)
  const [userReceiverCommLength, setUserReceiverCommLength] = useState(0)

  const getCommission = async () => {
    if (account) {
      const getremainComm = await poolContract.remainCommUSD(account)
      const getremainComm2 = await poolContract.remainComm2(account)
      const getcommKOL = await poolContract.remainRewardsActiveKolUSD(account)
      const commRemain = Number(formatEther(getremainComm))
      const commRemain2 = Number(formatEther(getremainComm2))
      const commKOL = Number(formatEther(getcommKOL))
      setRemainCommission(commRemain)
      setRemainDirectCommission(commRemain2)
      setRewardsKol(commKOL)
    } else {
      setRemainCommission(0)
      setRemainDirectCommission(0)
      setRewardsKol(0)
    }
  }

  const onSuccess = () => {
    getCommission()
  }

  const [openClaimModal, onDismissModal] = useModal(
    <ClaimPoolModal
      loadingModalClaim={loadingModalClaim}
      setLoadingModalClaim={setLoadingModalClaim}
      account={account}
      amount={remainCommission}
      funcName={'claimComm'}
      onSuccess={onSuccess}
      onDismiss={() => onDismissModal()}
    />,
    true,
  )

  const [openClaim2Modal, onDismissModal2] = useModal(
    <ClaimPoolModal
      loadingModalClaim={loadingModalClaim}
      setLoadingModalClaim={setLoadingModalClaim}
      account={account}
      amount={remainDirectCommission}
      funcName={'claimComm2'}
      onSuccess={onSuccess}
      onDismiss={() => onDismissModal2()}
    />,
    true,
  )

  const [openClaimKOL, onDismissModalKOL] = useModal(
    <ClaimPoolModal
      loadingModalClaim={loadingModalClaim}
      setLoadingModalClaim={setLoadingModalClaim}
      account={account}
      amount={rewardsKol}
      funcName={'claimCommKOL'}
      onSuccess={onSuccess}
      onDismiss={() => onDismissModalKOL()}
    />,
    true,
  )

  const handladClaim = (_is) => {
    onDismissModal()
    isClaimRef.current = _is
    if (isClaimRef.current !== '') {
      const run =
        Number(isClaimRef.current) < 1
          ? openClaimModal
          : Number(isClaimRef.current) === 1
          ? openClaim2Modal
          : openClaimKOL
      run()
    }
  }

  const handledSetTitle = (_ishis) => {
    setTitleHistory(_ishis)
  }

  const handledDetail = async (_ishis) => {
    setLoadingHis(true)
    handledSetTitle(_ishis)
    if (_ishis === 0) {
      const getUserReceiverComm = await poolMineContract.getUserReceiverComm(account, limit, page)
      // const getUserReceiverComm = await poolMineContract.getUserReceiverComm(account, limit, page)
      const getUsersClaimedComm = await poolMineContract.getUsersClaimedComm(account, limit, page)
      const getUserReceiverCommLength = await poolMineContract.getUserReceiverCommLength(account)
      const getUsersClaimedCommLength = await poolMineContract.getUsersClaimedCommLength(account)
      setUserReceiverComm(getUserReceiverComm[0])
      setUserClaimComm(getUsersClaimedComm[0])
      setUserReceiverCommLength(Number(getUserReceiverCommLength))
      setUserClaimedCommLength(Number(getUsersClaimedCommLength))
      // console.log("getCommHis", getUserReceiverComm[0]);
    } else if (_ishis === 1) {
      const getUserReceiverComm2 = await poolMineContract.getUserReceiverComm2(account, limit, page)
      const getUsersClaimedComm2 = await poolMineContract.getUsersClaimedComm2(account, limit, page)
      const getUserReceiverComm2Length = await poolMineContract.getUserReceiverComm2Length(account)
      const getUsersClaimedComm2Length = await poolMineContract.getUsersClaimedComm2Length(account)
      setUserReceiverComm(getUserReceiverComm2[0])
      setUserClaimComm(getUsersClaimedComm2[0])
      setUserReceiverCommLength(Number(getUserReceiverComm2Length))
      setUserClaimedCommLength(Number(getUsersClaimedComm2Length))
      // console.log("getCommHis", getComm2His[0]);
    } else if (_ishis === 2) {
      const getUserReceiverCommKOL = await poolMineContract.getUserReceiverCommKOL(account, limit, page)
      const getUsersClaimedCommKOL = await poolMineContract.getUsersClaimedCommKOL(account, limit, page)
      const getUserReceiverCommKOLLength = await poolMineContract.getUserReceiverCommKOLLength(account)
      const getUsersClaimedCommKOLLength = await poolMineContract.getUsersClaimedCommKOLLength(account)
      setUserReceiverComm(getUserReceiverCommKOL[0])
      setUserClaimComm(getUsersClaimedCommKOL[0])
      setUserReceiverCommLength(Number(getUserReceiverCommKOLLength))
      setUserClaimedCommLength(Number(getUsersClaimedCommKOLLength))
      // console.log("getCommHis", getCommKOLHis[0]);
    } else {
      setUserClaimComm([])
    }
    setLoadingHis(false)
  }

  useEffect(() => {
    getCommission()
  }, [account])

  return (
    <PoolRewards>
      <Rewards>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <h3>Pools Rewards</h3>
          <p>
            These Pool Rewards are only for Referral. Let invite your friends and get our reward{' '}
            <Link to="/referral">Invite Now</Link>
          </p>
        </div>
        <Commission>
          <Text
            className="value"
            style={{
              width: isMobile ? '100%' : '240px',
              color: 'rgba(103, 228, 255, 1)',
              fontWeight: '700',
              paddingTop: '0',
            }}
          >
            Direct Commission: {remainCommission.toFixed(2)}
          </Text>
          <BtnGroups>
            <Button
              style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
              variant="primary"
              width={['80px', '80px', '100px']}
              p="0 8px"
              height="36px"
              onClick={() => handladClaim(0)}
              disabled={remainCommission <= 0}
            >
              Claim
            </Button>
            <Button
              style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
              variant="primary"
              width={['80px', '80px', '100px']}
              p="0 8px"
              height="36px"
              onClick={() => handledDetail(0)}
            >
              Details
            </Button>
          </BtnGroups>
        </Commission>
        <Commission>
          <Text
            className="value"
            style={{
              width: isMobile ? '100%' : '240px',
              color: 'rgba(103, 228, 255, 1)',
              fontWeight: '700',
              paddingTop: '0',
            }}
          >
            Interest Commission: {remainDirectCommission.toFixed(2)}
          </Text>
          <BtnGroups>
            <Button
              style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
              variant="primary"
              width={['80px', '80px', '100px']}
              p="0 8px"
              height="36px"
              onClick={() => handladClaim(1)}
              disabled={remainDirectCommission <= 0}
            >
              Claim
            </Button>
            <Button
              style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
              variant="primary"
              width={['80px', '80px', '100px']}
              p="0 8px"
              height="36px"
              onClick={() => handledDetail(1)}
            >
              Details
            </Button>
          </BtnGroups>
        </Commission>
        <Commission>
          <Text
            className="value"
            style={{
              width: isMobile ? '100%' : '240px',
              color: 'rgba(103, 228, 255, 1)',
              fontWeight: '700',
              paddingTop: '0',
            }}
          >
            ActiveKOL Commission: {rewardsKol.toFixed(2)}
          </Text>
          <BtnGroups>
            <Button
              style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
              variant="primary"
              width={['80px', '80px', '100px']}
              p="0 8px"
              height="36px"
              onClick={() => handladClaim(2)}
              disabled={rewardsKol <= 0}
            >
              Claim
            </Button>
            <Button
              style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
              variant="primary"
              width={['80px', '80px', '100px']}
              p="0 8px"
              height="36px"
              onClick={() => handledDetail(2)}
            >
              Details
            </Button>
          </BtnGroups>
        </Commission>
        {/* <StyledRewardsRoot>
                <span>Root Contract</span>
                <LinkExternal
                  fontSize="14px"
                  href={getBlockExploreLink(contracts.pools[CHAIN_ID], 'address', CHAIN_ID)}
                  ellipsis={true}
                  style={{ color: '#0dcaf0' }}
                  color="#0dcaf0"
                >
                  {shortenURL(`${contracts.pools[CHAIN_ID]}`, 18)}
                </LinkExternal>
                <a
                  href={getBlockExploreLink(contracts.pools[CHAIN_ID], 'address', CHAIN_ID)}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  style={{ display: isMobile && 'none' }}
                >
                  Check Details
                </a>
              </StyledRewardsRoot> */}
        <ImgPoolRewards src={images.imgrewards} alt="rewards" />
      </Rewards>
      {account && titleHistory !== '' && (
        <>
          <Text style={{ padding: '40px 0', fontSize: '28px', lineHeight: '32px', fontWeight: '700', color: '#000' }}>
            {Number(titleHistory) === 0
              ? 'Direct Comm History'
              : Number(titleHistory) === 1
              ? 'Interest Comm History'
              : 'ActiveKOL Comm History'}
          </Text>
          {loadingHis ? (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                fontSize: '24px',
                fontWeight: '600',
                padding: '20px 0',
              }}
            >
              <ThreeDots className="loading">
                Loading<span>.</span>
                <span>.</span>
                <span>.</span>
              </ThreeDots>
            </div>
          ) : (
            <CommissionHis>
              <CommRecieverHistory
                userClaimComm={userReceiverComm}
                usersClaimed={[]}
                userClaimedCommLength={userReceiverCommLength}
              />
              <CommClaimHistory
                userClaimComm={userClaimComm}
                usersClaimed={[]}
                userClaimedCommLength={userClaimedCommLength}
              />
            </CommissionHis>
          )}
        </>
      )}
    </PoolRewards>
  )
}

export default Commissions

import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  
  @font-face {
    font-family: 'Inter';
    src: url("/assets/fonts/Inter-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: 'Inter';
    src: url("/assets/fonts/Inter-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: 'Inter';
    src: url("/assets/fonts/Inter-SemiBold.ttf");
    font-weight: 600;
  }
  @font-face {
    font-family: 'Inter';
    src: url("/assets/fonts/Inter-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: 'Inter';
    src: url("/assets/fonts/Inter-ExtraBold.ttf");
    font-weight: 800;
  }
  @font-face {
    font-family: 'Inter';
    src: url("/assets/fonts/Inter-Black.ttf");
    font-weight: 900;
  }
  body {
    /* background-color: #13171b; */
    background-color: #FFF;
    height: auto;
  }

}
`

export default GlobalStyle

import styled from 'styled-components'
import { Text } from '@pancakeswap/uikit'
import CountUp from 'react-countup'
import { useEffect, useState } from 'react'
import images from 'configs/images'
import priceTokenApi from 'api/priceTokenApi'

const Wrapper = styled.div`
  max-width: 310px;
  width: 100%;
  z-index: 98;
  position: fixed;
  // display: flex;
  padding: 18px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 2px solid transparent;
  background-image: linear-gradient(#fff, #fff), linear-gradient(180deg, #c45656 27%, #ef4343 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  gap: 6px;
  right: 1%;
  top: 50%;
  @media screen and (max-width: 800px) {
    padding: 12px;
    top: 60%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    margin: auto;
  }
`
const Card = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #f1f4f9;
`
const Title = styled(Text)`
  max-width: 65px;
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`
const Label = styled(Text)`
  color: #3aab9d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const CurrencyExchange = () => {
  const [priceOP, setPriceOP] = useState({
    op: 0,
    op24h: 0,
  })
  const [styleDiv, setStyleDiv] = useState('flex')
  const [priceOPSF, setPriceOPSF] = useState({
    opsf: 0,
    opsf24h: 0,
  })

  useEffect(() => {
    const fetchPriceOP = async () => {
      try {
        const params = {
          address: '0x0df083de449f75691fc5a36477a6f3284c269108',
          chain: 'optimism',
        }
        const response = await priceTokenApi.get(params)
        const currentPrice = response.data.data.price
        const previousPrice24h = response.data.data.price24h ? response.data.data.price24h.price : null

        if (previousPrice24h !== null) {
          const percentageChange24h = ((currentPrice - previousPrice24h) / previousPrice24h) * 100
          setPriceOP({ op: currentPrice, op24h: percentageChange24h })
        } else {
          setPriceOP({ op: currentPrice, op24h: 0 })
        }
      } catch (error) {
        console.log('Failed to fetch price: ', error)
      }
    }
    const fetchPriceOPSF = async () => {
      try {
        const params = {
          address: '0x84c605834f0e06408f96c50ac886d7c9b239537f',
          chain: 'optimism',
        }
        const response = await priceTokenApi.get(params)
        const currentPrice = response.data.data.price
        const previousPrice24h = response.data.data.price24h ? response.data.data.price24h.price : null

        if (previousPrice24h !== null) {
          const percentageChange24h = ((currentPrice - previousPrice24h) / previousPrice24h) * 100
          setPriceOPSF({ opsf: currentPrice, opsf24h: percentageChange24h })
        } else {
          setPriceOPSF({ opsf: currentPrice, opsf24h: 0 })
        }
      } catch (error) {
        console.log('Failed to fetch price: ', error)
      }
    }
    fetchPriceOP()
    fetchPriceOPSF()
  }, [])

  return (
    <Wrapper style={{ display: styleDiv }}>
      <div style={{ position: 'absolute', top: '0%', left: '1%' }} onClick={() => setStyleDiv('none')}>
        <img src="/images/icons/close-line.svg" style={{ cursor: 'pointer' }} />
      </div>
      <Card>
        <Title>
          1 <span style={{ color: '#ff0420' }}>OPS</span>
        </Title>
        <span style={{ color: 'rgba(173, 171, 178, 1)', fontSize: '16px' }}>=</span>
        <Title>
          $
          <CountUp
            separator=","
            start={0}
            preserveValue
            delay={0}
            decimals={3}
            duration={1}
            end={Number(priceOPSF.opsf)}
          />{' '}
        </Title>

        {priceOPSF.opsf24h >= 0 ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <img width="16px" height="16px" src={images.priceup} alt="img" />
            <Label>
              +
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                decimals={3}
                duration={1}
                end={Number(priceOPSF.opsf24h)}
              />
              %
            </Label>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <img width="16px" height="16px" src={images.pricedow} alt="img" />
            <Label style={{ color: '#E30721' }}>
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                decimals={3}
                duration={1}
                end={Number(priceOPSF.opsf24h)}
              />
              %
            </Label>
          </div>
        )}
      </Card>
      <Card>
        <Title>
          1 <span style={{ color: '#ff0420' }}>OP</span>
        </Title>
        <span style={{ color: 'rgba(173, 171, 178, 1)', fontSize: '16px' }}>=</span>
        <Title>
          $
          <CountUp
            separator=","
            start={0}
            preserveValue
            delay={0}
            decimals={3}
            duration={1}
            end={Number(priceOP.op)}
          />{' '}
        </Title>

        {priceOP.op24h >= 0 ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <img width="16px" height="16px" src={images.priceup} alt="img" />
            <Label>
              +
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                decimals={3}
                duration={1}
                end={Number(priceOP.op24h)}
              />
              %
            </Label>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <img width="16px" height="16px" src={images.pricedow} alt="img" />
            <Label style={{ color: '#E30721' }}>
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                decimals={3}
                duration={1}
                end={Number(priceOP.op24h)}
              />
              %
            </Label>
          </div>
        )}
      </Card>
    </Wrapper>
  )
}

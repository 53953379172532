import React from 'react'
import images from 'configs/images'

const Avatar = ({ data, width = 44, height = 44, border = 1 }) => {
  const style = {
    width: `${width}px`,
    height: `${height}px`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    border: `${border}px solid #e4e4e7`,
    background: '#fff',
    overflow: 'hidden',
  }
  const styledAvatar = {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as 'cover',
  }

  return (
    <div style={{ width: `${width}`, height: `${height}` }}>
      <div style={style}>
        {!data || data === '' ? (
          <img style={styledAvatar} src={images.avatar_none} alt="avatar" />
        ) : (
          <img style={styledAvatar} src={data} alt="avatar" />
        )}
      </div>
    </div>
  )
}

export default Avatar

import { Button, ButtonProps } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
import styled from 'styled-components'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'

const StyledButton = styled(Button)`
  * {
    font-family: Inter, sans-serif;
    font-size: 14px;
  }
  border-radius: var(--radius-radi-6, 12px);
  border: 1px solid var(--mode-base-200, #e4e4e7);
  background: var(--primary-600-default, #ff0420);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8, 8px);
  color: #fff;
  height: 44px;
`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <StyledButton onClick={handleClick} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </StyledButton>
  )
}

export default ConnectWalletButton

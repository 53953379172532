import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from 'components/Avatar'

const StyledAvatar = ({ data, link, w = 44, h = 44, br = 1 }) => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <Link to={link}>
        <Avatar data={data} width={w} height={h} border={br} />
      </Link>
    </div>
  )
}

export default StyledAvatar

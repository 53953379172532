import { useTranslation } from '@pancakeswap/localization'
import { Modal, useToast, Button, Text, Input } from '@pancakeswap/uikit'
import CountUp from 'react-countup'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { usePoolsContract } from 'hooks/useContract'
import { ToastDescriptionWithTx } from 'components/Toast'
import useRegisterConfirmTransaction from 'hooks/useRegisterConfirmTransaction'
import { getContract, getPoolsContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import poolAbi from 'config/abi/pools.json'
import { useSigner } from 'wagmi'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  height: 100%;
  justify-content: space-between;
  gap: 1em;
  .imagesvector {
    margin-left: 4px;
    padding: 3px;
    display: flex;
    width: 18px;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }
`
const ClaimAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`
const StyledButton = styled(Button)`
  width: 130px;
  height: 42px;
  padding: 0 20px;
  background: rgb(255, 4, 32);
`
const BtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const depositModal = {
  padding: '24px',
}
const depositInput = {
  borderRadius: '10px',
}
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const StyledInput = styled.div`
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 6px;
  color: #000;
  background: #ccc;
`
const InputAddress = styled(Input)`
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border: none;
  font-weight: 600;
  border-radius: 6px;
  color: #000;
  background: #ccc;
`

const ClaimPoolModal = ({
  loadingModalClaim,
  setLoadingModalClaim,
  pool,
  // isV2,
  account,
  onDismiss,
  onSuccess,
}) => {
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [confirmedTxHash, setConfirmedTxHash] = useState('')
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const poolContract = usePoolsContract()
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const poolCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [isValidAmount, setIsValidAmount] = useState(true)
  const [valueAddress, setValusAddress] = useState(account)

  const isClaimRef = useRef('')

  // const [openModal, onDismissModal] = useModal(
  //   <ModalCheckPinCode onDismiss={() => onDismissModal()} onCheck={() => onCheck()} />,
  //   false,
  //   false,
  //   'ModalCheckPinCode',
  // )

  // const onCheck = () => {
  //   handleConfirm()
  // }
  // const handleCheckPin = () => {
  //   const protectAccount = process.env.NEXT_PUBLIC_DEFAULT_PROTECT_ACCOUNT
  //   if (protectAccount.includes(account)) {
  //     // openModal()
  //   } else {
  //     handleConfirm()
  //   }
  // }

  const handledClaim = (_isClaim) => {
    isClaimRef.current = _isClaim
    if (isClaimRef.current !== '' && valueAddress.length > 0) {
      handleConfirm()
    }
  }

  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      setLoadingModalClaim(true)
      //   console.log("1_isClaim", isClaimRef.current);
      // console.log("1valueAddress", valueAddress);
      // console.log("pool", pool.pid);
      return callWithMarketGasPrice(poolContract, 'claimRewards', [pool.pid, valueAddress, isClaimRef.current])
    },
    onSuccess: async ({ receipt }) => {
      setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Claim reward successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      onDismiss()
      onSuccess()
      setLoadingModalClaim(false)
    },
  })

  return (
    <Modal
      style={depositModal}
      title={''}
      onDismiss={onDismiss}
      hideCloseButton={false}
      borderRadius={25}
      headerBackground="#24272A"
    >
      <Wrapper>
        <ClaimAmount>
          <Text fontWeight={700} fontSize="24px">
            Claim
          </Text>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              justifyContent: 'flex-start',
            }}
          >
            <Text fontSize="16px" fontWeight={600} paddingTop="6px">
              Current reward
            </Text>
            <StyledInput>
              ${' '}
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                end={Number(pool.currentReward * pool.rateBNB2USD)}
                decimals={pool.currentReward === 0 ? 0 : 2}
                duration={0.5}
                style={{ color: '#202327', fontWeight: '700' }}
              />
            </StyledInput>
          </div>
        </ClaimAmount>
        <BtnGroup>
          <StyledButton
            variant={'danger'}
            width="180px"
            disabled={isConfirming || (!isValidAmount ? true : false) || valueAddress === ''}
            onClick={() => handledClaim(false)}
          >
            {isConfirming ? (
              <ThreeDots className="loading">
                Claiming<span>.</span>
                <span>.</span>
                <span>.</span>
              </ThreeDots>
            ) : (
              'Claim OP'
            )}
          </StyledButton>
          <StyledButton
            variant={'danger'}
            width="180px"
            disabled={isConfirming || (!isValidAmount ? true : false) || valueAddress === ''}
            onClick={() => handledClaim(true)}
          >
            {isConfirming ? (
              <ThreeDots className="loading">
                Claiming<span>.</span>
                <span>.</span>
                <span>.</span>
              </ThreeDots>
            ) : (
              'Claim OPS'
            )}
          </StyledButton>
        </BtnGroup>
      </Wrapper>
    </Modal>
  )
}

export default ClaimPoolModal

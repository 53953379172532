import React from 'react';

const IsIcon = ({ src, alt = 'icon', w = 18, h = 18, curs = false}) => {
  const style = {
    width: `${w}px`,
    height: `${h}px`,
    cursor: curs ? 'pointer' : 'default',
  }

  return <img style={style} src={src} alt={alt}/>
}

export default IsIcon
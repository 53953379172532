import styled from 'styled-components'
import images from 'configs/images'
import { Link, useHistory } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from 'astore/UserContext'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const Wrapper = styled.div`
  // z-index: 999;
  padding-top: 79px;
  position: relative;
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  margin: 0 auto;
  * {
    font-family: Inter, sans-serif;
  }
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`
const Fixed = styled.div`
  position: fixed;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 0;
  align-self: stretch;
`
const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  color: rgba(26, 26, 26, 0.7);
`
const StyledItem = styled.div`
  cursor: pointer;
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 10px 8px 10px 16px;
  &.active {
    background: #fef1f1;
    border-right: 2px solid #dc2828;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
  &:hover {
    opacity: 0.8;
    color: rgba(26, 26, 26, 0.7);
  }
`
const ButtonShowMenuAccount = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 4px 24px;
  color: var(--mode-base-400, #a1a1aa);

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const link = [
  { title: 'Home', href: '/', img: `${images.home}` },
  // { title: 'Signal Hub', href: '#', img: `${images.Signal}` },
  // { title: 'News', href: '#', img: `${images.News}` },
  // { title: 'Ranking', href: '#', img: `${images.Ranking}` },
  { title: 'Pool', href: '/pools', img: `${images.iconPool}` },
  { title: 'Referral', href: '/referral', img: `${images.iconReferral}` },
  { title: 'OPS', href: '/ops', img: `${images.iconOPSF}` },
  { title: 'Tokenomic', href: '/tokenomic', img: `${images.iconTokenomic}` },
]

const SideBar = () => {
  const { userData } = useContext(UserContext)
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const history = useHistory()

  const arr = [
    { title: 'Your Wallet', href: `/profile?id=${userData?.userId}`, img: `${images.Wallet}` },
    // { title: 'Friends', href: '#', img: `${images.Friends}` },
    { title: 'Message', href: '/messages', img: `${images.Message}` },
  ]

  const handledNextPage = (_to) => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    if (_to.length > 0) {
      history.push(_to)
    }
  }

  return (
    <Wrapper className="left">
      <Fixed>
        {/* <Link to={'/'}>
          <Logo>
            <img src={images.logo} alt="" />
          </Logo>
        </Link> */}
        <Menu>
          {link.map((i, ix) => (
            <Link key={ix} to={i.href} className="active">
              <Item>
                <img src={i.img} alt="icon" />
                <p>{i.title}</p>
              </Item>
            </Link>
          ))}
        </Menu>
        <ButtonShowMenuAccount className="showmenuaccount">
          Account Access
          <a>
            <img src={images.dot} alt="" />
          </a>
        </ButtonShowMenuAccount>
        <Menu>
          {arr.map((i, ix) => (
            <StyledItem key={ix} onClick={() => handledNextPage(i.href)}>
              <Item>
                <img src={i.img} alt="icon" />
                <p>{i.title}</p>
              </Item>
            </StyledItem>
          ))}
        </Menu>
      </Fixed>
    </Wrapper>
  )
}

export default SideBar

import styled from 'styled-components'
import { Button, Heading } from '@pancakeswap/uikit'
import images from 'configs/images'

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  * {
    font-family: Inter, sans-serif;
  }
  background: #fff;
  display: flex;
  /* width: 460px; */
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
  border-radius: 8px;
`

const Title = styled(Heading)`
  color: var(--mode-alpha-900, #1a1a1a);

  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 158.333% */
`
const Follower = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .image {
    overflow: hidden;
    display: flex;
    width: 44px;
    height: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #e4e4e7;
    background: #fff;
    img{
        object-fit: cover;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .name {
      font-size: 16px;
      font-style: normal;
      /* font-weight: 500; */
      font-weight: bold;
      line-height: 24px; /* 150% */
    }
    .measure {
      display: flex;
      gap: 6px;
      .measure-item {
        display: flex;
        gap: 4px;

        span {
          color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));

          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
        .num {
          color: var(--mode-alpha-900, #1a1a1a);

          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
`
const FollowButton = styled(Button)`
  height: 36px;
  min-height: 36px;
  padding: 0 12px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
  background: #f4f4f5;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
`
const Follow = () => {
  return (
    <Wrapper>
      <Title>Who to follow</Title>
      <Follower>
        <div className="image"></div>
        <div className="info">
          <div className="name">Savannah Nguyen</div>
          <div className="measure">
            <div className="measure-item">
              <span>Follower</span>
              <div className="num">27.5k</div>
            </div>
            <div className="measure-item">
              <span>Signal</span>
              <div className="num">150</div>
            </div>
          </div>
        </div>
        <FollowButton>Follow</FollowButton>
      </Follower>
      <Follower>
        <div className="image"></div>
        <div className="info">
          <div className="name">Robert Fox</div>
          <div className="measure">
            <div className="measure-item">
              <span>Follower</span>
              <div className="num">27.5k</div>
            </div>
            <div className="measure-item">
              <span>Signal</span>
              <div className="num">150</div>
            </div>
          </div>
        </div>
        <FollowButton>Follow</FollowButton>
      </Follower>
      <Follower>
        <div className="image"><img src={images.user} alt="" /></div>
        <div className="info">
          <div className="name">Jenny Wilson</div>
          <div className="measure">
            <div className="measure-item">
              <span>Follower</span>
              <div className="num">27.5k</div>
            </div>
            <div className="measure-item">
              <span>Signal</span>
              <div className="num">150</div>
            </div>
          </div>
        </div>
        <FollowButton>Follow</FollowButton>
      </Follower>
      <Follower>
        <div className="image"></div>
        <div className="info">
          <div className="name">Bessie Cooper</div>
          <div className="measure">
            <div className="measure-item">
              <span>Follower</span>
              <div className="num">27.5k</div>
            </div>
            <div className="measure-item">
              <span>Signal</span>
              <div className="num">150</div>
            </div>
          </div>
        </div>
        <FollowButton>Follow</FollowButton>
      </Follower>
    </Wrapper>
  )
}

export default Follow

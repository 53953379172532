import axiosClient from './axiosClient'

const messagesApi = {
  getToken: () => {
    const url = `/zego/tokens`
    return axiosClient.get(url)
  },
}

export default messagesApi

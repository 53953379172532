import { PageMeta } from 'components/Layout/Page'
import { SEOPage } from 'components/SEOPage'
import images from 'configs/images'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  margin: 79px auto 0;
`
const Overview = styled.div`
  display: flex;
  padding: 60px 20px;
  flex-direction: column;
  align-items: center;
  gap: var(--space-0, 0px);
  align-self: stretch;
  border-radius: var(--Radius-radi-0, 0px);
  background: var(--mode-base-00, #fff);
  @media (max-width: 575px) {
    padding: 20px;
  }
`
const HowToGet = styled.div`
  max-width: 1128px;
  width: 100%;
  padding: 60px 20px;
  @media (max-width: 575px) {
    padding: 20px;
  }
`
const StyledHowToGet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  @media (max-width: 575px) {
    padding-top: 20px;
    flex-direction: column;
    gap: 20px;
  }
`
const PoolMining = styled.div`
  max-width: 1128px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .table-destop {
    display: block;
    @media (max-width: 575px) {
      display: none;
    }
  }
  .table-mobile {
    display: none;
    @media (max-width: 575px) {
      display: block;
    }
  }
`
const HowToGetContainer = styled.div`
  max-width: 456px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const HowToGetItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  p {
    color: var(--mode-alpha-900, #1a1a1a);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  span {
    color: var(--mode-alpha-900, #1a1a1a);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  a {
    color: var(--Colors-blue-600, #2463eb);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`
const Title = styled.p`
  color: var(--mode-alpha-900, #1a1a1a);
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const Text = styled.p`
  margin-top: 12px;
  color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 20px;
  }
`
const StyledOverViewImg = styled.div`
  position: relative;
  padding: 50px;
  @media (max-width: 575px) {
    padding: 20px;
  }
`
const ImgSupply = styled.img``
const StyledTitleOP = styled.div`
  position: absolute;
  top: 170px;
  left: 106px;
  text-align: center;
  @media (max-width: 575px) {
    top: 145px;
    left: 75px;
  }
  h4 {
    color: var(--brand-active, #dc2828);
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
  }
  p {
    padding-bottom: 8px;
    color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
`
const StyledSupply = styled.div`
  max-width: 1128px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
  @media (max-width: 1000px) {
    gap: 60px;
  }
  @media (max-width: 575px) {
    gap: 16px;
  }
`
const SupplyItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 6px;
  .item-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 575px) {
    max-width: 153px;
    width: 100%;
  }
  h4 {
    color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  p {
    color: var(--mode-alpha-900, #1a1a1a);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  span {
    color: var(--mode-alpha-700, rgba(26, 26, 26, 0.7));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`
const StyledPoolTab = styled.div`
  padding-bottom: 40px;
  @media (max-width: 575px) {
    padding-bottom: 20px;
  }
`

const PoolTab = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--mode-base-200, #e4e4e7);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  .active {
    background: var(--mode-base-100, #f4f4f5);
    color: #dc2828;
  }
`
const PoolTabItem = styled.div`
  display: flex;
  height: 36px;
  padding: var(--space-4, 8px) var(--space-7, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--space-4, 8px);
  background: var(--mode-base-100, #fff);
  cursor: pointer;

  color: rgba(26, 26, 26, 0.7);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  &:hover {
    background: var(--mode-base-100, #f4f4f5);
    color: #dc2828;
  }
  &:active {
    background: var(--mode-base-100, #f4f4f5);
    color: #dc2828;
  }
  @media (max-width: 575px) {
    padding: 18px;
  }
`
const StyledMiningSpeed = styled.div`
  margin: 38px 0 60px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 12px;
  border: 1px solid var(--mode-alpha-100, rgba(26, 26, 26, 0.1));
  @media (max-width: 1000px) {
    margin: 20px 0;
    border-radius: 0;
  }
  .styled-border-top {
    border-radius: 12px 0 0 12px;
    @media (max-width: 1000px) {
      border-radius: 0;
    }
  }
  .styled-border-botton {
    border-radius: 0 12px 12px 0;
    @media (max-width: 1000px) {
      border-radius: 0;
    }
  }
  }
`
const MiningSpeedItem = styled.div`
  display: flex;
  min-width: 160px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  background: var(--Colors-red-50, #fef1f1);
  @media (max-width: 575px) {
    gap: 16px;
  }
  p {
    color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  h4 {
    color: var(--mode-alpha-900, #1a1a1a);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
`
const StyledTable = styled.div`
max-width: 1128px;
width: 100%;
padding 20px 0;
  table,
  th,
  td {
    border: 1px solid var(--brand-active, #dc2828);
  }
  td {
    padding: 40px;
    @media (max-width: 575px) {
      padding: 20px;
    }
  }
  h4 {
    color: var(--mode-alpha-900, #1a1a1a);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  p {
    color: var(--mode-alpha-900, #1a1a1a);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  span {
    color: var(--mode-base-600, #52525B);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .styled-ratio {
    font-size: 24px;
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }
`

const dataSupply = [
  { id: 1, title: 'Seed Round', img: `${images.ratio1}`, quantity: '360,000 OPS', percent: '2.0%' },
  { id: 3, title: 'Market Develop Team', img: `${images.ratio3}`, quantity: '720,000 OPS', percent: '4.0%' },
  { id: 4, title: 'Liquidity', img: `${images.ratio4}`, quantity: '900,000 OPS', percent: '5.0%' },
  { id: 5, title: 'Founding Team', img: `${images.ratio5}`, quantity: '1,800,000 OPS', percent: '10.0%' },
  { id: 6, title: 'Advisor', img: `${images.ratio6}`, quantity: '540,000 OPS', percent: '3.0%' },
  { id: 7, title: 'Bounty / Marketing', img: `${images.ratio7}`, quantity: '540,000 OPS', percent: '3.0%' },
  { id: 8, title: 'Ecosystem Growth', img: `${images.ratio8}`, quantity: '540,000 OPS', percent: '3.0%' },
  { id: 9, title: 'Mining Rewards', img: `${images.ratio9}`, quantity: '12,600,000 OPS', percent: '70.0%' },
]

const Tokenomic = () => {
  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'tokenomic')
  return (
    <>
      <PageMeta meta={findMeta} />
      <Wrapper>
        <Overview>
          <Title>Tokenomic Overview</Title>
          <Text>
            Govern the economic aspects of a cryptocurrency or blockchain network, including the distribution, supply,
            and utility of tokens within the ecosystem.
          </Text>
          <StyledOverViewImg>
            <ImgSupply src={images.supplyopfsnew} alt="supply" />
            <StyledTitleOP>
              <p>Supply</p>
              <h4>18,000,000 OPS</h4>
            </StyledTitleOP>
          </StyledOverViewImg>

          <StyledSupply>
            {dataSupply.map((i, ix) => (
              <SupplyItem key={ix}>
                <img style={{ width: '24px', height: '24px' }} src={i.img} alt="icon" />
                <div className="item-title">
                  <h4>{i.title}</h4>
                  <p>
                    {i.quantity} <span>({i.percent})</span>
                  </p>
                </div>
              </SupplyItem>
            ))}
          </StyledSupply>
        </Overview>
        <HowToGet>
          <Title>How to get OPS token</Title>
          <StyledHowToGet>
            <HowToGetContainer>
              <img src={images.illus} alt="illus" />
            </HowToGetContainer>
            <HowToGetContainer>
              <HowToGetItem>
                <img src={images.mineop} alt="mine" />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <p>Mine OPS token</p>
                  <span>
                    Through pool Stater <Link to={'/pools'}>Visit Pool</Link>
                  </span>
                </div>
              </HowToGetItem>
              <HowToGetItem>
                <img src={images.earnop} alt="earn" />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <p>Earn profit and reward</p>
                  <span>By developing the pool standard system</span>
                </div>
              </HowToGetItem>
              <HowToGetItem>
                <img src={images.engageop} alt="engage" />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <p>Engage in Bounty and Airdrop programs</p>
                  <span>On OpSocialfi community platforms</span>
                </div>
              </HowToGetItem>
            </HowToGetContainer>
          </StyledHowToGet>
        </HowToGet>
        {/* <PoolMining>
        <StyledPoolTab>
          <PoolTab>
            <PoolTabItem className="active" style={{ borderRadius: '12px 0 0 12px' }}>
              Mining Speed
            </PoolTabItem>
            <PoolTabItem
              style={{ borderRadius: '0 12px 12px 0', borderLeft: '1px solid var(--mode-base-200, #e4e4e7)' }}
            >
              Rank Instruction
            </PoolTabItem>
          </PoolTab>
        </StyledPoolTab>
        <Title>Pool’s Mining Speed</Title>
        <Text style={{ textAlign: 'start' }}>
          The mining speed of the OP token depends on the highest pool you participate in.
        </Text>
        <StyledMiningSpeed>
          <MiningSpeedItem className="styled-border-top" style={{ background: 'var(--Colors-red-50, #FEF1F1)' }}>
            <p>Starter</p>
            <h4>0</h4>
          </MiningSpeedItem>
          <MiningSpeedItem style={{ background: 'var(--Colors-red-100, #FFDBDB)' }}>
            <p>Standard</p>
            <h4>+1.25</h4>
          </MiningSpeedItem>
          <MiningSpeedItem style={{ background: 'var(--Colors-red-300, #FCA6A6)' }}>
            <p>Pro</p>
            <h4>+1.5</h4>
          </MiningSpeedItem>
          <MiningSpeedItem style={{ background: 'var(--Colors-red-400, #FD6B6B)' }}>
            <p>Advance</p>
            <h4>$6,213.17</h4>
          </MiningSpeedItem>
          <MiningSpeedItem className="styled-border-botton" style={{ background: 'var(--Colors-red-500, #EF4343)' }}>
            <p style={{ color: '#fff' }}>Premium</p>
            <h4 style={{ color: '#fff' }}>+2</h4>
          </MiningSpeedItem>
        </StyledMiningSpeed>
        <StyledTable className="table-destop">
          <table style={{ maxWidth: '1128px', width: '100%' }}>
            <tbody>
              <tr>
                <td>
                  <p>
                    The regulation states that for every 1 dollar deposited, the user will receive a 20% conversion to
                    OP in contract mining
                  </p>
                </td>
                <td>
                  <p>
                    The claim speed of OP per day is 1/730, which is equivalent to 2 years. However, it will be
                    inversely proportional to the price of OP on the pool.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Pool Standard</h4>
                  <p>
                    ✦ Claim speed is increased by an additional <span>0.25%</span> mean <span>1/730 * 1.25</span>
                  </p>
                </td>
                <td>
                  <h4>Pool Pro</h4>
                  <p>
                    ✦ Claim speed is increased by an additional <span>0.25%</span> mean <span>1/730 * 1.25</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p>The claim speed is determined by the highest pool that the user participates in.</p>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p>
                    When the user reaches the target level, for example Silver, the claim speed increases by{' '}
                    <span className="styled-ratio" style={{ color: '#1a1a1a', fontWeight: '700' }}>
                      x0.5
                    </span>
                    &#8658; every day claim{' '}
                    <span className="styled-ratio" style={{ color: '#1a1a1a', fontWeight: '700' }}>
                      1/730 * 1.5 + (1/730 * 1.5 * 0.5)
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </StyledTable>
        <StyledTable className="table-mobile">
          <table style={{ maxWidth: '1128px', width: '100%' }}>
            <tbody>
              <tr>
                <td>
                  <p>
                    The regulation states that for every 1 dollar deposited, the user will receive a 20% conversion to
                    OP in contract mining
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    The claim speed of OP per day is 1/730, which is equivalent to 2 years. However, it will be
                    inversely proportional to the price of OP on the pool.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Pool Standard</h4>
                  <p>
                    ✦ Claim speed is increased by an additional <span>0.25%</span> mean <span>1/730 * 1.25</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Pool Pro</h4>
                  <p>
                    ✦ Claim speed is increased by an additional <span>0.25%</span> mean <span>1/730 * 1.25</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>The claim speed is determined by the highest pool that the user participates in.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    When the user reaches the target level, for example Silver, the claim speed increases by{' '}
                    <span className="styled-ratio" style={{ color: '#1a1a1a', fontWeight: '700' }}>
                      x0.5
                    </span>
                    &#8658; every day claim{' '}
                    <span className="styled-ratio" style={{ color: '#1a1a1a', fontWeight: '700' }}>
                      1/730 * 1.5 + (1/730 * 1.5 * 0.5)
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </StyledTable>
      </PoolMining> */}
      </Wrapper>
    </>
  )
}

export default Tokenomic

import { Heading, LinkExternal, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import Child from '../child'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { getBlockExploreLink } from 'utils'
import { formatEther } from '@ethersproject/units'

const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const CardReferral = styled.div`
  max-width: 1220px;
  min-height: 300px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #e6e6e6;
  @media screen and (max-width: 1440px) {
    width: 75%;
  }
  @media screen and (max-width: 900px) {
    padding: 0 16px;
    width: 100%;
  }
`
const FriendsList = styled.div`
  border-radius: 16px;
  background: var(--greyscale-grayscale-3, #141217);
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  margin-top: 48px;
  @media screen and (max-width: 575px) {
    padding: 40px 16px;
    margin-top: 28px;
  }
`
const CardFriends = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid var(--white-white-12, rgba(255, 255, 255, 0.12));
  background: var(--white-white-6, rgba(255, 255, 255, 0.06));
  backdrop-filter: blur(6px);
  width: 257px;
  @media screen and (max-width: 575px) {
    padding: 10px;
  }
`
const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.96px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #ff0a23 0%, #f77b88 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  color: rgba(173, 171, 178, 1);
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
  }
  .title {
    color: white;
    font-weight: 700;
  }
`
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  @media screen and (max-width: 1440px) {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
  }
  @media screen and (max-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    gap: 16px;
  }
`
const StyledItemChild = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: rgba(173, 171, 178, 1);
`
const StyledTotal = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
`
const Table = styled.table`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  tr {
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  th {
    width: 251px;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: rgba(119, 126, 144, 1);
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #1a1a1a;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #ccc;
  }

  @media screen and (max-width: 575px) {
    padding: 12px;
  }
`
const ChildItem = styled.tr`
  word-break: break-all;
  width: 100%;
  td {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
  }
  @media screen and (max-width: 575px) {
    td {
      font-size: 14px;
    }
  }
`

const PageDashboardV1 = ({ account, acountChild, refferCT, poolCT }) => {
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [total5Level, setTotal5Level] = useState(0)
  const [totalItemChild, setTotalItemChild] = useState(0)
  const [loadingTable, setLoadingTable] = useState(true)
  const [listChild, setListChild] = useState([])
  const [isShowChild, setIsShowChild] = useState([])

  const getTotalRefferChild = async (page, accountChild) => {
    const limit = 1000000
    const data = await Promise.all([
      refferCT.getTotalUserByUp(accountChild ? accountChild : account, limit, page),
      refferCT.userInfos(account),
    ])
    setTotal5Level(data[1].totalRefer5.toString())
    const arr = data[0].list.map((item) => item.user)
    setLoadingTable(false)
    const promises = arr.map(async (item) => {
      const lockResults = await poolCT.userTotalLockUSD(item)
      const referResults = await refferCT.userInfos(item)
      return {
        locked: lockResults.toString(),
        child: referResults.totalRefer.toString(),
      }
    })

    const results = await Promise.all(promises)

    setListChild(
      arr.map((item, ix) => {
        return {
          id: ix,
          account: item,
          locked: Number(formatEther(results[ix].locked)),
          child: results[ix].child,
        }
      }),
    )
    setTotalItemChild(Number(data[0].totalItem.toString()))
  }

  const handledAddChild = (_id) => {
    const newItem = { _id }
    setIsShowChild((currentItems) => [...currentItems, newItem])
  }

  const handleRemoveChild = (_id) => {
    setIsShowChild((currentItems) => currentItems.filter((item) => item._id !== _id))
  }

  useEffect(() => {
    getTotalRefferChild(0, account)
  }, [account])

  return (
    <CardReferral>
      <StyledHead>Referral Dashboard</StyledHead>
      <StyledSubtitle>
        Here, you can easily track the growth of your referral downline, monitor your direct staked amount, view a list
        of wallet addresses, and stay updated on the volume and token locked within the system.
      </StyledSubtitle>
      <FriendsList>
        {loadingTable ? (
          <ThreeDots style={{ textAlign: 'center' }} className="loading">
            Loading
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </ThreeDots>
        ) : (
          <>
            <Group>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '280px' }}>
                <StyledItemChild>F0:</StyledItemChild>
                <LinkExternal
                  fontSize={['13px', '13px', '15px', '16px', '16px']}
                  href={getBlockExploreLink(acountChild, 'address', CHAIN_ID)}
                  ellipsis={true}
                  color="#fff"
                  className="account"
                >
                  <p style={{ color: '#fff', fontSize: '18px' }}>{truncateHash(account, 8, 8)}</p>
                </LinkExternal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '147px' : isTablet ? '100%' : '280px' }}>
                <StyledItemChild>Total of F{acountChild.length}</StyledItemChild>
                <StyledTotal>{totalItemChild}</StyledTotal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '147px' : isTablet ? '100%' : '280px' }}>
                <StyledItemChild>Total refer downline</StyledItemChild>
                <StyledTotal>{total5Level}</StyledTotal>
              </CardFriends>
            </Group>
            <Table>
              <tbody>
                {listChild.map((item, index) => (
                  <React.Fragment key={index}>
                    <ChildItem>
                      <td
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '3px 0',
                        }}
                      >
                        <span
                          style={{
                            minWidth: isMobile ? '130px' : '154px',
                            maxWidth: isMobile ? '130px' : '154px',
                            width: '100%',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            fontSize: isMobile ? '16px' : '18px',
                            fontWeight: '600',
                          }}
                        >
                          <LinkExternal
                            fontSize={isMobile ? '14px' : '18px'}
                            href={getBlockExploreLink(item.account, 'address', CHAIN_ID)}
                            ellipsis={true}
                            style={{ color: '#fff' }}
                            color="#fff"
                          >
                            <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                              {item.account.substring(0, 2)}...{item.account.substring(item.account.length - 6)}
                            </p>
                          </LinkExternal>
                          {item.child > 0 && isShowChild.some((item) => item._id === index) === false && (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => (item.child > 0 ? handledAddChild(item.id) : '')}
                            >
                              <img src="/images/referral/circleDown.svg" style={{ fill: 'white', cursor: 'pointer' }} />
                            </div>
                          )}
                          {item.child > 0 && isShowChild.some((item) => item._id === index) && (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => (item.child > 0 ? handleRemoveChild(item.id) : '')}
                            >
                              <img src="/images/referral/circleUp.svg" style={{ fill: 'white', cursor: 'pointer' }} />
                            </div>
                          )}
                        </span>
                        <span style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          ($
                          <CountUp
                            style={{ color: 'rgba(255, 255, 255, 1)', fontSize: isMobile ? '16px' : '18px' }}
                            separator=","
                            start={0}
                            preserveValue
                            delay={0}
                            end={item.locked}
                            decimals={2}
                            duration={1}
                          />
                          )
                        </span>
                      </td>
                    </ChildItem>
                    {isShowChild.some((item) => item._id === index) && (
                      <ChildItem key={index + '-' + index}>
                        <td style={{ padding: 0 }}>
                          <Child referBy={item.account} />
                        </td>
                      </ChildItem>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </FriendsList>
    </CardReferral>
  )
}
export default PageDashboardV1

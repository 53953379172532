import { useTranslation } from '@pancakeswap/localization'
import { Button, ModalActiveKol, useModal, useToast } from '@pancakeswap/uikit'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getContract, getPoolsContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import poolAbi from 'config/abi/pools.json'
import { useSigner } from 'wagmi'
import OPAbi from 'config/abi/OP.json'
import { useWeb3React } from '../../../../packages/wagmi/src/useWeb3React'
import { formatEther } from '@ethersproject/units'
import useRegisterConfirmTransaction from 'hooks/useRegisterConfirmTransaction'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'

export const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
`
const Title = styled.div`
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #1a1a1a;
  text-align: center;
`
const Container = styled.div`
  width: 100%;
`
const TextAmount = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #1a1a1a;
  padding: 5px 2px;
`
const InputAmount = styled.input`
  width: 100%;
  height: 44px;
  padding: 0 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
  background: #2d2c33;
  &:focus {
    border: none;
    outline: none;
  }
`
const StyledBtn = styled.div`
  width: 100%;
`
const BtnActiveKOL = styled(Button)`
  margin: 10px 0 0;
  width: 100%;
  height: 42px;
  padding: 0 12px;
  border: none;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
  background: #3f5be5;
`
const Total = styled.div`
  width: 100%;
  padding: 8px 0 0;
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
  text-align: end;
`

export const ModalKol = ({ getRemainKol, onDismiss }) => {
  const { account, chainId } = useWeb3React()
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation()
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const getPoolContract = getPoolsContract(CHAIN_ID)
  const poolCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const OPContract = getContract({
    address: addresses.OP[CHAIN_ID],
    abi: OPAbi,
    chainId: CHAIN_ID,
    signer,
  })

  const [loading, setLoading] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [amount, setAmount] = useState('1')
  const [invalid, setInvalid] = useState(false)
  const [OP2USDT, setOP2USDT] = useState(0)
  const [isApprove, setIsApprove] = useState(false)

  const price = 20
  const min = 1
  const max = 1000000
  const maxApprove = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

  useEffect(() => {
    checkApprove()
  }, [])

  const onChangeAmount = async (_amount) => {
    if (!_amount) {
      setAmount('')
      setInvalid(true)
      return
    }
    if (!Number.isNaN(+_amount)) {
      setAmount(_amount)
      setInvalid(false)
    }
    await checkApproveKol(_amount)
  }

  const checkApproveKol = async (_amount) => {
    const allowance = await OPContract.allowance(account, poolCT.address)
    const n = Number(_amount) * price
    const USD2OP = await poolCT.USD2OP(n)

    if (Number(formatEther(allowance)) >= Number(USD2OP)) {
      setIsApprove(true)
    } else {
      setIsApprove(false)
    }
  }

  const checkApprove = async () => {
    const allowance = await OPContract.allowance(account, poolCT.address)
    const OP2USDT = await poolCT.OP2USDT()
    setOP2USDT(Number(formatEther(OP2USDT)))

    const n = Number(amount) * price
    const USD2OP = await poolCT.USD2OP(n)

    if (Number(formatEther(allowance)) >= Number(USD2OP)) {
      setIsApprove(true)
    } else {
      setIsApprove(false)
    }
  }

  const {
    isRegistting,
    isRegisterd,
    isRegisterConfirmed,
    isRegisterConfirming,
    handleRegister,
    handleRegisterConfirm,
  } = useRegisterConfirmTransaction({
    onRequiresRegister: async () => {
      return Promise.resolve(true)
    },
    onRegister: async () => {
      setLoading(true)

      return poolCT.activeKol(amount)
    },
    onRegisterSuccess: async ({ receipt }) => {
      toastSuccess(
        t('Congratulations, you have successfully Register Kol!'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash} />,
      )
      getRemainKol()
      onDismiss()
    },
    onConfirm: (receipt) => {
      return receipt
    },
    onSuccess: async ({ receipt }) => {
      setLoading(false)
      return Promise.resolve(1)
    },
  })

  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      setLoadingApprove(true)
      return callWithMarketGasPrice(OPContract, 'approve', [getPoolContract.address], [maxApprove])
    },
    onSuccess: async ({ receipt }) => {
      toastSuccess(t('Approve successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      checkApprove()
      setLoadingApprove(false)
    },
  })

  return (
    <ModalActiveKol title="" onDismiss={onDismiss}>
      <Wrapper>
        <Title>Active KOL</Title>
        <Container>
          <TextAmount>Amount:</TextAmount>
          <InputAmount
            min={min}
            max={max}
            type="number"
            value={amount}
            onChange={(e) => onChangeAmount(e.target.value)}
            placeholder="Enter amount"
          />
          <Total>
            {amount === '' ? 0 : amount} month = ${Number(amount) * price} ~{' '}
            {((Number(amount) * price) / Number(OP2USDT)).toFixed(3)}
            <span style={{ color: 'red' }}> OP</span>
          </Total>
        </Container>

        <StyledBtn>
          {invalid && (
            <div style={{ width: '100%', textAlign: 'center', color: 'red', fontWeight: '600' }}>Invalid amount</div>
          )}
          {!isApprove && (
            <BtnActiveKOL onClick={handleConfirm} disabled={loadingApprove || invalid}>
              {loadingApprove ? (
                <ThreeDots className="loading">
                  Approving<span>.</span>
                  <span>.</span>
                  <span>.</span>
                </ThreeDots>
              ) : (
                'Approve'
              )}
            </BtnActiveKOL>
          )}
          {isApprove && (
            <BtnActiveKOL onClick={handleRegister} disabled={loading || invalid}>
              {loading ? (
                <ThreeDots className="loading">
                  Activing<span>.</span>
                  <span>.</span>
                  <span>.</span>
                </ThreeDots>
              ) : (
                'Active'
              )}
            </BtnActiveKOL>
          )}
        </StyledBtn>
      </Wrapper>
    </ModalActiveKol>
  )
}

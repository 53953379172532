import { Input } from '@pancakeswap/uikit'
import { Link, BrowserRouter as Router } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { usePhishingBannerManager } from 'state/user/hooks'
import { MENU_HEIGHT } from '@pancakeswap/uikit/src/widgets/Menu/config'
import styled from 'styled-components'
import UserMenuV2 from './UserMenu/UserMenuV2'
import { NewNav } from './config/configV2'
import images from 'configs/images'
import SideBar from 'components/SideBar'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import Avatar from 'components/Avatar'
import { UserContext } from 'astore/UserContext'
import IsIcon from 'components/IsIcon'
import Tippy from '@tippyjs/react/headless'
import UserName from 'components/UserName'
import searchApi from 'api/searchApi'
import _debounce from 'lodash/debounce'
import notificationsApi from 'api/notificationsApi'
import DayTimeDifference from 'components/DayTimeDifference'
import Skeleton from 'react-loading-skeleton'
import { NavV1 } from './config/configNav'
import useAuth from 'hooks/useAuth'

const WrapperV1 = styled.div``

const Wrapper = styled.div`
  font-family: Inter, sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  overflow: hidden;
  .left {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }
  .right {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 6;
    @media screen and (max-width: 1100px) {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: 2;
      grid-column-end: 5;
    }
  }
  @media screen and (max-width: 1100px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  z-index: 20;
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  max-width: 80vw;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: top 0.2s;
  background: #fafbfc;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  @media screen and (max-width: 1100px) {
    max-width: 100%;
  }
`
const FixedContainerV1 = styled.div<{ showMenu: boolean; height: number }>`
  z-index: 20;
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  max-width: 100vw;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: top 0.2s;
  background: #fafbfc;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  @media screen and (max-width: 1100px) {
    max-width: 100%;
  }
`
const Head = styled.div`
  color: var(--mode-alpha-900, #1a1a1a);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  @media screen and (max-width: 820px) {
    display: none;
  }
`
const Desc = styled.div`
  color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  @media screen and (max-width: 820px) {
    display: none;
  }
`
const ExtendInput = styled(Input)`
  display: flex;
  width: 306px;
  min-height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  flex-shrink: 0;
  background: none;
  border-color: #e4e4e7;
  color: #1a1a1a;
  &::placeholder {
    color: rgba(26, 26, 26, 0.7);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  @media screen and (max-width: 820px) {
    display: none;
  }
`
const UserButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  @media screen and (max-width: 575px) {
    display: none;
  }
`
const Notify = styled.div`
  height: 100%;
  display: flex;
  height: 44px;
  min-height: 44px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width: 820px) {
    display: none;
  }
`
const LogoIcon = styled.i`
  display: none;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 820px) {
    display: flex;
  }
`
const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const SearchIcon = styled.i`
  display: none;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 820px) {
    display: flex;
  }
`
const SearchResult = styled.div`
  max-height: min((100vh - 96px) - 60px, 734px);
  width: 100%;
  width: 306px;
  min-height: 44px;
  padding: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  color: #000;
  background: #fff;
`
const SearchItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  border-radius: 4px;
  gap: 8px;
  &:hover {
    background: #f1f1f1;
  }
`
const ListItem = styled.div`
  max-height: min((100vh - 96px) - 60px, 734px);
  width: 100%;
  width: 306px;
  min-height: 44px;
  padding: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  color: #000;
  background: #fff;
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  border-radius: 4px;
  gap: 8px;
  &:hover {
    background: #f1f1f1;
  }
`
const StyledNotification = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #e4e6eb;
  &:hover {
    background: #dcdcdc;
  }
`
const Notifitions = styled.div`
  overflow: auto;
  max-height 80vh;
  height: 100%;
  max-height: min((100vh - 96px) - 60px, 734px);
  width: 360px;
  min-height: 44px;
  border-radius: 8px;
  color: #000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
    /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
const NotifyList = styled.div`
  padding: 8px;
  border-radius: 8px;
  color: #000;
  background: #fff;
`
const NotifyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  padding: 6px;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
const NotifyDescription = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #65676b;
`
const TitleNotify = styled.p`
  width: 100%;
  padding: 12px 16px 6px;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
`
const StyledDay = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #65676b;
`
const StyledName = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #65676b;
`
const StyledDescription = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #65676b;
`
const TabBar = styled.div`
  width: 100%;
  padding: 0 16px;
`
const TabBarItem = styled.div`
  max-width: 80px;
  width: 100%;
  padding: 0 16px;
  height: 34px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 24px;
  background: #fef1f1;
  color: #dc2828;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const StyledBtn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
`
const BtnAccept = styled.button`
  width: 100px;
  height: 28px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  color: #fff;
  background: #dc2828;
  cursor: pointer;
`
const BtnDeleted = styled.button`
  width: 100px;
  height: 28px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  color: #000;
  background: #ccc;
  cursor: pointer;
`
const StyledListItem = styled.div`
  display: flex;
  gap: 16px;
  .active {
    color: #202327;
    border-bottom: 3px solid #ff0420;
  }
  @media (max-width: 880px) {
    display: none;
  }
`
const StyledMenuItem = styled.div`
  max-width: 151px;
  height: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  color: var(--neutral-600-gray, #68778d);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  border-bottom: 3px solid #fafbfc;

  &:hover {
    color: #202327;
    border-bottom: 3px solid #ff0420;
  }
  p {
    color: var(--neutral-600-gray, #68778d);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
`

const data = [
  {
    img: '',
    link: '/pools',
    label: 'Pools',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/referral',
    label: 'Referral',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/ops',
    label: 'OPS',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/commissions',
    label: 'Commissions',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/tokenomic',
    label: 'Tokenomic',
    dropdownMenu: [],
  },
]

const MenuV2 = () => {
  // const { userData } = useContext(UserContext)
  // const { account, chainId } = useWeb3React()
  // const { logout } = useAuth()
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const banner = showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />
  const totalTopMenuHeight = banner ? MENU_HEIGHT : MENU_HEIGHT
  // const [isTippyVisible, setIsTippyVisible] = useState(false)
  // const [isLoading, setIsLoading] = useState(false)
  // const [searchResultAcc, setSearchResultAcc] = useState([])
  // const [searchResultGr, setSearchResultGr] = useState([])
  // const [listNotifitions, setNotifitions] = useState([])
  // const [pageOderNotifition, setPageOderNotifition] = useState(1)
  const [activeIndex, setActiveIndex] = useState(null)
  const [active, setActive] = useState('')

  // const fetchListSearch = async (keyword) => {
  //   try {
  //     if (keyword.length > 0) {
  //       const params = {
  //         keyword: keyword,
  //         limit: 5,
  //       }
  //       const response = await searchApi.getSuggest(params)
  //       setSearchResultAcc(response.data.users)
  //       setSearchResultGr(response.data.groups)
  //     }
  //   } catch (error) {
  //     console.log('Failed to fetch Search: ', error)
  //   }
  // }

  // const fetchNotification = async () => {
  //   try {
  //     setIsLoading(true)
  //     const params = {
  //       pageSize: 10,
  //       pageIndex: pageOderNotifition,
  //     }
  //     const response = await notificationsApi.get(params)
  //     setNotifitions(response.data)
  //     setIsLoading(false)

  //     console.log('response', response)
  //   } catch (error) {
  //     console.log('Failed to fetch Notification: ', error)
  //   }
  // }

  // const handleInputChange = _debounce((keyword) => {
  //   if (keyword.length <= 0) {
  //     setSearchResultAcc([])
  //     setSearchResultGr([])
  //     setIsTippyVisible(false)
  //     return
  //   }
  //   fetchListSearch(keyword)
  //   setIsTippyVisible(true)
  // }, 300)

  // const handleInputClick = () => {
  //   setIsTippyVisible(!isTippyVisible)
  // }
  // const handledPush = (_id) => {
  //   setIsTippyVisible(!isTippyVisible)
  // }

  useEffect(() => {
    const pathname = window.location.pathname
    if (pathname === '/') {
      setActive('/pools')
    } else {
      setActive(pathname)
    }
  }, [window.location.pathname, active])

  return (
    <>
      <WrapperV1>
        <Router>
          <FixedContainerV1 showMenu={true} height={totalTopMenuHeight}>
            <StyledLogo>
              <Link to={'/'} onClick={() => setActive('/')}>
                <img src="/images/logo/logo-opsocialfi.svg" alt="logo" />
              </Link>
            </StyledLogo>
            <StyledListItem>
              {data.map((i, ix) => (
                <Link key={ix} to={i.link} onClick={() => setActive(i.link)}>
                  <StyledMenuItem className={active === i.link ? 'active' : ''}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <p>{i.label}</p>
                    </div>
                  </StyledMenuItem>
                </Link>
              ))}
            </StyledListItem>
            <UserMenuV2 />
          </FixedContainerV1>
          <NavV1 />
        </Router>
      </WrapperV1>
      {/* <Wrapper>
        <Router>
          <SideBar />
          <Container className="right">
            <FixedContainer className="right" showMenu={true} height={totalTopMenuHeight}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Head>Home</Head>
                <Desc>Looking for stream and up to date</Desc>
                <Link to={'/'}>
                  <LogoIcon>
                    <img src="/images/logo/logo-opsocialfi.svg" alt="logo" />
                  </LogoIcon>
                </Link>
              </div>
              <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                <div>
                  <Tippy
                    content="Search"
                    interactive
                    visible={isTippyVisible}
                    onClickOutside={() => setIsTippyVisible(false)}
                    render={(attrs) => (
                      <SearchResult tabIndex={-1} {...attrs}>
                        {searchResultAcc.length > 0 && (
                          <>
                            {searchResultAcc.map((i, ix) => (
                              <Link to={`/profile?id=${i._id}`} key={ix}>
                                <SearchItem onClick={() => handledPush(i._id)}>
                                  <Avatar width={32} height={32} data={i.avatar} />
                                  <UserName data={i.name} font={14} />
                                </SearchItem>
                              </Link>
                            ))}
                          </>
                        )}
                        {searchResultGr.length > 0 && (
                          <>
                            <div>Groups</div>
                            {searchResultGr.map((i, ix) => (
                              <SearchItem key={ix}>
                                <Avatar width={32} height={32} data={images.avatar_none} />
                                <UserName data={'Nguyen Van A'} font={14} />
                              </SearchItem>
                            ))}
                          </>
                        )}
                      </SearchResult>
                    )}
                  >
                    <ExtendInput
                      onChange={(e) => handleInputChange(e.target.value)}
                      onClick={handleInputClick}
                      type="text"
                      placeholder="Search address, news"
                    />
                  </Tippy>
                </div>
                <SearchIcon>
                  <IsIcon src={images.icon_search} alt="search" />
                </SearchIcon>
                <UserMenuV2 />

                {account && (
                  <>
                    <Notify>
                      <Tippy
                        content="Notifitions"
                        interactive
                        offset={[0, 18]}
                        trigger="click"
                        render={(attrs) => (
                          <Notifitions tabIndex={-1} {...attrs}>
                            <TitleNotify>Notifitions</TitleNotify>
                            <TabBar>
                              <TabBarItem>All</TabBarItem>
                            </TabBar>
                            {isLoading ? (
                              <NotifyList>
                                <NotifyItem>
                                  <Skeleton width={52} height={52} borderRadius={50} />
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                    <NotifyDescription>
                                      <Skeleton width={200} height={15} borderRadius={24} />
                                      <Skeleton width={100} height={15} borderRadius={24} />
                                    </NotifyDescription>
                                  </div>
                                </NotifyItem>
                                <NotifyItem>
                                  <Skeleton width={52} height={52} borderRadius={50} />
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                    <NotifyDescription>
                                      <Skeleton width={200} height={15} borderRadius={24} />
                                      <Skeleton width={100} height={15} borderRadius={24} />
                                    </NotifyDescription>
                                  </div>
                                </NotifyItem>
                              </NotifyList>
                            ) : (
                              <NotifyList>
                                {listNotifitions.map((i, ix) => (
                                  <React.Fragment key={ix}>
                                    {i.entityType === 'friend_connect' && (
                                      <NotifyItem>
                                        <Avatar width={52} height={52} data={i.actor.avatar} />
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                          <NotifyDescription>
                                            <StyledName>{i.actor.name}</StyledName>
                                            <StyledDescription> has sent you a friend request.</StyledDescription>
                                          </NotifyDescription>
                                          <StyledDay>
                                            <DayTimeDifference At={i.createdAt} />
                                          </StyledDay>
                                          <StyledBtn>
                                            <BtnAccept>Accept</BtnAccept>
                                            <BtnDeleted>Delete</BtnDeleted>
                                          </StyledBtn>
                                        </div>
                                      </NotifyItem>
                                    )}
                                    {i.entityType === 'friend_feedback' && (
                                      <NotifyItem>
                                        <Avatar width={52} height={52} data={i.actor.avatar} />
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                          <NotifyDescription>
                                            <StyledName>{i.actor.name}</StyledName>
                                            <StyledDescription> has accepted your request.</StyledDescription>
                                          </NotifyDescription>
                                          <StyledDay>
                                            <DayTimeDifference At={i.createdAt} />
                                          </StyledDay>
                                        </div>
                                      </NotifyItem>
                                    )}
                                  </React.Fragment>
                                ))}
                              </NotifyList>
                            )}
                          </Notifitions>
                        )}
                      >
                        <StyledNotification onClick={fetchNotification}>
                          <img
                            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                            src={images.notification}
                            alt="notifi"
                          />
                        </StyledNotification>
                      </Tippy>
                    </Notify>
                    <UserButton>
                      <Avatar
                        data={userData || userData?.avatar !== '' ? userData?.avatar : ''}
                        width={44}
                        height={44}
                      />
                      <>
                        <Tippy
                          content="Down"
                          interactive
                          trigger="click"
                          render={(attrs) => (
                            <ListItem tabIndex={-1} {...attrs}>
                              <Item></Item>
                            </ListItem>
                          )}
                        >
                          <img
                            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                            src={images.down}
                            alt="down"
                          />
                        </Tippy>
                      </>
                    </UserButton>
                  </>
                )}
              </div>
            </FixedContainer>
            <NewNav />
          </Container>
        </Router>
      </Wrapper> */}
    </>
  )
}

export default MenuV2

import { useTranslation } from '@pancakeswap/localization'
import { Grid, ModalPostStatus, useToast } from '@pancakeswap/uikit'
import postsApi from 'api/postApi'
import axios from 'axios'
import Avatar from 'components/Avatar'
import DayTimeDifference from 'components/DayTimeDifference'
import CircleLoader from 'components/Loader/CircleLoader'
import { StyledImages } from 'components/StyledImages'
import StyledUserName from 'components/StyledUserName'
import UserName from 'components/UserName'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledBody = styled.div`
  overflow: auto;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
const Body = styled.div`
  max-height: 400px;
  width: auto;
  height: 100%;
`
const BtnPost = styled.button`
  width: 100%;
  height: 36px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 600;
  background: rgb(220, 40, 40);
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 1;
    background: #ccc;
  }
`
const StyledSelect = styled.select`
  padding: 1px;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ccc;
  color: #000;
  &:focus-visible {
    outline: none;
  }
`
const StyledShare = styled.div`
  max-width: 645px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
`
const User = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .status {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      .text {
        color: var(--mode-alpha-400, rgba(26, 26, 26, 0.4));
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
`
const Content = styled.div`
  padding: 0 12px 8px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--mode-alpha-900, #1a1a1a);
`
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 45px;
  font-size: 16px;
  line-height: 18px;
  border: none;
  border-radius: none;
  background: none;
  padding: 10px 0;
  color: #000;
  &:focus-visible {
    outline: none;
  }
`
const HeaderPost = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const Shared = styled.div`
  width: 100%;
  @media (max-width: 899px) {
    padding: 0 12px;
  }
`
const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  .fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(244, 244, 244, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: none;
  }
  @media (max-width: 575px) {
    border-radius: 0;
  }
`
const OptionPost = styled.div`
  width: 100%;
  height: auto;
`
const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`
const StyledChooseFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0;
  border-radius: 8px;
  border: 1px solid #b1b1b1;
  input[type='file'] {
    display: none;
  }
  .custom-file-2 {
    margin: 0;
    padding: 6px 20px;
    cursor: pointer;
  }
`

export const EditStatusModal = ({ userData, dataEdit, listDataPost, setListDataPost, onDismiss }) => {
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [loading, setLoading] = React.useState(false)
  const [audience, setAudience] = React.useState(dataEdit.audience)
  const [content, setContent] = React.useState(dataEdit.content)
  const [imgHash, setImgHash] = useState(dataEdit.images)

  const sendFileToIPFS = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      const resFile = await axios({
        method: 'post',
        url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
        data: formData,
        headers: {
          pinata_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_KEY}`,
          pinata_secret_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_SECRET}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`
      setImgHash([...imgHash, ImgHash])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('Error sending File to IPFS: ')
      console.log(error)
    }
  }

  const fetchEdit = async () => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      const body = {
        content: content,
        audience: audience,
        images: imgHash,
      }
      const response = await postsApi.editpost(dataEdit._id, body)
      const indexToEdit = listDataPost.findIndex((post) => post._id === dataEdit._id)

      if (indexToEdit !== -1) {
        const updatedArray = [...listDataPost]
        updatedArray[indexToEdit].content = content
        updatedArray[indexToEdit].audience = audience
        updatedArray[indexToEdit].images = imgHash
        setListDataPost(updatedArray)
        onDismiss()
        toastSuccess(t('Success'), t('Edit articles Success'))
      } else {
        toastError(t('Error'), t('The article does not exist in the array.'))
      }
    } catch (error) {
      toastError(t('Error'), t('Please try again'))
      console.log('Failed to fetch list posts: ', error)
    }
  }

  return (
    <ModalPostStatus title="Edit articles" color="#fff" onDismiss={onDismiss}>
      {dataEdit && (
        <Wrapper>
          <Grid style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', paddingBottom: ' 12px' }}>
              <Avatar data={dataEdit.authorInfo.avatar} width={44} height={44} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <UserName data={dataEdit.authorInfo.name} />
                <StyledSelect value={audience} onChange={(e) => setAudience(e.target.value)}>
                  <option value="everyone">Everyone</option>
                  <option value="onlyMe">Only Me</option>
                  <option value="friends">Friends</option>
                </StyledSelect>
              </div>
            </div>
            <StyledBody>
              <Body>
                <StyledTextarea
                  placeholder="What's on your mind?"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />

                {dataEdit.isShared === false ? (
                  <>
                    {imgHash.length > 0 && (
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          padding: '8px',
                          border: '1px solid #ccc',
                        }}
                      >
                        <StyledImages data={imgHash} setData={setImgHash} cancel={true} />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {dataEdit.sharedInfo && (
                      <Shared>
                        <StyledShare>
                          <div style={{ width: '100%' }}>
                            {dataEdit.sharedInfo.images.length > 0 && (
                              <StyledImages data={dataEdit.sharedInfo.images} setData={null} />
                            )}
                          </div>
                          <Container>
                            <HeaderPost>
                              <User>
                                <Avatar data={dataEdit.sharedInfo.authorInfo.avatar} width={44} height={44} />
                                <div className="userInfo">
                                  <StyledUserName
                                    link={`/profile?id=${dataEdit.sharedInfo.authorInfo._id}`}
                                    data={dataEdit.sharedInfo.authorInfo.name}
                                  />
                                  <div className="status">
                                    <span className="text">
                                      <DayTimeDifference At={dataEdit.sharedInfo.createdAt} />
                                    </span>
                                    {/* <IsIcon
                                    w={14}
                                    h={14}
                                    src={status.find((st) => st.title === dataEdit.sharedInfo.audience)?.icon}
                                    curs={true}
                                  /> */}
                                  </div>
                                </div>
                              </User>
                            </HeaderPost>
                            <Content>{dataEdit.sharedInfo.content}</Content>
                          </Container>
                        </StyledShare>
                      </Shared>
                    )}
                  </>
                )}
              </Body>
            </StyledBody>
          </Grid>
          {dataEdit.isShared === false && (
            <OptionPost>
              {loading ? (
                <StyledLoading>
                  <CircleLoader size="48px" />
                </StyledLoading>
              ) : (
                <StyledChooseFile>
                  <label htmlFor="UpImgEditPost" className="custom-file-2">
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src="./images/icons/chooseimg.png"
                      alt="choose file"
                    />
                  </label>
                  <input
                    type="file"
                    id="UpImgEditPost"
                    name="files[]"
                    accept="image/*"
                    onChange={(e) => sendFileToIPFS(e)}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                </StyledChooseFile>
              )}
            </OptionPost>
          )}

          <BtnPost
            disabled={content.length <= 0 && imgHash.length <= 0 && dataEdit.isShared === false}
            onClick={fetchEdit}
          >
            Edit articles
          </BtnPost>
        </Wrapper>
      )}
    </ModalPostStatus>
  )
}

import React, { useContext, useState } from 'react'
import TopSignal from '../TopSignal'
import HeadHome from './components/Head'
import LoginSection from './components/LoginSection'
import Post from '../../../../components/Post/ListPost'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import postsApi from 'api/postApi'
import { UserContext } from 'astore/UserContext'

const Section = ({ userData }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { account } = useActiveWeb3React()
  const [listDataPost, setListDataPost] = React.useState([])
  const [pageOrder, setPageOrder] = React.useState(1)

  React.useEffect(() => {
    const fethchListPost = async () => {
      try {
        setIsLoading(true)
        const params = {
          pageSize: 21,
          pageIndex: pageOrder,
        }
        const response = await postsApi.getAll(params)
        if (response.data.length > 0) {
          const filteredPosts = response.data.filter((post) => post.audience !== 'onlyMe')
          setListDataPost(listDataPost.concat(filteredPosts))
          setIsLoading(false)
        } else {
          setIsLoading(false)
          return
        }
        // console.log('response', response.data)
      } catch (error) {
        setIsLoading(false)
        console.log('Failed to fetch list posts: ', error)
      }
    }
    fethchListPost()
  }, [pageOrder])

  return (
    <>
      {!account ? (
        <LoginSection />
      ) : (
        <HeadHome userData={userData} listDataPost={listDataPost} setListDataPost={setListDataPost} />
      )}
      <TopSignal />
      <Post
        userData={userData}
        listDataPost={listDataPost}
        setListDataPost={setListDataPost}
        pageOrder={pageOrder}
        setPageOrder={setPageOrder}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default Section

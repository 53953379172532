export const SEOPage = [
  {
    key: 'home',
    title: 'OP SocialFi - Home',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com',
      type: 'website',
      title: 'Home',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Home',
          url: 'https://opsocialfi.com/images/thumnal.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'pools',
    title: 'OP SocialFi - Pools',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/pools',
      type: 'website',
      title: 'Pools',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/pools.png',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'referral',
    title: 'OP SocialFi - Referral',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/referral',
      type: 'website',
      title: 'Referral',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/referral.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'ops',
    title: 'OP SocialFi - OPS',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/ops',
      type: 'website',
      title: 'OPS',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/opsf.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'tokenomic',
    title: 'OP SocialFi - Tokenomic',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/tokenomic',
      type: 'website',
      title: 'Tokenomic',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/tokenomic.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'profile',
    title: 'OP SocialFi - Profile',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/profile',
      type: 'website',
      title: 'Profile',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/tokenomic.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'message',
    title: 'OP SocialFi - Message',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/message',
      type: 'website',
      title: 'Message',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/tokenomic.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
  {
    key: 'post-details',
    title: 'OP SocialFi - Posts Details',
    noindex: false,
    nofollow: false,
    canonical: 'https://opsocialfi.com',
    openGraph: {
      url: 'https://opsocialfi.com/post',
      type: 'website',
      title: 'Posts',
      locale: 'vi_VN',
      images: [
        {
          alt: 'Pools',
          url: 'https://opsocialfi.com/images/detail.jpg',
          width: 800,
          height: 600,
        },
      ],
      site_name: 'OP SocialFi',
      description:
        'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    },
    twitter: {
      handle: '@opsocialfi',
      site: '@opsocialfi',
      cardType: 'summary_large_image',
    },
    description:
      'Customers interesteds in blockchain-related investments and financial services can use Op socialFi, a system that empowers the community',
    robotsProps: {},
  },
]

// export const handleSEOTitle = (AppId: string, hospitalName: string) => {
//   switch (AppId) {
//     case 'umc':
//       return hospitalName
//     case 'hoanmytd':
//       return `Đặt khám trực tuyến tại ${hospitalName}`
//     case 'nhidong1':
//     case 'dalieu':
//       return `Medpro - Đặt lịch khám bệnh tại ${hospitalName}`
//     default:
//       return `Đăng ký khám, đặt lịch khám hơn 30 bệnh viện lớn tại Việt Nam - Medpro`
//   }
// }

// export const handleSEOTitleSubPage = (partnerId: string, partnerName: string, title: string) => {
//   switch (partnerId) {
//     case 'medpro':
//       return `Medpro - Đặt lịch khám bệnh | ${title}`
//     default:
//       return `${partnerName} | ${title}`
//   }
// }

import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 79px;
  background-color: #f4f4f5;
`
export const StyledAction = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #ccc;
  background-color: #fff;
`
export const StyledPost = styled.div`
  width: 100%;
  max-width: 1088px;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media (max-width: 850px) {
    flex-direction: column;
  }
  @media (max-width: 575px) {
    padding: 12px 0;
  }
`
export const ListPost = styled.div`
  max-width: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // gap: 12px;
  @media (max-width: 850px) {
    max-width: 500px;
    margin: 0 auto;
  }
`
export const UserInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
`
export const BtnPost = styled.button`
  width: 100%;
  height: 42px;
  display: flex;
  padding: 6px 18px;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid #e4e4e7;
  background: #fff;
  color: #1a1a1a;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: text;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    background-color: #f0f0f0;
  }
  @media (max-width: 575px) {
    font-size: 15px;
  }
`
export const Left = styled.div`
  width: 80%;
  height: 100%;
`
export const Right = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const More = styled.div`
  width: 48px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #ccc;
`
export const Info = styled.div`
  min-height: 45px;
  width: 100%;
  height: auto;
  display: flex;
  flexdirection: row;
  alignitems: center;
  justifycontent: center;
  gap: 12px;
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media (max-width: 575px) {
    border-radius: 0px;
  }
`
export const TitleListPost = styled.p`
  padding: 12px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  color: #3a3a3a;
`
export const TitlePost = styled.p`
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
`

import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Th, Td, Text, LinkExternal, Button } from '@pancakeswap/uikit'
import CountUp from 'react-countup'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import moment from 'moment'
import { formatEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import { isMobile } from 'react-device-detect'
import { shortenURL } from 'views/Referral/util'
import { getBlockExploreLink } from 'utils'

const WrapperTable = styled.div`
  width: 100%;
`
const TableScroll = styled.div`
  width: auto;
  &.scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &.scroll::-webkit-scrollbar-track {
    background-color: #1a1a1a;
  }
  &.scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #ccc;
  }
  @media screen and (max-width: 1070px) {
    overflow-x: auto;
    width: 100%;
  }
  Th {
    padding: 20px 10px;
    @media screen and (max-width: 896px) {
      padding: 20px 25px;
    }
    @media screen and (max-width: 530px) {
      padding: 20px 16px;
    }
  }
  Td {
    border-bottom: 0;
    padding: 10px 22px;
    @media screen and (max-width: 730px) {
      padding: 10px 10px;
    }
  }
  .imagesvector {
    padding: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--#1a1a1a-#1a1a1a-8, rgba(255, 255, 255, 0.08));
  }
`
const TableHeader = styled.span`
  width: 100%;
  font-size: 24px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
`
const TablePool = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--#1a1a1a-#1a1a1a-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  table > thead {
    tr {
      font-weight: 300;
      border-bottom: 1px solid black;
    }
  }

  @media screen and (max-width: 360px) {
    width: 100%;
  }
`
const AmountData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`
const StyledPage = styled.div`
  width: 100%;
  height: 50px;
  background: rgba(23, 19, 32, 0.5);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  border-radius: 0 0 12px 12px;

  .active {
    background: #fff;
    color: #000;
  }
`
const BtnPage = styled(Button)`
  max-width: 80px;
  width: 100%;
  height: 30px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px soild #fff;
  cursor: pointer;
  background: #fff;
  border: none;
  &:disabled {
    border: 1px soild #f1f1f1;
    background: none !important;
  }
`
const BtnPageNumber = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  cursor: pointer;
`
const StyledPageNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  @media (max-width: 350px) {
    display: none;
  }
`

const responsiveTextTH = ['11px', '12px', '16px', '16px', '16px']
const responsiveTextSizeBNB = ['14px', '14px', '14px', '16px', '16px']
const responsiveTextSizeHeader = ['18px', '18px', '24px', '28px', '28px']

const CommClaimHistory = ({ userClaimComm, usersClaimed, userClaimedCommLength, ...props }) => {
  const { account, chainId } = useActiveWeb3React()
  const [dataTable, setDataTable] = useState([])
  const currentPage = useRef(1)
  const lastPage = useRef(5)

  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(userClaimComm.length / lastPage.current); i++) {
    pageNumbers.push(i)
  }

  const getSortData = async (r) => {
    currentPage.current = r
    const lastPageIndex = lastPage.current * r
    let resurt = []
    resurt = await userClaimComm.slice(lastPageIndex - lastPage.current, lastPageIndex)
    setDataTable(resurt)
    return resurt
  }

  useEffect(() => {
    getSortData(currentPage.current)
  }, [])

  const renderClaimHistory = () => {
    return <>{renderHistory()}</>
  }

  const renderHistory = () => {
    return (
      <>
        {userClaimedCommLength > 0 &&
          userClaimComm.map((i, index) => {
            return (
              <tr key={index}>
                <Td textAlign={'center'}>
                  <Text
                    style={{
                      fontSize: isMobile ? '14px' : '16px',
                      color: '#1a1a1a',
                      lineHeight: '18px',
                      fontWeight: '700',
                    }}
                  >
                    {chainId === 97 ? (
                      <>
                        <p style={{ fontSize: isMobile ? '14px' : '16px' }}>
                          {moment.unix(Number(i.date)).format('DD/MM/YYYY')}
                        </p>
                        <p style={{ fontSize: isMobile ? '10px' : '12px' }}>
                          {moment.unix(Number(i.date)).format('HH:mm:ss')}
                        </p>
                      </>
                    ) : (
                      <>
                        <p style={{ fontSize: isMobile ? '14px' : '16px' }}>
                          {moment.unix(Number(i.date)).format('DD/MM/YYYY')}
                        </p>
                        <p style={{ fontSize: isMobile ? '10px' : '12px' }}>
                          {moment.unix(Number(i.date)).format('HH:mm:ss')}
                        </p>
                      </>
                    )}
                  </Text>
                </Td>

                <Td textAlign={'right'}>
                  {userClaimComm.totalRewardUSD + userClaimComm.remainRewardUSD === 0 ? (
                    <Text style={{ fontSize: isMobile ? '14px' : '16px', color: '#1a1a1a', fontWeight: '700' }}>
                      $0
                    </Text>
                  ) : (
                    <AmountData>
                      <Text
                        style={{
                          fontSize: isMobile ? '14px' : '16px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          fontWeight: '700',
                          color: '#1a1a1a',
                        }}
                      >
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number(formatEther(i.amountUSD))}
                          decimals={Number(formatEther(i.amountUSD)) > 0 ? 2 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Text>
                    </AmountData>
                  )}
                </Td>
                {/* <Td textAlign={'right'} style={{ width: '120px'}}>
                  {i.amount === 0 ? (
                    <Text style={{ fontSize: isMobile ? '14px' : '16px', color: '#1a1a1a' }}>
                      0x...00
                    </Text>
                  ) : (
                    <AmountData>
                      <Text style={{ fontSize: isMobile ? '14px' : '16px', fontWeight: "700", color: '#1a1a1a' }}>
                      $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number(formatEther(i.amount))}
                          decimals={Number(formatEther(i.amount)) > 0 ? 2 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Text>
                    </AmountData>
                  )}
                </Td> */}
              </tr>
            )
          })}
      </>
    )
  }

  return (
    <WrapperTable>
      <TableHeader>
        <Text style={{ fontSize: '24px', fontWeight: '700', color: '#1a1a1a' }} fontSize={responsiveTextSizeHeader}>
          Claimed
        </Text>
      </TableHeader>
      <TablePool>
        <TableScroll className="scroll">
          <table style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <thead>
              <tr>
                <Th textAlign="left">
                  <Text
                    className="th"
                    style={{ color: '#777E90', fontWeight: '600' }}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                    textAlign="center"
                  >
                    Date Time
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Your Income
                  </Text>
                </Th>
                {/* <Th textAlign="center">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Receive From
                  </Text>
                </Th> */}
              </tr>
            </thead>
            <tbody>
              <>{renderClaimHistory()}</>
            </tbody>
          </table>
        </TableScroll>
        {userClaimedCommLength > 0 && (
          <StyledPage>
            <BtnPage onClick={() => getSortData(currentPage.current - 1)} disabled={currentPage.current === 1}>
              <Text style={{ fontSize: '13px', lineHeight: '24px', fontWeight: '600', color: '#667085' }}>
                Previous
              </Text>
            </BtnPage>
            <StyledPageNumber>
              {pageNumbers.length > 3 ? (
                <>
                  <BtnPageNumber
                    key={1}
                    className={
                      currentPage.current && currentPage.current !== pageNumbers[pageNumbers.length - 1] && 'active'
                    }
                  >
                    {currentPage.current === pageNumbers[pageNumbers.length - 1] ? pageNumbers[0] : currentPage.current}
                  </BtnPageNumber>
                  <BtnPageNumber key={2}>...</BtnPageNumber>
                  <BtnPageNumber
                    key={3}
                    className={currentPage.current === pageNumbers[pageNumbers.length - 1] && 'active'}
                  >
                    {pageNumbers[pageNumbers.length - 1]}
                  </BtnPageNumber>
                </>
              ) : (
                <>
                  {pageNumbers.map((i, r) => (
                    <BtnPageNumber key={r + 1} className={r === currentPage.current - 1 && 'active'}>
                      {i}
                    </BtnPageNumber>
                  ))}
                </>
              )}
            </StyledPageNumber>
            <BtnPage
              onClick={() => getSortData(currentPage.current + 1)}
              disabled={currentPage.current === pageNumbers.length}
            >
              <Text style={{ fontSize: '13px', lineHeight: '24px', fontWeight: '600', color: '#667085' }}>Next</Text>
            </BtnPage>
          </StyledPage>
        )}
      </TablePool>
    </WrapperTable>
  )
}
export default CommClaimHistory

import React from 'react'
import images from 'configs/images'

const Banner = ({ data, width = 300, height = 180, border = 1 }) => {
  const style = {
    width: `100%`,
    height: `100%`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `${border}px solid #e4e4e7`,
    background: '#fff',
    overflow: 'hidden',
    borderRadius: '4px',
  }
  const styledBanner = {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as 'cover',
  }

  return (
    <div style={style}>
      {!data || data === '' ? (
        <img style={styledBanner} src={images.nobanner} alt="banner" />
      ) : (
        <img style={styledBanner} src={data} alt="banner" />
      )}
    </div>
  )
}

export default Banner

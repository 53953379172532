import { Button, Heading, Input, Text, useMatchBreakpoints, useModal } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import truncateHash from '@pancakeswap/utils/truncateHash'
import images from 'configs/images'
import moment from 'moment'
import { ModalKol } from './ModalActiveKol'
import { formatEther } from '@ethersproject/units'
import CountUp from 'react-countup'

const ReferralPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
  gap: 48px;
  width: 78%;

  @media screen and (max-width: 900px) {
    padding 0px 16px;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    padding 0px 16px;
    width: 100%;
  }
`
const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.96px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #ff0a23 0%, #f77b88 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  color: rgba(173, 171, 178, 1);
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
  }
  .title {
    color: white;
    font-weight: 700;
  }
`
const CardRegister = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  height: auto;
  padding: 40px;
  border-radius: 16px;
  background: var(--greyscale-grayscale-3, #141217);
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  position: relative;
  .upto {
    position: absolute;
    top: -12%;
    right: -7%;
  }
  @media screen and (max-width: 575px) {
    padding: 40px 16px;
    .upto {
      position: relative;
      top: 0;
      right: 0;
      width: 343px;
    }
  }
`
const GroupLinkRef = styled.div`
  flex-direction: column;
  display: flex;
  gap: 48px;
  width: 100%;
`
const StyledText = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: rgba(173, 171, 178, 1);
`
const WrapperLinkRef = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  padding: 32px;
  gap: 24px;
  @media screen and (max-width: 575px) {
    padding: 16px;
    width: 100%;
  }
`

const GroupLink = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`
const StyledLink = styled.div`
  width: 100%;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-direction: row-reverse;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  padding: 12px 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    min-height: 44px;
  }
`
const LinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`
const LinkKol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`
const ShowLinkRefPc = styled.span`
  display: none;
  word-break: break-all;
  color: #fff;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`
const ShowLinkRefMobile = styled.span`
  display: block;
  word-break: break-all;
  color: #fff;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  }
`
const ShowTimeKol = styled.span`
  word-break: break-all;
  color: #fff;
`
const StyledIconRef = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const StyledInput = styled(Input)`
  outline: none;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 16px;
  height: 44px;
  &:focus:not(:disabled) {
    border: none;
  }
`
const BtnRegister = styled(Button)`
  margin-top: 30px;
  height: 44px;
  border-radius: 12px;
  background: #ff0420;
  color: rgba(255, 255, 255, 1);
`
const GroupSearchByCode = styled.div`
  .search {
    position: absolute;
    top: 20%;
    width: 24px;
    height: 24px;
    right: 2%;
    cursor: pointer;
  }
`
const StyledHeadSearchUser = styled(Heading)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
`
const StyledInputSearch = styled(Input)`
  outline: none;
  height: 44px;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 32px 12px 16px;

  &:focus:not(:disabled) {
    border: none;
  }
  ::placeholder {
    color: #67666e;
  }
`
const ButtonLink = styled.button`
  width: 192px;
  border-radius: 90px;
  border: 2px solid var(--greyscale-grey-scale-text-seconday, #adabb2);
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 1);
  background: rgba(20, 18, 23, 1);
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
`
const Step = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`
const TitleStep = styled(Text)`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #000;
`
const LabelStep = styled(Text)`
  color: var(--neutral-900-text, #202327);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const CardStep = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 24px;
  justify-content: space-between;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`
const Card = styled.div`
  width: 340px;
  border-radius: 8px;
  background: #fafafa;
  padding: 29px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .imgStep {
    width: 48px;
    height: 48px;
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`
const CardTitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
`
const BtnActiveKol = styled(Button)`
  padding: 0 8px;
  height: 28px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #fff;
  background: #ff0420;
  &:disabled {
    border-radius: 8px;
  }
`
const TitleTextStep = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`

const PageReferral = ({
  linkRef,
  totalLock,
  remainKol,
  getRemainKol,
  handleRef,
  handleLeave,
  userIsRegister,
  showCopied,
  referCode,
  validateReferByWallet,
  showError,
  onRegister,
  userSearch,
  setUserSearch,
  handleSearchUserCode,
  showResultSearch,
  setTab,
}) => {
  const { isMobile } = useMatchBreakpoints()
  const currentTime = moment().unix()

  const formatLinkRef = (ref, start, end) => {
    if (ref?.includes('0x')) {
      return truncateHash(ref, start, end)
    }
    return linkRef
  }

  const [openModalActiveKol, onDismissModalActiveKol] = useModal(
    <ModalKol getRemainKol={() => getRemainKol()} onDismiss={() => onDismissModalActiveKol()} />,
  )

  return (
    <ReferralPage>
      <div>
        <StyledHead>Refer friends & Get rewards</StyledHead>
        <StyledSubtitle>
          Share the love with our referral program! Invite friends to OPSocialFi, both you and your referral enjoy
          exclusive rewards or discounts.
        </StyledSubtitle>
      </div>
      <CardRegister>
        <GroupLinkRef>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledText style={{ fontWeight: '700', color: 'white', fontSize: '28px' }}>
              Referral Registration
            </StyledText>
          </div>
          <WrapperLinkRef>
            <Text
              fontSize={['16px', '16px', '18px', '24px', '24px']}
              fontWeight="600"
              lineHeight="32px"
              style={{ color: 'rgba(255, 255, 255, 1)' }}
            >
              Earn up to 10%
            </Text>
            <GroupLink>
              <div style={{ minWidth: '50%', width: 'auto' }}>
                <StyledText
                  style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 1)', marginBottom: '12px' }}
                  color="rgba(255, 255, 255, 1)"
                >
                  Referral link
                </StyledText>
                <StyledLink style={{ fontSize: '16px' }}>
                  <LinkItem>
                    <ShowLinkRefPc>{formatLinkRef(linkRef, 50, 4)}</ShowLinkRefPc>
                    <ShowLinkRefMobile>{formatLinkRef(linkRef, 20, 4)}</ShowLinkRefMobile>
                    <StyledIconRef id="iconRef" src={images.copy} onClick={handleRef} onMouseLeave={handleLeave} />
                    <Tooltip
                      anchorId="iconRef"
                      content={userIsRegister ? (showCopied ? 'Copied' : 'Copy') : 'Please Register'}
                    />
                  </LinkItem>
                </StyledLink>
              </div>
              {!userIsRegister && (
                <>
                  <div style={{ width: isMobile ? '100%' : '50%' }}>
                    <StyledText
                      style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 1)', marginBottom: '12px' }}
                      color="rgba(255, 255, 255, 1)"
                    >
                      Referral code
                    </StyledText>
                    <StyledInput
                      value={referCode}
                      autoFocus={true}
                      onChange={validateReferByWallet}
                      placeholder={`refer code`}
                    />
                    {showError && referCode && (
                      <div style={{ color: 'red', fontWeight: '600', margin: '4px' }}>Invalid code</div>
                    )}
                  </div>
                  <BtnRegister onClick={onRegister} disabled={userIsRegister || showError}>
                    Register
                  </BtnRegister>
                </>
              )}
            </GroupLink>
            <GroupLink>
              <div style={{ minWidth: '50%', width: 'auto' }}>
                <StyledText
                  style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 1)', marginBottom: '12px' }}
                  color="rgba(255, 255, 255, 1)"
                >
                  Kol remain time
                </StyledText>
                <StyledLink style={{ fontSize: '16px' }}>
                  <LinkKol>
                    <ShowTimeKol>
                      {remainKol <= 0 || remainKol < currentTime
                        ? '00.00.00'
                        : moment.unix(remainKol).diff(moment(), 'days') +
                          ' days ' +
                          (moment.unix(remainKol).diff(moment(), 'hours') % 24) +
                          ' hours ' +
                          Math.floor(moment.unix(remainKol).diff(moment(), 'minutes') % 60) +
                          ' minutes'}
                    </ShowTimeKol>
                    <BtnActiveKol
                      onClick={openModalActiveKol}
                      disabled={totalLock <= 0 || !userIsRegister}
                      // disabled={totalLock <= 0 || remainKol > currentTime || !userIsRegister}
                    >
                      active
                    </BtnActiveKol>
                  </LinkKol>
                </StyledLink>
              </div>
            </GroupLink>
            <div style={{ display: 'flex', gap: '32px', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: '100px', height: '1px', background: 'rgba(255, 255, 255, 0.10)' }}></div>
              <Text style={{ color: '#fff', fontSize: '18px', fontWeight: '500', lineHeight: '20px' }}>Or</Text>
              <div style={{ width: '100px', height: '1px', background: 'rgba(255, 255, 255, 0.10)' }}></div>
            </div>
            <GroupSearchByCode>
              <StyledHeadSearchUser>Search By Code</StyledHeadSearchUser>
              <div style={{ position: 'relative' }}>
                <StyledInputSearch
                  value={userSearch}
                  // autoFocus={true}
                  onChange={(e) => setUserSearch(e.target.value)}
                  placeholder={`Enter code`}
                />
                <div onClick={handleSearchUserCode}>
                  <img
                    style={{ width: '18px', height: '28px', marginRight: '4px' }}
                    className="search"
                    src={images.icon_search}
                  />
                </div>
              </div>
              {showResultSearch()}
            </GroupSearchByCode>
          </WrapperLinkRef>
        </GroupLinkRef>
        <ButtonLink onClick={() => setTab(2)}>Check my refer list</ButtonLink>
      </CardRegister>
      <Step>
        <TitleStep>How to refer your friends</TitleStep>
        <CardStep>
          <Card>
            <img className="imgStep" src={images.sharerefe} />
            <LabelStep>Copy & Share your referral links with your friends</LabelStep>
          </Card>
          <Card>
            <img className="imgStep" src={images.yourrefe} />
            <LabelStep>Your friends sign up to OPSocialFi platform through your referral link</LabelStep>
          </Card>
          <Card>
            <img className="imgStep" src={images.boxrefe} />
            <LabelStep>Receive rewards and enjoy OPSocialFi</LabelStep>
          </Card>
        </CardStep>
      </Step>
      <Step>
        <TitleStep>How to receive commissions</TitleStep>
        <CardStep>
          <Card style={{ gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <TitleTextStep>Direct Commission: </TitleTextStep>
              <LabelStep style={{ fontWeight: '400' }}>(Get Commission On Your Affiliate Packages)</LabelStep>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <LabelStep>Level 1: 10%</LabelStep>
              <LabelStep>Level 2: 3%</LabelStep>
              <LabelStep>Level 3: 2%</LabelStep>
            </div>
          </Card>
          <Card style={{ gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <TitleTextStep>Staking Commission: </TitleTextStep>
              <LabelStep style={{ fontWeight: '400' }}>(Get Commission On Profit of Your Affiliate)</LabelStep>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <LabelStep>Level 1: 15%</LabelStep>
              <LabelStep>Level 2: 4%</LabelStep>
              <LabelStep>Level 3: 3%</LabelStep>
              <LabelStep>Level 4: 2%</LabelStep>
              <LabelStep>Level 5: 1%</LabelStep>
            </div>
          </Card>
          <Card style={{ gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <TitleTextStep>KOLs Commission:</TitleTextStep>
              <LabelStep style={{ fontWeight: '400' }}>(Get Commission Of Your Level 1 Buy KOLs Packages)</LabelStep>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <LabelStep>Level 1: 50%</LabelStep>
            </div>
          </Card>
        </CardStep>
        <LabelStep
          style={{ padding: '10px 10px 40px', display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '4px' }}
        >
          Note*:{' '}
          <Text style={{ color: 'rgba(173,171,178,1)' }}>
            Need to active KOLs packages for Earn Your Commission KOLs Packages: 20$/month
          </Text>
        </LabelStep>
      </Step>
    </ReferralPage>
  )
}
export default PageReferral

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const UserName = ({ data, font = 16 }) => {
  
  const style = {
    width: '100%',
    fontSize: `${font}px`,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: '#1a1a1a',
    cursor: 'pointer',
  }

  return (
    <>
      {data === '' ? (
        <>
        <p style={style}>
          <Skeleton style={{width: "100%"}} />
        </p>
        </>
      ) : (
        <>
          {data && data.length > '30' ? (
            <p style={style}>{`${data.substring(0, 5)}...${data.substring(data.length - 5)}`}</p>
          ) : (
            <p style={style}>{data}</p>
          )}
        </>
      )}
    </>
  )
}

export default UserName

import { ReactNode } from 'react'
import { Flex, Box, FlexProps } from '@pancakeswap/uikit'
import Image from 'next/image'
import StyledBannerImageWrapper from './BannerImage'
import Banner from 'components/Banner'

interface BannerHeaderProps extends FlexProps {
  bannerImage: string
  bannerAlt?: string
  avatar?: ReactNode
}

const BannerHeader: React.FC<React.PropsWithChildren<BannerHeaderProps>> = ({
  bannerImage,
  bannerAlt,
  avatar,
  children,
  ...props
}) => {
  return (
    <Flex flexDirection="column" {...props}>
      <Box position="relative">
        <StyledBannerImageWrapper>
          <Banner data={bannerImage} />
          {/* <Image src={bannerImage} alt={bannerAlt} layout="fill" objectFit="cover" priority /> */}
        </StyledBannerImageWrapper>
        <Box position="absolute" bottom={-60} left={15}>
          <Flex alignItems="flex-end">
            {avatar}
            {/* {children} */}
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default BannerHeader

import { useTranslation } from '@pancakeswap/localization'
import { Grid, ModalPostStatus, useToast } from '@pancakeswap/uikit'
import postsApi from 'api/postApi'
import axios from 'axios'
import Avatar from 'components/Avatar'
import CircleLoader from 'components/Loader/CircleLoader'
import { StyledImages } from 'components/StyledImages'
import UserName from 'components/UserName'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HeaderPost = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  paddingbottom: 12px;
`
const BtnPost = styled.button`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 600;
  background: rgb(220, 40, 40);
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 1;
    background: #ccc;
  }
`
const StyledSelect = styled.select`
  padding: 1px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  border: 1px solid #e4e6eb;
  border-radius: 4px;
  background: #ccc;
  color: #050505;
  &:focus-visible {
    outline: none;
  }
`
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 45px;
  font-size: 16px;
  line-height: 18px;
  border: none;
  border-radius: none;
  background: none;
  padding: 10px 0;
  color: #000;
  &:focus-visible {
    outline: none;
  }
`
const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`
const StyledChooseFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0;
  border-radius: 8px;
  border: 1px solid #b1b1b1;
  input[type='file'] {
    display: none;
  }
  .custom-file-2 {
    margin: 0;
    padding: 6px 20px;
    cursor: pointer;
  }
`
const OptionPost = styled.div`
  width: 100%;
  height: auto;
`
const BodyPost = styled.div`
  overflow: auto;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
const StyledImg = styled.div`
  max-height: 410px;
  width: auto;
  height: 100%;
`
const StyledName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const PostStatusModal = ({ userData, listDataPost, setListDataPost, onDismiss }) => {
  const { connetSocial } = useAuth()
  const { onPresentConnectModal } = useWallet()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [loading, setLoading] = React.useState(false)
  const [audience, setAudience] = React.useState('everyone')
  const [content, setContent] = React.useState('')
  const [imgHash, setImgHash] = useState([])

  const sendFileToIPFS = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      const resFile = await axios({
        method: 'post',
        url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
        data: formData,
        headers: {
          pinata_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_KEY}`,
          pinata_secret_api_key: `${process.env.NEXT_PUBLIC_PINATA_API_SECRET}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`
      setImgHash([...imgHash, ImgHash])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('Error sending File to IPFS: ')
      console.log(error)
    }
  }

  const fetchPostStatus = async () => {
    if (!account) {
      onPresentConnectModal()
      return
    }
    if (!userData.userId) {
      connetSocial()
      return
    }
    try {
      const body = {
        content: content,
        audience: audience,
        images: imgHash,
      }
      const response = await postsApi.statusPost(body)
      const updatedListData = [...listDataPost]
      updatedListData.unshift(response.data)
      setListDataPost(updatedListData)
      toastSuccess(t('Success!'), t('Post Success!'))
      onDismiss()
    } catch (error) {
      toastError(t('Failed to Posts'), t('Please try again!'))
      console.log('Failed to fetch list posts: ', error)
    }
  }

  return (
    <ModalPostStatus title="Create articles" color="#fff" onDismiss={onDismiss}>
      <Wrapper>
        <Grid style={{ width: '100%' }}>
          <HeaderPost>
            <Avatar data={userData?.avatar} width={44} height={44} />
            <StyledName>
              <UserName data={userData?.name} />
              <StyledSelect value={audience} onChange={(e) => setAudience(e.target.value)}>
                <option value="everyone">Everyone</option>
                <option value="onlyMe">Only Me</option>
                <option value="friends">Friends</option>
              </StyledSelect>
            </StyledName>
          </HeaderPost>
          <BodyPost>
            <StyledImg>
              <StyledTextarea
                placeholder="What's on your mind?"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
              {imgHash.length > 0 && (
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    padding: '8px',
                    border: '1px solid #ccc',
                  }}
                >
                  <StyledImages data={imgHash} setData={setImgHash} cancel={true} />
                </div>
              )}
            </StyledImg>
          </BodyPost>
          <OptionPost>
            {loading ? (
              <StyledLoading>
                <CircleLoader size="48px" />
              </StyledLoading>
            ) : (
              <StyledChooseFile>
                <label htmlFor="UpImg" className="custom-file-2">
                  <img style={{ width: '24px', height: '24px' }} src="./images/icons/chooseimg.png" alt="choose file" />
                </label>
                <input type="file" id="UpImg" name="files[]" accept="image/*" onChange={(e) => sendFileToIPFS(e)} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              </StyledChooseFile>
            )}
          </OptionPost>
        </Grid>
        <BtnPost disabled={content.length <= 0 && imgHash.length <= 0} onClick={fetchPostStatus}>
          Post
        </BtnPost>
      </Wrapper>
    </ModalPostStatus>
  )
}

import styled from 'styled-components'
import { useModal } from '@pancakeswap/uikit'
import AvatarImage from 'views/Nft/market/components/BannerHeader/AvatarImage'
import EditProfileModal from './EditProfileModal'
import images from 'configs/images'
import Avatar from 'components/Avatar'
import { useState } from 'react'

const EditOverlay = styled.div`
  background: rgba(0, 0, 0, 0.6) url('/images/icons/camera.svg') no-repeat center center;
  border-radius: 50%;
  left: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 300ms;
  width: 100%;
  z-index: 1;
`

const StyledEditProfileAvatar = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;

    ${EditOverlay} {
      opacity: 1;
    }
  }
`

const EditProfileAvatar: React.FC<
  React.PropsWithChildren<{ src: string; alt?: string; loading: boolean; onSuccess: () => void }>
> = ({ src, alt, loading, onSuccess }) => {
  const [userCt, setUserCt] = useState(false)
  const [onEditProfileModal] = useModal(
    <EditProfileModal userInfo={null} setUserInfo={null} userCt={userCt} setUserCt={setUserCt} onSuccess={onSuccess} />,
    false,
  )

  return (
    <StyledEditProfileAvatar>
      {/* <StyledEditProfileAvatar onClick={onEditProfileModal}> */}
      {loading ? (
        <>
          <Avatar data={images.loadinggif} width={150} height={150} border={4} />
          {/* <AvatarImage src={images.loadinggif} alt={alt} /> */}
        </>
      ) : (
        <>
          <Avatar data={src} width={150} height={150} border={4} />
          {/* <AvatarImage src={src} alt={alt} /> */}
          {/* <EditOverlay /> */}
        </>
      )}
    </StyledEditProfileAvatar>
  )
}

export default EditProfileAvatar

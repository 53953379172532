import { Box, Button } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const Wrapper = styled(Box)`
  overflow: auto;
  width: 100%;
  height: 100%;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`
export const Container = styled.div`
  width: 100%;
  max-height: 60vh;
  height: auto;
  padding: 12px 12px 0;
`
export const Title = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
`
export const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
`
export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  input[type='file'] {
    display: none;
  }
  .custom-file-2 {
    margin: 0;
    z-index: 100;
    cursor: pointer;
  }
`
export const BtnIntroduction = styled(Button)`
  width: 100%;
  height: 32px;
  margin: 10px 0;
  border-radius: 6px;
  background-color: rgb(220, 40, 40);
  color: #fff;
  &:disabled {
    border-radius: 6px;
    background: #ccc !important;
  }
`
export const StyledTextarea = styled.textarea`
  max-width: 45%;
  width: 100%;
  height: 80px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border: none;
  border-radius: none;
  background: none;
  padding: 10px 0;
  color: #000;
  text-align: center;
  &:focus-visible {
    outline: none;
  }
`
export const StyledFrom = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .open-button {
    position: absolute;
    top: 5px;
    right: 0;
    width: 25px;
    height: 25px;
    background: #fff;
    pointer-events: none;
    cursor: pointer;
  }
  .open-button button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  img {
    width: 18px;
    height: 18px;
  }
  input {
    width: 100%;
    height: 36px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #050505;
    border: none;
    background-color: #fff;
    &:focus-visible {
      outline: none;
    }
  }
`
export const StyledError = styled.div`
  width: 100%;
`
export const ErrorInput = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: rgb(220, 40, 40);
  text-align: center;
`

import styled from 'styled-components'
import { Heading, Button } from '@pancakeswap/uikit'
import 'aos/dist/aos.css'
import images from 'configs/images'

const Wrap = styled.div`
  * {
    font-family: Inter, sans-serif;
  }
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  gap: 16px;
  @media (max-width: 575px) {
    padding: 20px;
    flex-direction: column;
  }
`
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`
const HeadingTop = styled(Heading)`
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  color: #1a1a1a;
`
const CTA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  box-shadow: 0px 1.75px 4px -1px rgba(0, 0, 0, 0.14);
  .wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    align-self: stretch;
    flex-wrap: wrap;
  }
`
const Box = styled.div`
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
`
const Message = styled.div`
  display: flex;
  min-width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  .title {
    margin: 5px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #1a1a1a;
  }
  .content {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(26, 26, 26, 0.7);
  }
`

const LoginButton = styled(Button)`
  display: flex;
  height: 44px;
  min-height: 44px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #dc2828;
  background: #dc2828;
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`
const Image = styled.div`
  max-width: 288px;
  max-height: 377px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0.99;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const LoginSection = () => {
  return (
    <Wrap>
      <Flex>
        <HeadingTop>&quot;Hi!. Welcome to Opsocialfi&quot;</HeadingTop>
        <CTA>
          <Box>
            <img src={images.stars} alt="star" />
            <Message>
              <div className="title">Don’t miss thing out</div>
              <div className="content">
                Join our community to catch-up the latest news and signal from blockchain expert over the world
              </div>
            </Message>
          </Box>
          <LoginButton>Join Now</LoginButton>
        </CTA>
      </Flex>
      <Image>
        <img src={images.connect} alt="connect" />
      </Image>
    </Wrap>
  )
}

export default LoginSection

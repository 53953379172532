import styled from 'styled-components'

export const Comment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 6px;
  gap: 4px;
`
export const CommentItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000;
  p {
    max-width: 500px;
    width: 100%;
    word-wrap: break-word;
  }
`
export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const Item = styled.div`
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justifycontent: space-between;
  border-radius: 18px;
  background: var(--mode-base-100, #f4f4f5);
`
export const Action = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  span {
    color: #65676b;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`
export const More = styled.div`
  width: 26px;
  height: 26px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #ccc;
  }
`
export const StyledAction = styled.div`
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`
export const StyledComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const InputComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--mode-alpha-100, rgba(26, 26, 26, 0.1));
  background: var(--mode-base-50, #fcfcfc);
`
export const Input = styled.input`
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--mode-alpha-900, #1a1a1a);
  background: #fff;
  border: none;
  &:focus-visible {
    outline: none;
  }
`
export const BtnSend = styled.img`
  width: 21px;
  height: 21px;
  margin: 4px 6px;
  cursor: pointer;
`
export const ActionComments = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  color: #65676b;
  span {
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    color: #65676b;
  }
`
export const StyledDayTime = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #56676b;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 575px) {
    display: none;
  }
`
export const ViewsReply = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 0 10px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #65676b;
  cursor: pointer;
  &:hover {
    img,
    p {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`
export const ModalTippy = styled.div`
  max-width: 160px;
  width: 160px;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
  color: #000;
  background: #fff;
`
export const ModalTippyItem = styled.div`
  width: 100%;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  text-align: end;
  color: #000;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`

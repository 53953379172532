import styled from 'styled-components'
import { LinkExternal } from '@pancakeswap/uikit'
import React, { useEffect, useState } from 'react'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import { useSigner } from 'wagmi'
import { formatEther } from '@ethersproject/units'
import CountUp from 'react-countup'
import { getBlockExploreLink } from 'utils'
import poolAbi from 'config/abi/pools.json'
import { isMobile } from 'react-device-detect'

const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const CardReferral = styled.div`
  max-width: 1000px;
  width: 100%;
  height: auto;
  padding-left: 50px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #e6e6e6;
`

const Child = ({ referBy }) => {
  const [loadingTable, setLoadingTable] = useState(true)
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const poolCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [listChild, setListChild] = useState([])
  const [isShowChild, setIsShowChild] = useState([])
  const [acountChild, setAccountChild] = useState(referBy)

  const getTotalRefferChild = async (page, accountChild) => {
    if (!referBy) {
      return
    }
    const limit = 1000000
    const data = await Promise.all([refferCT.getTotalUserByUp(referBy, limit, page), refferCT.userInfos(referBy)])
    const arr = data[0].list.map((item) => item.user)

    const promises = arr.map(async (item) => {
      const locked = await poolCT.userTotalLockUSD(item)
      const child = await refferCT.userInfos(item)
      return { locked: locked.toString(), child: child.totalRefer.toString() }
    })

    const results = await Promise.all(promises)

    setListChild(
      arr.map((item, ix) => {
        return {
          id: ix,
          account: item,
          locked: Number(formatEther(results[ix].locked)),
          child: results[ix].child,
        }
      }),
    )
    setLoadingTable(false)
  }

  const handledAddChild = (_id) => {
    const newItem = { _id }
    setIsShowChild((currentItems) => [...currentItems, newItem])
  }

  const handleRemoveChild = (_id) => {
    setIsShowChild((currentItems) => currentItems.filter((item) => item._id !== _id))
  }

  useEffect(() => {
    if (!referBy) {
      return
    }
    getTotalRefferChild(0, referBy)
  }, [referBy, acountChild])

  return (
    <CardReferral>
      {loadingTable ? (
        <ThreeDots style={{ textAlign: 'center' }} className="loading">
          Loading
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </ThreeDots>
      ) : (
        <table style={{ width: '100%' }}>
          <tbody>
            {listChild.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '3px 0',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        maxWidth: isMobile ? '118px' : '130px',
                        minWidth: isMobile ? '118px' : '130px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <LinkExternal
                        fontSize={isMobile ? '14px' : '18px'}
                        href={getBlockExploreLink(item.account, 'address', CHAIN_ID)}
                        ellipsis={true}
                        style={{ color: '#fff' }}
                        color="#fff"
                      >
                        <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                          {item.account.substring(0, 2)}...{item.account.substring(item.account.length - 4)}
                        </p>
                      </LinkExternal>
                      {item.child > 0 && isShowChild.some((item) => item._id === index) === false && (
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() => (item.child > 0 ? handledAddChild(item.id) : '')}
                        >
                          <img src="/images/referral/circleDown.svg" style={{ fill: 'white', cursor: 'pointer' }} />
                        </div>
                      )}
                      {item.child > 0 && isShowChild.some((item) => item._id === index) && (
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() => (item.child > 0 ? handleRemoveChild(item.id) : '')}
                        >
                          <img src="/images/referral/circleUp.svg" style={{ fill: 'white', cursor: 'pointer' }} />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        minWidth: '250px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      ($
                      <CountUp
                        style={{ color: '#fff', fontSize: isMobile ? '14px' : '18px' }}
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={item.locked}
                        decimals={2}
                        duration={1}
                      />
                      )
                    </div>
                  </td>
                </tr>
                {isShowChild.some((item) => item._id === index) && (
                  <tr key={index + '-' + index}>
                    <td style={{ padding: 0 }}>
                      <Child referBy={item.account} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </CardReferral>
  )
}

export default Child

import { Flex, useModal } from '@pancakeswap/uikit'
import styled from 'styled-components'
import 'aos/dist/aos.css'
import { PostStatusModal } from '../../../../../components/Post/PostStatusModal/PostStatusModal'
import Avatar from 'components/Avatar'

const Head = styled(Flex)`
  * {
    font-family: Inter, sans-serif;
  }
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 899px) {
    padding: 24px 10px 0;
  }
`
const BtnPost = styled.button`
  width: 100%;
  height: 42px;
  display: flex;
  padding: 6px 18px;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid #e4e4e7;
  background: #fff;
  color: #1a1a1a;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: text;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    background-color: #f0f0f0;
  }
`
const HeadHome = ({ userData, listDataPost, setListDataPost }) => {
  const [openPostStatusModal, onDismissPostStatusModal] = useModal(
    <PostStatusModal
      userData={userData}
      listDataPost={listDataPost}
      setListDataPost={setListDataPost}
      onDismiss={() => onDismissPostStatusModal}
    />,
    true,
    false,
    'removeModalPostStatusModal',
  )

  return (
    <Head>
      <div style={{ width: '44px', height: '44px' }}>
        <Avatar data={userData?.avatar} width={44} height={44} />
      </div>
      <BtnPost onClick={openPostStatusModal}>What’s on your mind ?</BtnPost>
    </Head>
  )
}

export default HeadHome

import { useTranslation } from '@pancakeswap/localization'
import { Box, Modal, useToast, Button, Input, Text, useModal } from '@pancakeswap/uikit'
import CountUp from 'react-countup'
import { useState } from 'react'
import styled from 'styled-components'
// import { trendyColors } from 'style/trendyTheme'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { usePoolsContract, usePoolsV2Contract, usePoolsV4Contract } from 'hooks/useContract'
import { ToastDescriptionWithTx } from 'components/Toast'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ChainId } from '../../../../../../packages/swap-sdk/src/constants'
// import { ClaimPoolModalProps } from './type'
import images from 'configs/images'
import { useSigner } from 'wagmi'
import { getContract } from 'utils/contractHelpers'
import OPAbi from 'config/abi/OP.json'
import contracts from 'config/constants/contracts'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
// import { ModalCheckPinCode } from 'components/ModalPin'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'

// STYLE
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: start;
  gap: 1em;
  .imagesvector {
    margin-left: 4px;
    padding: 3px;
    display: flex;
    width: 18px;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }
`
const ClaimAmount = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledButton = styled(Button)`
background: #ff0420;
`
const depositModal = {
  padding: '32px',
}
const depositInput = {
  borderRadius: '10px',
}
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
// const Error = styled.span`
//   margin: -0.5em 0 1em;
//   color: ${trendyColors.ORANGE};
// `
const Error = styled.span`
  margin: -0.5em 0 1em;
  color: red;
  font-size: 18px;
  margin: 10px 0;
`
const StyledInput = styled(Input)`
  margin-top: 24px;
  outline: none;
  border-radius: 12px;
  // border: 1px solid var(--primary-primary-1, #8544f5);
  background: var(--greyscale-grayscale-4, #2d2c33);
  &:focus:not(:disabled) {
    border: 1px solid var(--primary-200,#ffdbdf);
  }
`
const ApproveModal = ({
  account,
  onSuccess,
  onDismiss,
  chainId
}) => {
    const { data: signer } = useSigner()
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId

  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [confirmedTxHash, setConfirmedTxHash] = useState('')
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const [isValidAmount, setIsValidAmount] = useState(true)
  const poolContract = usePoolsContract()
  const [amountApprove, setAmountApprove] = useState('')
  const [amount, setAmount] = useState(0)
  const [showError,setShowError] = useState(false)
  const OPContract = getContract({
    address: contracts.OP[CHAIN_ID],
    abi: OPAbi,
    chainId: CHAIN_ID,
    signer,
  })

  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
        return callWithMarketGasPrice(OPContract, 'approve', [poolContract.address], [amountApprove])
    },
    onSuccess: async ({ receipt }) => {
      setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Approve successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      onDismiss()
      onSuccess()
    },
  })
  const handleAmountChange = (e: any) => {
    setAmountApprove(new BigNumber(e.target.value).times(getFullDecimalMultiplier(18)).toString())
    setAmount(e.target.value)
    if(e.target.value < 0) {
      setShowError(true)
    }
    else {
      setShowError(false)
    }
  }
  return (
    <Modal
      style={depositModal}
      title={''}
      onDismiss={onDismiss}
      hideCloseButton={false}
      borderRadius={25}
      headerBackground="#24272A"
    >
      <Wrapper>
        <ClaimAmount>
          <Text fontSize="24px">Approve</Text>
          <StyledInput
              value={amount}
              autoFocus={true}
              type="number"
              style={depositInput}
              onChange={handleAmountChange}
              // placeholder={`${minLockMatic.toFixed(4)} ${pool.unit}`}
            />
        </ClaimAmount>
        {showError === true ? <Error>Please enter a value greater than 0 !!</Error> : null}
        <StyledButton
          variant={'danger'}
          width="180px"
          disabled={isConfirming || (!isValidAmount ? true : false)}
          onClick={handleConfirm}
        >
          {isConfirming ? (
            <ThreeDots className="loading">
              Approving<span>.</span>
              <span>.</span>
              <span>.</span>
            </ThreeDots>
          ) : (
            'Approve'
          )}
        </StyledButton>
      </Wrapper>
    </Modal>
  )
}

export default ApproveModal
